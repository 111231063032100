import React, {useState, useEffect, useRef} from 'react'
import {BiX} from "react-icons/bi";
import ProfileIcon from "../../assets/images/profile.jpg";
import AttachIcon from "../../assets/images/attch_big.png";
import SendIcon from "../../assets/images/send_icon.png";
import Thread from "./threads/Thread";
import {GetAuthUser} from "../../services/auth/auth.service";
import {
    //deleteMessage,
    //deleteVendorMessage,
    deleteVendorMessage,
    deleteEveryOneVendorMessage,
    getUserImage,
    submitMessage,
    updateVendorLastMessage, deleteMessage
} from "../../services/firebase.service";
import {constants} from "../../utils/constants";
import {UploadImageToS3} from "../../utils/helpers";
import {toast} from "react-toastify";
import {FaTimesCircle, FaUsers, FaUser} from "react-icons/fa";
import BarLoader from "react-spinners/BarLoader";
import ThemeModal from "../ThemeModal";
import {Row, Col, Button, Form} from 'react-bootstrap';

const VendorChat = ({vendorPop, setVendorPop, vendor_messages, active_vendor, activeThread, activeStore}) => {
    const current_user = GetAuthUser();
    const [vendorFile, setVendorFile] = useState()
    const [vendorFileName, setVendorFileName] = useState()
    const vendorFileInput = useRef();
    const vendorMessagesEndRef = useRef(null)
    const [vendorTextareaDisabled, setVendorTextareaDisabled] = useState(false)
    const [loading, setVendorLoading] = useState(false)
    const [message, setMessage] = useState(false)
    const [delModalShow, setDelModalShow] = useState(false);
    const [deleteModalData, setDeleteModalData] = useState({});
    const [senderImage, setSenderImage] = useState()
    const [receiverImage, setReceiverImage] = useState()

    const scrollToBottom = () => {
        if (vendorMessagesEndRef && vendorMessagesEndRef.current) {
            const element = vendorMessagesEndRef.current;
            element.scroll({
                top: element.scrollHeight,
                left: 0,
                behavior: "auto"
            })
        }
    }

    useEffect(() => {
        receiver_image(current_user.id)
        sender_image(active_vendor)
    }, [active_vendor])

    useEffect(() => {
        scrollToBottom()
    }, [vendor_messages]);

    function vendorHandleChange(e) {
        setVendorFile(e.target.files[0]);
        setVendorFileName(e.target.files[0]['name']);
    }

    const sender_image = async (image) => {
        await getUserImage(image, (sender_image) => {
            setSenderImage(sender_image);
        })
    }

    const receiver_image = async (image) => {
        await getUserImage(image, (receiver_image) => {
            setReceiverImage(receiver_image);
        })
    }

    const onKeyDownMessage = async (e) => {
        setMessage(e);
        if (e.keyCode == 13) {
            if (e.ctrlKey) {
                //todo: on press ctrl + enter break to new line
                return true;
            } else {
                await SubmitMessage(e)
            }
        }
    }

    const SubmitMessage = async (e) => {
        const file = vendorFileInput?.current?.files[0];
        let is_vendor = current_user.roles[0].id == constants.ROLES.ROLE_VENDOR;
        if (e.target.value.trim().length > 0 || file) {
            e.preventDefault();
            let url = '';
            let filename = '';
            let content_type = 'text';
            if (file) {
                setVendorLoading(true);
                let uploadImage = await UploadImageToS3(vendorFileInput);
                if (uploadImage) {
                    if (!uploadImage.success) {
                        toast.error(uploadImage.message);
                        return false;
                    }

                    content_type = constants.CONTENTTYPE[file.type];
                    url = uploadImage.data;
                    filename = file.name;
                }
                setVendorFile('');
                setVendorFileName('');
                let data = {
                    chatId: activeThread.toString(),
                    content: filename,
                    contentType: content_type,
                    delete: 0,
                    deleteAgent: 0,
                    idFrom: current_user.id.toString(),
                    idTo: active_vendor.toString(),
                    isread: false,
                    timestamp: new Date().getTime(),
                    url: url,
                    vendorIds: [],
                    videoThumbnail: "",
                    isVendor: true,
                }
                let submit_message = submitMessage(data);
                submit_message.then((data) => {
                    if (data) {
                        vendorFileInput.current.value = "";
                        e.target.focus();
                    }
                });
                setVendorLoading(false);
            }

            let message = e.target.value.trim();
            if (message) {
                e.target.value = '';
                vendorFileInput.current.value = "";
                let data = {
                    chatId: activeThread.toString(),
                    content: message,
                    contentType: "text",
                    delete: 0,
                    deleteAgent: 0,
                    idFrom: current_user.id.toString(),
                    idTo: active_vendor.toString(),
                    isread: false,
                    timestamp: new Date().getTime(),
                    url: url,
                    vendorIds: [],
                    videoThumbnail: "",
                    isVendor: true,
                }
                let submit_message = submitMessage(data);
                submit_message.then((data) => {
                    setVendorTextareaDisabled(true);
                    if (data) {
                        updateVendorLastMessage(active_vendor.toString(), activeThread, message.substring(0, 70))
                        e.target.value = '';
                        vendorFileInput.current.value = "";
                        setVendorTextareaDisabled(false);
                        e.target.focus();
                    }
                });
            }
        }
    }

    const deleteForMe = async (data) => {
        let is_vendor = current_user.roles[0].id == constants.ROLES.ROLE_VENDOR;

        let delete_data = {
            'active_thread': await data.active_thread,
            'message_id': await data.message_id,
            'deleted_at': constants.CHATMESSAGE.CONTENT_SOFT_DELETE
        }

        is_vendor ? await deleteVendorMessage(delete_data) : await deleteMessage(delete_data)

        setDelModalShow(false)
    }

    const deleteForEveryOne = async (data) => {
        let delete_data = {
            'active_thread': await data.active_thread,
            'message_id': await data.message_id,
            'deleted_at': constants.CHATMESSAGE.CONTENT_HARD_DELETE
        }

        await deleteEveryOneVendorMessage(delete_data)
        setDelModalShow(false)
    }

    //Vendor Delete Modal
    const DeleteModal = (data) => {
        return (
            <div className={"modalBodyContent mt-0"}>
                <Row>
                    <Col md={12}>
                        <ul className={"list-unstyled mb-0"}>
                            <li>
                                <Button onClick={() => deleteForMe(data.data)} className={"btn-green-theme mb-2"}>
                                    <FaUser/> Delete For Me
                                </Button>
                            </li>
                            {
                                data.data.type === 'received' ?
                                    <li>
                                        <Button onClick={() => deleteForEveryOne(data.data)} className={"btn-green-theme"}>
                                            <FaUsers/> Delete For Everyone
                                        </Button>
                                    </li> : ''
                            }
                        </ul>
                    </Col>
                </Row>
            </div>
        )
    }

    return (
        <div className={`chatVendorPop ${vendorPop ? "vendor-active" : " "}`}>
            <div className="tab-content chat-des">
                <div id="conversaion_starts" className="tab-pane active">
                            <span className="title">
                                <span className={"MenuIcon"} onClick={() => setVendorPop(!vendorPop)}>
                                    <BiX/>
                                </span>
                                <h3>{activeStore}
                                    <small> (Order# {activeThread})</small> {/*<strong>is typing....</strong>*/}</h3>
                            </span>
                    <div className="message-info" ref={vendorMessagesEndRef}>
                        {
                            vendor_messages && vendor_messages.length > 0 ?
                                vendor_messages.map((message) => {
                                    if (message.mediaType) {
                                        return (
                                            <Thread activeThread={activeThread}
                                                    type={message.type}
                                                    text={message.text}
                                                    profileImg={message.profileImg}
                                                    receivedAt={message.timestamp}
                                                    media={message.media}
                                                    mediaType={message.mediaType}
                                                    isRead={message.isRead}
                                                    setDelModalShow={setDelModalShow}
                                                    setDeleteModalData={setDeleteModalData}
                                                    senderImage={senderImage}
                                                    receiverImage={receiverImage}
                                            />
                                        )
                                    } else {
                                        return (
                                            <Thread activeThread={activeThread}
                                                    type={message.type}
                                                    text={message.text}
                                                    profileImg={message.profileImg}
                                                    receivedAt={message.timestamp}
                                                    isRead={message.isRead}
                                                    setDelModalShow={setDelModalShow}
                                                    setDeleteModalData={setDeleteModalData}
                                                    senderImage={senderImage}
                                                    receiverImage={receiverImage}
                                            />
                                        )
                                    }
                                })
                                : <span style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'end',
                                    height: '100%',
                                    fontSize: '10px'
                                }}>To begin a chat, send a message</span>
                        }
                        <ThemeModal className={"deleteModal"} title={"Delete"} size={"sm"}
                                    content={<DeleteModal data={deleteModalData}/>} show={delModalShow}
                                    onHide={() => setDelModalShow(false)}/>
                    </div>

                    <div className="reply" style={{position: 'relative'}}>
                        <BarLoader color={'#961b1a'} loading={loading} css={'marginTop: 10px;position:absolute;'}
                                   height={'4'}
                                   width={'100%'}/>

                        <div style={{
                            display: 'flex',
                            alignItems: 'center',
                            flexDirection: 'row',
                            width: '100%',
                        }}>
                            <div className="reply-area" style={{width: '100%'}}>
                                <input type="text"
                                       disabled={vendorTextareaDisabled}
                                       className="form-control"
                                       placeholder="Type your message...."
                                       onKeyDown={onKeyDownMessage}
                                       style={{
                                           borderRadius: '20px',
                                           boxShadow: 'none',
                                           marginRight: '0px',
                                           float: 'right',
                                           resize: 'none',
                                           border: '0px',
                                           fontSize: '12px',
                                           height: '40px',
                                           background: '#efeded',
                                           paddingRight: "70px"
                                       }}
                                />
                                {/*<textarea
                                disabled={vendorTextareaDisabled}
                                className="form-control"
                                placeholder="Type your message...."
                                onKeyDown={SubmitMessage}></textarea>*/}
                            </div>

                            <div className="attach" style={{
                                position: 'absolute',
                                top: "10%",
                                right: "15px",
                                display: 'flex',
                                flexDirection: 'row'
                            }}>
                                <Form.Group controlId="vendorFile" className="" style={{marginRight: "10px"}}>
                                    <Form.Label style={{margin: 0}}>
                                        <img src={AttachIcon} alt="Add Attachments" width="16"/>
                                    </Form.Label>
                                    <Form.Control type="file"
                                                  name="vendor_file"
                                                  className={"d-none"}
                                                  onChange={vendorHandleChange}
                                                  ref={vendorFileInput}
                                                  accept="image/png,image/jpeg,application/pdf,.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,.xls,.xlsx,application/msexcel,audio/mp3,video/mp4"
                                    />
                                </Form.Group>
                                <Form.Label style={{marginBottom: 0, marginRight: "10px"}}>
                                    <img src={SendIcon} onClick={() => SubmitMessage(message)} alt="Send Message"
                                         width="16"
                                         style={{filter: "invert(0.4)", width: "20px"}}/>
                                </Form.Label>
                            </div>
                        </div>

                        <div className="upload-main-div" style={{
                            width: '100%',
                            background: '#000',
                            color: '#fff',
                            position: 'absolute',
                            bottom: '56px',
                            padding: '4px',
                            display: (vendorFile) ? 'block' : 'none'
                        }}>
                            <div className="upload-image-div" style={{width: '100%', position: 'relative'}}>
                                <div>
                                    <FaTimesCircle style={{position: 'absolute', right: '0', background: '#000'}}
                                                   onClick={() => {
                                                       setVendorFile('')
                                                       setVendorFileName('')
                                                       vendorFileInput.current.value = "";
                                                   }}/>
                                    <div style={{fontSize: '10px'}}>{vendorFileName}</div>
                                </div>
                            </div>
                        </div>


                    </div>
                </div>
            </div>
        </div>
    )
}

export default VendorChat
