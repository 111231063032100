import React, {useEffect, useState} from 'react'
import {AddIcon, AddIconOutline, MenuDots, TrashIcon, UploadIcon, SearchIcon} from "../../components/SvgIcons";
import {Col, Row, Form, Button, Dropdown, DropdownButton, InputGroup, FormControl} from "react-bootstrap"
import swal from "sweetalert";
import {
    GetReviews,
    DeleteReview,
} from "../../services/reviews.service";
import {toast} from "react-toastify";
import {constants, customStyles} from "../../utils/constants";
import DataTable from 'react-data-table-component';
import BarLoader from "react-spinners/BarLoader";
import {EmptyLocalStorage} from "../../services/auth/auth.service";
import {useHistory} from 'react-router-dom';

function ReviewList(props) {
    const history = useHistory();
    const [loading, setLoading] = useState(false);
    const [totalRows, setTotalRows] = useState(0);
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(constants.PERPAGE);
    const [rows, setRows] = useState();
    const [keyword, setKeyword] = useState('');

    useEffect(() => {
        getReviews();
    }, [page, perPage, keyword])

    const getReviews = async () => {
        setLoading(true)
        await GetReviews(page, perPage, keyword).then((result) => {
            if (result.status) {
                if (result?.data?.data) {
                    const rowData = [];
                    //setPage(result.meta.current_page)
                    //setTotalRows(result.meta.total);
                    result?.data?.data.map((dt, index) => {
                        console.log('==>', dt)
                        rowData.push({
                            Id: dt.id,
                            Index: ++index,
                            Review: dt?.review || '-',
                            Rate: dt?.rate || '-',
                            UserName: dt?.user?.full_name || '-',
                            OrderNumber: dt?.order?.order_number || '-',
                            ProductName: dt?.product?.name || '-',
                            //User: dt.user?.fullname || ''
                        });
                    })
                    setRows(rowData);
                    setLoading(false)
                }
            } else {
                setLoading(false)
                return toast.error(result.message);
            }
        }).catch((error) => {
            setLoading(false)
            if (error.response.status == 401) {
                EmptyLocalStorage()
                history.push('/');
            } else {
                return toast.error(error.response.data.message);
            }
        })
    }

    const onReviewDelete = (id) => {
        swal({
            title: "Are you sure?",
            text: "Are you sure you want to delete the selected Review?",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then(async (willDelete) => {
            if (willDelete) {
                await DeleteReview(id).then((result) => {
                    if (result.status) {
                        swal(result.message, {
                            icon: "success",
                        });
                        getReviews()
                    } else {
                        toast.error(result.message);
                    }
                }).catch((error) => {
                    if (error.response.status == 401) {
                        EmptyLocalStorage()
                        history.push('/');
                    } else {
                        return toast.error(error.response.data.message);
                    }
                })
            }
        });
    }

    const reviewActions = (data) => {
        return (
            <DropdownButton align="end" className={"actionDropdown"} id="dropdown-item-button" title={<MenuDots/>}>
                <Dropdown.Item as="button" onClick={() => onReviewDelete(data.Id)}>Delete</Dropdown.Item>
            </DropdownButton>
        )
    }

    const columns = [
        {
            id: 'Review',
            name: 'Review',
            selector: rowData => rowData.Review,
        },
        {
            id: 'Rate',
            name: 'Rate',
            selector: rowData => rowData.Rate,
        },
        {
            id: 'UserName',
            name: 'User',
            selector: rowData => rowData.UserName,
        },
        {
            id: 'OrderNumber',
            name: 'Order Number',
            selector: rowData => rowData.OrderNumber,
        },
        {
            id: 'ProductName',
            name: 'Product Name',
            selector: rowData => rowData.ProductName,
        },
        {
            field: 'action',
            name: 'Action',
            cell: rowData => reviewActions(rowData),
        },
    ];

    return (
        <div className={"manageReviews"}>
            <Row>
                <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                    <h3 className="page-heading">Manage Reviews</h3>
                </Col>
            </Row>
            <hr/>
            <div className={"customOrderFilters"}>
                <Row>
                    <Col xs={12} sm={12} md={5} lg={5} xl={5}>
                        <InputGroup className="mb-3 search-group">
                            <InputGroup.Text id="basic-addon1"><SearchIcon/></InputGroup.Text>
                            <FormControl
                                placeholder="Search"
                                aria-label="Search"
                                aria-describedby="Search"
                                maxLength={"255"}
                                onChange={(e) => {
                                    (e.target.value.length > 1) ? setKeyword(e.target.value) : setKeyword(null)
                                }}
                            />
                        </InputGroup>
                    </Col>
                </Row>
            </div>
            <div className={"mt-3"}>
                <div className="theme-dataGrid products-dataGrid">
                    <DataTable
                        columns={columns}
                        data={rows}
                        progressPending={loading}
                        customStyles={customStyles}
                        selectableRows={false}
                        striped
                        highlightOnHover
                        pagination
                        paginationServer
                        paginationTotalRows={totalRows}
                        paginationPerPage={perPage}
                        onChangePage={(page) => {
                            setPage(page);
                        }}
                        onChangeRowsPerPage={(currentRowsPerPage) => {
                            setPerPage(currentRowsPerPage)
                        }}
                        progressComponent={<BarLoader color={'#961b1a'} loading={loading} css={'marginTop: 10px'}
                                                      height={'4'} width={'100%'}/>}
                    />
                </div>
            </div>
        </div>
    )
}

export default ReviewList
