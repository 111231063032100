import React, { useState } from "react";

const ReadMore = ({ children, textLength }) => {
    const text = children;
    const [isReadMore, setIsReadMore] = useState(true);
    const toggleReadMore = () => {
        setIsReadMore(!isReadMore);
    };
    return (
        <p>
            {isReadMore ? text.slice(0, textLength) : text}
            <span onClick={toggleReadMore} className="read-or-hide">
                {isReadMore ? "Read more" : " Read less"}
            </span>
        </p>
    );
};

export default ReadMore