import React, {useState, useEffect} from 'react'
import {Col, Dropdown, DropdownButton, Form, FormControl, InputGroup, Row} from "react-bootstrap"
import {MenuDots, SearchIcon, StatusIcon} from "../../components/SvgIcons"
import {useHistory} from 'react-router-dom';
import {toast} from "react-toastify";
import {constants, customStyles} from "../../utils/constants";
import moment from "moment";
import DataTable from 'react-data-table-component';
import BarLoader from "react-spinners/BarLoader";
import {EmptyLocalStorage} from "../../services/auth/auth.service";
import {GetTransactions, UpdateTransactionStatus} from "../../services/transaction.service";
import {UpdateStatus} from "../../services/csa/orders.service";

function Payments() {
    let history = useHistory();
    const [loading, setLoading] = useState(false);
    const [totalRows, setTotalRows] = useState(0);
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(constants.PERPAGE);
    const [rows, setRows] = useState();
    const [keyword, setKeyword] = useState('');
    const [status, setStatus] = useState('');

    useEffect(async () => {
        await getPayments();
    }, [keyword, status, page, perPage])

    const getPayments = async () => {
        setLoading(true)
        await GetTransactions(page, perPage, keyword, status).then((result) => {
            if (result.status) {
                if (result.data.data) {
                    const rowData = [];
                    setPage(result.data.meta.current_page)
                    setTotalRows(result.data.meta.total);
                    result.data.data.map((dt, index) => {
                        rowData.push({
                            id: dt.id,
                            index: ++index,
                            user_id: dt?.user?.full_name || '',
                            order_id: dt?.order_id || '',
                            tran_ref: dt?.tran_ref || '',
                            tran_type: dt?.tran_type || '',
                            order_currency: dt?.order_currency || '',
                            order_amount: dt?.order_amount || '',
                            status: dt?.status || 0,
                            created_at: moment(dt.created_at).fromNow(),
                        });
                    })
                    setRows(rowData);
                    setLoading(false)
                }
            } else {
                setLoading(false)
                return toast.error(result.message);
            }
        }).catch((error) => {
            setLoading(false)
            if (error.response.status == 401) {
                EmptyLocalStorage()
                history.push('/');
            } else {
                return toast.error(error.response.data.message);
            }
        })
    }

    const onEdit = async (data) => {
        let transaction_id = data.id;
        let transaction_status = data.status;
        let order_status = data.status == 0 ? constants.ORDERSTATUS.PAID : constants.ORDERSTATUS.CONFIRMED;
        await UpdateStatus(data.order_id, {status: order_status}).then(async (data) => {
            if (data.status) {
                await UpdateTransactionStatus(transaction_id, {status: transaction_status}).then((data) => {
                    if (data.status) {
                        toast.success(data.message);
                        getPayments();
                    } else {
                        toast.error(data.message);
                    }
                }).catch((error) => {
                    toast.error(error.response.data.message);
                })
            } else {
                toast.error(data.message);
            }
        }).catch((error) => {
            toast.error(error.response.data.message);
        })
    }

    const userActions = (data) => {
        if (data?.status == 0) {
            return (
                <DropdownButton align="end" className={"actionDropdown"} id="dropdown-item-button" title={<MenuDots/>}>
                    <Dropdown.Item as="button" onClick={() => onEdit(data)}>Mark as Paid</Dropdown.Item>
                </DropdownButton>
            )
        }
    }

    const columns = [
        {
            id: 'user_id',
            name: 'User',
            maxWidth: '160px',
            selector: rowData => rowData.user_id,
        },
        {
            id: 'order_id',
            name: 'Order#',
            maxWidth: '160px',
            selector: rowData => rowData.order_id,
        },
        {
            id: 'tran_ref',
            name: 'Transaction Ref#',
            selector: rowData => rowData.tran_ref,
        },
        {
            id: 'order_currency',
            name: 'Order Currency',
            selector: rowData => rowData.order_currency,
        },
        {
            id: 'order_amount',
            name: 'Order Amount',
            selector: rowData => rowData.order_amount,
        },
        {
            id: 'status',
            name: 'Status',
            selector: rowData => rowData.status == 1 ? 'Paid' : 'Unpaid',
        },
        /*{
            id: 'created_at',
            name: 'Created At',
            selector: rowData => rowData.created_at,
        },*/
        {
            id: 'action',
            name: 'Action',
            cell: rowData => userActions(rowData)
        },
    ];

    return (
        <div className={"usersPage"}>
            <h3 className="page-heading">Manage Payments</h3>
            <hr/>
            <div className={"customOrderFilters"}>
                <Row>
                    <Col xs={6} sm={6} md={5} lg={5} xl={5}>
                        <InputGroup className="mb-3 search-group">
                            <InputGroup.Text id="basic-addon1"><SearchIcon/></InputGroup.Text>
                            <FormControl
                                placeholder="Search By Transaction Ref#"
                                aria-label="Search"
                                aria-describedby="Search"
                                maxLength={"255"}
                                onChange={(e) => {
                                    (e.target.value.length > 2) ? setKeyword(e.target.value) : setKeyword(null)
                                }}
                            />
                        </InputGroup>
                    </Col>

                    <Col xs={3} sm={3} md={3} lg={3} xl={3}>
                        <InputGroup className="mb-3 select-group">
                            <InputGroup.Text id="basic-addon1"><StatusIcon/></InputGroup.Text>
                            <Form.Select
                                onChange={e => {
                                    setStatus(e.target.value);
                                }}
                                aria-label="Default Filters">
                                <option selected={status == ''} value="">Status</option>
                                <option selected={status == '1'} value="1">Paid</option>
                                <option selected={status == '0'} value="0">Unpaid</option>
                            </Form.Select>
                        </InputGroup>
                    </Col>
                </Row>
            </div>
            <div className="theme-dataGrid products-dataGrid">
                <DataTable
                    columns={columns}
                    data={rows}
                    progressPending={loading}
                    customStyles={customStyles}
                    selectableRows={false}
                    striped
                    highlightOnHover
                    pagination
                    paginationServer
                    paginationTotalRows={totalRows}
                    paginationPerPage={perPage}
                    onChangePage={(page) => {
                        setPage(page);
                    }}
                    onChangeRowsPerPage={(currentRowsPerPage) => {
                        setPerPage(currentRowsPerPage)
                    }}
                    progressComponent={<BarLoader color={'#961b1a'} loading={loading} css={'marginTop: 10px'}
                                                  height={'4'} width={'100%'}/>}
                />
            </div>
        </div>
    )
}

export default Payments
