import React, {useState, useEffect} from 'react'
import {Row, Col, Table} from 'react-bootstrap'
import BreadcrumbsComponent from "../../components/BreadcrumbsComponent";
import ProductImageIcon from "../../assets/images/product-image@2x.png";
import {GetOrdersById} from "../../services/csa/orders.service"
import {toast} from "react-toastify";
import moment from 'moment';
import {MoneyFormat} from "../../utils/helpers";
import {constants} from "../../utils/constants";
import ReadMore from "../../components/ReadMore";
import {EmptyLocalStorage} from "../../services/auth/auth.service";
import {useHistory} from 'react-router-dom';

const InitialStore = {
    id: '',
    name: '',
}

const InitialVariant = {
    color: '',
    size: '',
    quantity: '',
    price: '',
    total_price: '',
}

const InitialOrderProducts = {
    id: '',
    name: '',
    store_id: '',
    image: '',
    store: [InitialStore],
    variants: [InitialVariant]
}

const InitialData = {
    order_number: '',
    total_amount: '',
    description: '',
    order_products: [InitialOrderProducts],
    order_summary: {
        status: '',
        created_at: '',
        shipping_date: '',
        total_amount: '',
        total_shipping_fees: '',
    },
    shipping_address: {
        address_line_1: '',
        street: '',
        postal_code: '',
        city: '',
        country: '',
    },
    customer: {
        full_name: '',
        phone: '',
        email: '',
        business_type: '',
    }
}

function OrderDetails(props) {
    const history = useHistory();
    const [data, setData] = useState(InitialData);

    useEffect(() => {
        let order_id = props.match.params.id;
        GetOrdersById(order_id).then((result) => {
            if (result.status) {
                if (result.data) {
                    console.log(result.data)
                    setData({
                        order_number: result.data.order_number,
                        total_amount: MoneyFormat(result.data.total_amount),
                        description: result.data.description,
                        order_products: result.data.order_products.flatMap((v) => {
                            return {
                                id: v.id,
                                name: v.product.name,
                                about: v.product.about_appearance,
                                store_id: v.store_id,
                                image: v.image,
                                store: {
                                    id: v.store.id,
                                    name: v.store.name,
                                },
                                variants: v.variants
                            }
                        }),
                        order_summary: {
                            status: constants.ORDERSTATUSKEY[result.data.status],
                            created_at: moment.utc(result?.data?.created_at).format('ddd, MMM DD, YYYY') || '',
                            shipping_date: moment.utc(result?.data?.shipping_date).format('ddd, MMM DD, YYYY') || '',
                            total_amount: MoneyFormat(result?.data?.total_amount) || '',
                            total_shipping_fees: MoneyFormat(result?.data?.total_shipping_fees) || '',
                        },
                        shipping_address: {
                            address_line_1: result?.data?.shipping_address?.address_line_1 || '',
                            street: result?.data?.shipping_address?.street || '',
                            postal_code: result?.data?.shipping_address?.zip || '',
                            city: result?.data?.shipping_address?.city || '',
                            country: result?.data?.shipping_address?.country || '',
                        },
                        customer: {
                            full_name: result?.data?.user?.full_name || '',
                            phone: result?.data?.user?.phone || '',
                            email: result?.data?.user?.email || '',
                            business_type: result?.data?.user?.business_type || '',
                        },
                        order_shipping: {
                            created_ago: result?.data?.order_shipping[0] ? result?.data?.order_shipping[0].created_ago : '',
                            created_at: result?.data?.order_shipping[0] ? moment.utc(result?.data?.order_shipping[0].created_at).format('ddd, MMM DD, YYYY') || '' : '',
                            id: result?.data?.order_shipping[0] ? result?.data?.order_shipping[0].id : '',
                            order_id: result?.data?.order_shipping[0] ? result?.data?.order_shipping[0].order_id : '',
                            shipping_destination: result?.data?.order_shipping[0] ? result?.data?.order_shipping[0].shipping_destination : '',
                            shipping_id: result?.data?.order_shipping[0] ? result?.data?.order_shipping[0].shipping_id : '',
                            shipping_origin: result?.data?.order_shipping[0] ? result?.data?.order_shipping[0].shipping_origin : '',
                            shipping_url: result?.data?.order_shipping[0] ? result?.data?.order_shipping[0].shipping_url : '',
                            shipping_weight_unit: result?.data?.order_shipping[0] ? result?.data?.order_shipping[0].shipping_weight_unit : '',
                            shipping_weight_value: result?.data?.order_shipping[0] ? result?.data?.order_shipping[0].shipping_weight_value : '',
                            user_id: result?.data?.order_shipping[0] ? result?.data?.order_shipping[0].user_id : '',
                        }
                    });
                }
            } else {
                toast.error(result.message);
            }
        }).catch((error) => {
            if (error.response.status == 401) {
                EmptyLocalStorage()
                history.push('/');
            } else {
                return toast.error(error.response.data.message);
            }
        })
    }, [])

    const orderSummary = data.order_products.flatMap((product, indexProduct) => {
        return <tbody key={indexProduct}>
        <tr>
            <td colSpan={5}>
                <div className={"ItemDescription"}>
                    <Row className={"g-0"}>
                        <Col xs={2}>
                            <div className={"img-bg"}>
                                <img onError={({currentTarget}) => {
                                    currentTarget.onerror = null; // prevents looping
                                    currentTarget.src = constants.IMAGENOTFOUND;
                                }} src={product.image || ProductImageIcon} alt={"Product Image"}
                                     title={"Product Image"} className={"img-fluid"} width={"36px"}
                                     width={"49px"}/>
                            </div>
                        </Col>
                        <Col xs={10}>
                            <h4>{product.name}</h4>
                            <p>
                                {product.about}
                            </p>

                            {/*<ReadMore textLength={"40"}>*/}
                            {/*    {product.about}*/}
                            {/*</ReadMore>*/}

                        </Col>
                    </Row>
                </div>
            </td>
        </tr>
        <tr>
            <td colSpan={5}>
                <h4>Variations</h4>
            </td>
        </tr>

        {
            product.variants.map((variant, indexVariant) => {
                let total_price = parseInt(variant.price) * parseInt(variant.quantity);
                return <tr key={indexVariant}>
                    <td colSpan={2}>
                        <div className={"d-flex justify-content-between"}>
                            <span>Color: {variant.color}</span>
                            <span>Size: {variant.size}</span>
                        </div>
                    </td>
                    <td>x {variant.quantity}</td>
                    <td>{MoneyFormat(variant.price)}</td>
                    <td>{MoneyFormat(total_price)}</td>
                </tr>
            })
        }
        </tbody>
    })

    return (
        /*add page name as class to each parent div of the page like here*/
        <div className={"orderPage OrderDetails"}>
            {/*<BreadcrumbsComponent/>*/}
            <h3 className="page-heading">Order Number <span>#{data.order_number}</span></h3>
            <hr/>

            <Row>
                <Col xs={12} sm={12} md={7} lg={7} xl={7}>
                    <div className={"BoxCustom ItemSummary mb-4"}>
                        <Table>
                            <thead>
                            <tr>
                                <th colSpan={2}>Items Summary</th>
                                <th>Qty</th>
                                <th>Price</th>
                                <th>Total Price</th>
                            </tr>
                            </thead>

                            {orderSummary}

                        </Table>
                    </div>
                    <div className={"BoxCustom Details mb-4"}>
                        <div className={"box-header mb-0"}>
                            <Row className={"g-0 align-items-center"}>
                                <Col xs={12}>
                                    <h4>
                                        Customer Details
                                    </h4>
                                </Col>
                            </Row>
                        </div>
                        <hr className={"mb-3"}/>
                        <div className={"box-body"}>
                            <ul className={"list-unstyled"}>
                                <li>
                                    <span>Customer Name</span>
                                    <span>{data.customer.full_name}</span>
                                </li>
                                <li>
                                    <span>Phone Number</span>
                                    <span>{data.customer.phone}</span>
                                </li>
                                <li>
                                    <span>Email</span>
                                    <span>{data.customer.email}</span>
                                </li>
                                <li>
                                    <span>Business Type</span>
                                    <span>{data.customer.business_type}</span>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className={"BoxCustom TotalRow mb-4"}>
                        <div className={"box-header mb-0"}>
                            <Row className={"g-0 align-items-center"}>
                                <Col xs={8}>
                                    <h4>
                                        Total
                                    </h4>
                                </Col>
                                <Col xs={4}>
                                    <h4 className={"text-end"}>
                                        {data.total_amount}
                                    </h4>
                                </Col>

                            </Row>
                        </div>
                    </div>
                </Col>
                <Col xs={12} sm={12} md={5} lg={5} xl={5}>
                    <div className="productDetails mb-4">
                        <h4 className={"mb-3"}>Product Description</h4>
                        <p>{data.description}</p>
                    </div>
                    <div className={"BoxCustom OrderSummary mb-4"}>
                        <div className={"box-header"}>
                            <Row className={"g-0 align-items-center"}>
                                <Col xs={8}>
                                    <h4>
                                        Order Summary
                                    </h4>
                                </Col>
                                <Col xs={4}>
                                    <div className={"status-badge status-completed"}>
                                        <span>{data.order_summary.status}</span>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                        <div className={"box-body"}>
                            <ul className={"list-unstyled"}>
                                <li>
                                    <span>Order Created</span>
                                    <span>{data.order_summary.created_at}</span>
                                </li>
                                {/*<li>
                                    <span>Shipping </span>
                                    <span>{data.order_summary.shipping_date}</span>
                                </li>*/}
                                <li>
                                    <span>Sub Total</span>
                                    <span>{data.order_summary.total_amount}</span>
                                </li>
                                {/*<li>
                                    <span>Shipping Amount</span>
                                    <span>{data.order_summary.total_shipping_fees}</span>
                                </li>*/}
                            </ul>
                        </div>
                    </div>
                    <div className={"BoxCustom ShippingAddress mb-4"}>
                        <div className={"box-header"}>
                            <Row className={"g-0 align-items-center"}>
                                <Col xs={8}>
                                    <h4>
                                        Shipping Address
                                    </h4>
                                </Col>
                            </Row>
                        </div>
                        <div className={"box-body"}>
                            <ul className={"list-unstyled"}>
                                <li>
                                    <span>Address Line</span>
                                    <span>{data.shipping_address.address_line_1}</span>
                                </li>
                                <li>
                                    <span>House/Building No.</span>
                                    <span>{data.shipping_address.street}</span>
                                </li>
                                <li>
                                    <span>Post Code</span>
                                    <span>{data.shipping_address.postal_code}</span>
                                </li>
                                <li>
                                    <span>City</span>
                                    <span>{data.shipping_address.city}</span>
                                </li>
                                <li>
                                    <span>Country</span>
                                    <span>{data.shipping_address.country}</span>
                                </li>
                            </ul>
                        </div>
                    </div>

                    {
                        data?.order_shipping && data?.order_shipping?.shipping_id ?
                            <div className={"BoxCustom ShippingAddress mb-4"}>
                                <div className={"box-header"}>
                                    <Row className={"g-0 align-items-center"}>
                                        <Col xs={8}>
                                            <h4>
                                                Order Shipping Details
                                            </h4>
                                        </Col>
                                    </Row>
                                </div>
                                <div className={"box-body"}>
                                    <ul className={"list-unstyled"}>
                                        <li>
                                            <span>Destination</span>
                                            <span>{data?.order_shipping?.shipping_destination}</span>
                                        </li>
                                        <li>
                                            <span>Tracking #.</span>
                                            <span>{data?.order_shipping?.shipping_id}</span>
                                        </li>
                                        <li>
                                            <span>Origin</span>
                                            <span>{data?.order_shipping?.shipping_origin}</span>
                                        </li>
                                        <li>
                                            <span>Shipping Tag</span>
                                            <span><a target="_blank" href={data?.order_shipping?.shipping_url}
                                                     download>Download</a></span>
                                        </li>
                                        <li>
                                            <span>Weight Unit</span>
                                            <span>{data?.order_shipping?.shipping_weight_unit}</span>
                                        </li>
                                        <li>
                                            <span>Weight Value</span>
                                            <span>{data?.order_shipping?.shipping_weight_value}</span>
                                        </li>
                                        <li>
                                            <span>Created At</span>
                                            <span>{data?.order_shipping?.created_at}</span>
                                        </li>
                                    </ul>
                                </div>
                            </div> : ''
                    }


                </Col>
            </Row>

        </div>
    )
}

export default OrderDetails
