import {apiUrl} from '../utils/constants';
import {GET, POST, PUT, DELETE} from './api.service.wrapper';

export const GetNotifications = async (page, perPage) => {
    let params = {};

    if(page){
        params['page'] = page;
    }

    if(perPage){
        params['per-page'] = perPage;
    }

    const response = await GET(apiUrl.notifications, params);
    return response;
}

export const GetNotificationsCount = async (page, perPage) => {
    let params = {};

    if(page){
        params['page'] = page;
    }

    if(perPage){
        params['per-page'] = perPage;
    }

    const response = await GET(apiUrl.notifications_count, params);
    return response;
}


export const MarkNotificationRead = async (data) => {
    const response = await POST(`${apiUrl.mark_all_as_read}`, data);
    return response;
  };

export const GetCategoryById = async (id) => {
    let response = '';
    response = await GET(apiUrl.categories + '/' + id);
    return response;
}

