import React, {useEffect, useState} from 'react'
import {constants} from "../utils/constants";
import {GetUserRole} from "../services/auth/auth.service"
import {Admin, Agent, Officer, Representative} from "./SideMenu/index"

function SideBar() {
    const [sideBar, setSideBar] = useState();

    useEffect(() => {
        let role = GetUserRole();

        if(role == constants.ROLES.ROLE_ADMIN){
            setSideBar(<Admin />);
        }
        else if(role == constants.ROLES.ROLE_AGENT){
            setSideBar(<Agent />);
        }
        else if(role == constants.ROLES.ROLE_OFFICER){
            setSideBar(<Officer />);
        }
        else if(role == constants.ROLES.ROLE_REPRESENTATIVE){
            setSideBar(<Representative />);
        }
    }, []);

    return (
        <div className="d-flex flex-column flex-shrink-0 mt-2 h-100 sideBar">
            {sideBar}
        </div>
    )
}

export default SideBar
