import React from 'react'
import MaterialTable from 'material-table'
import swal from 'sweetalert';
import {SearchIcon, FilterIcon, StatusIcon, CalenderIcon, MenuDots} from '../../components/SvgIcons'
import {Row, Col, InputGroup, FormControl, Form, Dropdown, DropdownButton} from 'react-bootstrap'


const statusCheck = (status) => {
    if (status === "Completed"){
        return <div className={"status-badge status-completed"}>{status}</div>
    }
    if (status === "Cancelled"){
        return <div className={"status-badge status-cancelled"}>{status}</div>
    }
    if (status === "In Progress"){
        return <div className={"status-badge status-progress"}>{status}</div>
    }
    return <div className={"status-badge status-undefined"}>{status}</div>
}

const onOrderDelete = () => {
    swal({
        title: "Are you sure?",
        text: "Are you sure you want to delete this imaginary order?",
        icon: "warning",
        buttons: true,
        dangerMode: true,
    })
        .then((willDelete) => {
            if (willDelete) {
                swal("Poof! Your imaginary order has been deleted!", {
                    icon: "success",
                });
            }
        });
}

const orderActions = (data) => {
    return(
        <DropdownButton align="end" className={"actionDropdown"} id="dropdown-item-button" title={<MenuDots/>}>
            <Dropdown.Item as="button">Edit</Dropdown.Item>
            <Dropdown.Item as="button" onClick={onOrderDelete}>Delete</Dropdown.Item>
        </DropdownButton>
    )
}

const rows = [
    { id: 1, oid: 'ABC12345', customerName: 'Bryson Max', agentName: 'Jordyn Kayden', order: 'Red Velvet Bag', deliveryDate: '04.12.2021', price: '$24.90', status: 'Completed', action: '' },
    { id: 1, oid: 'ABC12345', customerName: 'Bryson Max', agentName: 'Jordyn Kayden', order: 'Red Velvet Bag', deliveryDate: '04.12.2021', price: '$24.90', status: 'Cancelled', action: '' },
    { id: 1, oid: 'ABC12345', customerName: 'Bryson Max', agentName: 'Jordyn Kayden', order: 'Red Velvet Bag', deliveryDate: '04.12.2021', price: '$24.90', status: 'In Progress', action: '' },
    { id: 1, oid: 'ABC12345', customerName: 'Bryson Max', agentName: 'Jordyn Kayden', order: 'Red Velvet Bag', deliveryDate: '04.12.2021', price: '$24.90', status: 'Completed', action: '' },
    { id: 1, oid: 'ABC12345', customerName: 'Bryson Max', agentName: 'Jordyn Kayden', order: 'Red Velvet Bag', deliveryDate: '04.12.2021', price: '$24.90', status: 'Cancelled', action: '' },
    { id: 1, oid: 'ABC12345', customerName: 'Bryson Max', agentName: 'Jordyn Kayden', order: 'Red Velvet Bag', deliveryDate: '04.12.2021', price: '$24.90', status: 'Completed', action: '' },
    { id: 1, oid: 'ABC12345', customerName: 'Bryson Max', agentName: 'Jordyn Kayden', order: 'Red Velvet Bag', deliveryDate: '04.12.2021', price: '$24.90', status: 'Completed', action: '' },
    { id: 1, oid: 'ABC12345', customerName: 'Bryson Max', agentName: 'Jordyn Kayden', order: 'Red Velvet Bag', deliveryDate: '04.12.2021', price: '$24.90', status: 'In Progress', action: '' },
    { id: 1, oid: 'ABC12345', customerName: 'Bryson Max', agentName: 'Jordyn Kayden', order: 'Red Velvet Bag', deliveryDate: '04.12.2021', price: '$24.90', status: 'Completed', action: '' },
    { id: 1, oid: 'ABC12345', customerName: 'Bryson Max', agentName: 'Jordyn Kayden', order: 'Red Velvet Bag', deliveryDate: '04.12.2021', price: '$24.90', status: 'Completed', action: '' },
    { id: 1, oid: 'ABC12345', customerName: 'Bryson Max', agentName: 'Jordyn Kayden', order: 'Red Velvet Bag', deliveryDate: '04.12.2021', price: '$24.90', status: 'In Progress', action: '' },
    { id: 1, oid: 'ABC12345', customerName: 'Bryson Max', agentName: 'Jordyn Kayden', order: 'Red Velvet Bag', deliveryDate: '04.12.2021', price: '$24.90', status: 'Completed', action: '' },
    { id: 1, oid: 'ABC12345', customerName: 'Bryson Max', agentName: 'Jordyn Kayden', order: 'Red Velvet Bag', deliveryDate: '04.12.2021', price: '$24.90', status: 'Cancelled', action: '' },
    { id: 1, oid: 'ABC12345', customerName: 'Bryson Max', agentName: 'Jordyn Kayden', order: 'Red Velvet Bag', deliveryDate: '04.12.2021', price: '$24.90', status: 'Completed', action: '' },
    { id: 1, oid: 'ABC12345', customerName: 'Bryson Max', agentName: 'Jordyn Kayden', order: 'Red Velvet Bag', deliveryDate: '04.12.2021', price: '$24.90', status: 'Undefined', action: '' },
];

const columns = [
    { field: 'oid', title: 'Order ID', cellStyle: {color: '#9F9F9F', width: '120px', padding: '5px 0px', lineHeight: '32px', borderBottom: 'transparent',} },
    { field: 'customerName', title: 'Customer', maxWidth: '160px', cellStyle: {color: '#000000', width: '250px', padding: '5px 0px', borderBottom: 'transparent',} },
    { field: 'agentName', title: 'CSA Agent', maxWidth: '160px', cellStyle: {color: '#000000', width: '250px', padding: '5px 0px', borderBottom: 'transparent',} },
    { field: 'order', title: 'Order', cellStyle: {color: '#9F9F9F', width: '350px', padding: '5px 0px', borderBottom: 'transparent',} },
    { field: 'deliveryDate', title: 'Delivery Date', cellStyle: {color: '#9F9F9F', width: '200px', padding: '5px 0px', borderBottom: 'transparent',}},
    { field: 'price', title: 'Price', cellStyle: {color: '#9F9F9F', width: '120px', padding: '5px 0px', borderBottom: 'transparent',}},
    { field: 'status', title: 'Status', render: rowData => statusCheck(rowData.status), cellStyle: {color: '#9F9F9F', width: '120px', padding: '5px 0px', borderBottom: 'transparent'}},
    { field: 'action', title: 'Action', render: rowData => orderActions(rowData.customerName), cellStyle: {color: '#9F9F9F', width: '120px', padding: '5px 0px', margin: '0px auto', textAlign: 'center', borderBottom: 'transparent',}},
];

function OrderHistory() {

    return (
        /*add page name as class to each parent div of the page like here*/
        <div className={"orderPage"}>
            <h3 className="page-heading">New Orders</h3>
            <hr />
            <div className={"customOrderFilters"}>
                <Row>
                    <Col xs={12} sm={12} md={5} lg={5} xl={5}>
                        <InputGroup className="mb-3 search-group">
                            <InputGroup.Text id="basic-addon1"><SearchIcon /></InputGroup.Text>
                            <FormControl
                                placeholder="Search"
                                aria-label="Search"
                                aria-describedby="Search"
                                maxLength={"255"}
                            />
                        </InputGroup>
                    </Col>
                    <Col xs={12} sm={12} md={7} lg={7} xl={7}>
                        <Row>
                            <Col xs={12} sm={12} md={4} lg={4} xl={4}>
                                <InputGroup className="mb-3 select-group">
                                    <InputGroup.Text id="basic-addon1"><FilterIcon /></InputGroup.Text>
                                    <Form.Select aria-label="Default Filters">
                                        <option selected disabled>Filters</option>
                                        <option value="1">One</option>
                                        <option value="2">Two</option>
                                        <option value="3">Three</option>
                                    </Form.Select>
                                </InputGroup>

                            </Col>
                            <Col xs={12} sm={12} md={4} lg={4} xl={4}>
                                <InputGroup className="mb-3 select-group">
                                    <InputGroup.Text id="basic-addon1"><CalenderIcon /></InputGroup.Text>
                                    <Form.Select aria-label="Default Filters">
                                        <option selected disabled>Date Range</option>
                                        <option value="1">One</option>
                                        <option value="2">Two</option>
                                        <option value="3">Three</option>
                                    </Form.Select>
                                </InputGroup>
                            </Col>
                            <Col xs={12} sm={12} md={4} lg={4} xl={4}>
                                <InputGroup className="mb-3 select-group">
                                    <InputGroup.Text id="basic-addon1"><StatusIcon /></InputGroup.Text>
                                    <Form.Select aria-label="Default Filters">
                                        <option selected disabled>Status</option>
                                        <option value="Completed">Completed</option>
                                        <option value="In Progress">In Progress</option>
                                        <option value="Cancelled">Cancelled</option>
                                    </Form.Select>
                                </InputGroup>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </div>
            <div className="theme-dataGrid new-orders-dataGrid">
                <MaterialTable
                    title={false}
                    toolbar={false}
                    isLoading={false}
                    columns={columns}
                    data={rows}
                    tableLayout={"auto"}
                    options={{
                        search: false,
                        headerStyle: {
                            color: '#000',
                            fontSize: '13px',
                            fontWeight: '500',
                            lineHeight: '19px',
                            fontFamily: 'Poppins',
                            padding: '5px',
                        },
                        rowStyle: {
                            backgroundColor: '#fff',
                            padding: '5px',
                            fontSize: '13px',
                            fontWeight: '400',
                            lineHeight: '19px',
                            fontFamily: 'Poppins',
                        },

                    }}
                />
            </div>
        </div>
    )
}

export default OrderHistory
