import {apiUrl} from '../utils/constants';
import {GET, POST, PUT, DELETE} from './api.service.wrapper';

export const GetTransactions = async (page= '', perPage= '', keyword= '', status = '') => {
    let params = {};

    if(page){
        params['page'] = page;
    }

    if(perPage){
        params['per-page'] = perPage;
    }

    if(keyword){
        params['keyword'] = keyword;
    }

    if(status){
        params['status'] = status;
    }

    const response = await GET(apiUrl.transactions, params);
    return response;
}

export const UpdateTransactionStatus = async (id, data) => {
    const response = await PUT(apiUrl.update_transaction_status, id, data);
    return response;
}

