import React, { useRef, useState, useEffect } from "react";
import {
  Form,
  Row,
  Col,
  Button,
  InputGroup,
  FormControl,
} from "react-bootstrap";
import BreadcrumbsComponent from "../../components/BreadcrumbsComponent";
import { useForm, Controller } from "react-hook-form";
import { CreateUser, GetUsers } from "../../services/users.service";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import { constants } from "../../utils/constants";
import { createUser } from "../../services/firebase.service";
import { format } from "date-fns";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { EmptyLocalStorage } from "../../services/auth/auth.service";
import { UploadIcon } from "../../components/SvgIcons";
import { UploadImageToS3 } from "../../utils/helpers";
// import { thumbnailsSettings } from "lightgallery/plugins/thumbnail/lg-thumbnail-settings";
import Select from "react-select";

function AddNewUser(props) {
  let history = useHistory();
  const [data, setData] = useState([]);
  const [pass, TogglePass] = useState(true);
  const [confPass, ToggleConfPass] = useState(true);
  const [confirmPass, setConfirmPass] = useState(true);
  const [disableButton, setDisableButton] = useState(false);
  const [defaultImage, setDefaultImage] = useState(false);
  const [file, setFileData] = useState(false);
  const [selectedFile, setSelectedFile] = useState(false);
  const [rowsAgent, setRowsAgent] = useState();
  const [rowsProgram, setRowsProgram] = useState([
    {label: 'Pre Trial', value: '10'}, 
    {label: 'Probation', value: '20'}, 
    {label: 'Sober Count', value: '30'}, 
    {label: 'Verterans Court', value: '40'}
  ]);
  const [rowsOfficer, setRowsOfficer] = useState();
  const [rowsRep, setRowsRep] = useState();
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(constants.PERPAGE);
  const [keyword, setKeyword] = useState("");

  const {
    register,
    watch,
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
  });

  useEffect(async () => {
    await getAgents();
    await getOfficers();
    await getRepresentative();
  }, [keyword, page, perPage]);

  const getAgents = async () => {
    setLoading(true);
    await GetUsers(keyword, constants.ROLES.ROLE_AGENT, page, perPage)
      .then((result) => {
        if (result.success) {
          if (result.data) {
            const rowData = [];
            result.data.map((dt, index) => {
              rowData.push({
                value: dt.id,
                label: dt.full_name || "-",
              });
            });
            setRowsAgent(rowData);
            setLoading(false);
          }
        } else {
          setLoading(false);
          return toast.error(result.message);
        }
      })
      .catch((error) => {
        setLoading(false);
        if (error.response.status == 401) {
          EmptyLocalStorage();
          history.push("/");
        } else {
          return toast.error(error.response.data.message);
        }
      });
  };

  const getOfficers = async () => {
    setLoading(true);
    await GetUsers(keyword, constants.ROLES.ROLE_OFFICER, page, perPage)
      .then((result) => {
        if (result.success) {
          if (result.data) {
            const rowData = [];
            result.data.map((dt, index) => {
              rowData.push({
                value: dt.id,
                label: dt.full_name || "-",
              });
            });
            setRowsOfficer(rowData);
            setLoading(false);
          }
        } else {
          setLoading(false);
          return toast.error(result.message);
        }
      })
      .catch((error) => {
        setLoading(false);
        if (error.response.status == 401) {
          EmptyLocalStorage();
          history.push("/");
        } else {
          return toast.error(error.response.data.message);
        }
      });
  };

  const getRepresentative = async () => {
    setLoading(true);
    await GetUsers(keyword, constants.ROLES.ROLE_REPRESENTATIVE, page, perPage)
      .then((result) => {
        if (result.success) {
          if (result.data) {
            const rowData = [];
            result.data.map((dt, index) => {
              rowData.push({
                value: dt.id,
                label: dt.full_name || "-",
              });
            });
            setRowsRep(rowData);
            setLoading(false);
          }
        } else {
          setLoading(false);
          return toast.error(result.message);
        }
      })
      .catch((error) => {
        setLoading(false);
        if (error.response.status == 401) {
          EmptyLocalStorage();
          history.push("/");
        } else {
          return toast.error(error.response.data.message);
        }
      });
  };

  const handleChange = (event) => {
 
    setSelectedFile(event.target.files[0]);
    setFileData(URL.createObjectURL(event.target.files[0]));
  };

  const password = useRef({});
  password.current = watch("password", "");
  const onSubmit = async (data) => {
    setDisableButton(true);

    let url = "";
    if (selectedFile) {
      if (
        selectedFile.type == "image/jpeg" ||
        selectedFile.type == "image/jpg" ||
        selectedFile.type == "image/png"
      ) {
        const myFile = new File(
          [ selectedFile],
        `${data.full_name}`,
        {type:selectedFile.type}
         )
        let uploadImage = await UploadImageToS3(myFile);
        if (uploadImage) {
          console.log(uploadImage);
          if (!uploadImage.success) {
            toast.error(uploadImage.message);
            return false;
          }

          // content_type = constants.CONTENTTYPE[file.type];
          url = uploadImage.data;
          console.log(url, "url")
          // filename = file.name;
        }
        console.log("data: ", data)
        await CreateUser({
          full_name: data.full_name,
          state: data.state,
          email: data.email,
          phone_number: data.phone_number,
          password: data.password,
          license: data.license,
          dob: data.dob,
          agent: data.agent.value,
          officer: data.officer.value,
          officer_1: data.officer1 == undefined ? null : data.officer1.value,
          officer_2: data.officer2 == undefined ? null : data.officer2.value,
          officer_3: data.officer3 == undefined ? null : data.officer3.value,
          payment_date: data.payment_date,
          representative: data.representative.value,
          program: data.program.value,
          county: data.county,
          installation_date: data.installation_date,
          serial: data.serial,
          profile_image: url,
          device_type: "web",
          device_token: "user",
        })
          .then(async (data) => {
            if (data.success) {
              toast.success(data.message);
              setValue("agent", null);
              setValue("officer", null);
              history.push("/manage-user");
              // await createUser(data.user).then(() => {
              // });
            } else {
              setDisableButton(false);
              toast.error(data.message);
            }
          })
          .catch((error) => {
            setDisableButton(false);
            if (error.response.status == 401) {
              EmptyLocalStorage();
              history.push("/");
            } else {
              console.log(error);
              return toast.error(error.response.data.message);
            }
          });
      }else {
        toast.error("Invalid file type.");
        setDisableButton(false);
      }
    }  
  };

  const [agentId, setAgentId] = useState("");
  const [officerId, setOfficerId] = useState("");
  const [disableField, setDisableField] = useState(true);
  const [selectedOptionAgent, setSelectedOption] = useState("");
  const [selectedOptionOfficer, setSelectedOptionOfficer] = useState("");
  // console.log("selected option: ", selectedOption)

  const handleSelectAgent = (selectedOption) => {
    setSelectedOption(selectedOption);
    console.log(`Option selected:`, selectedOption);
  };

  const handleSelectOfficer = (selectedOption) => {
    setSelectedOptionOfficer(selectedOption);
    console.log(`Option selected:`, selectedOptionOfficer);
  };

  return (
    <div className="AddNewUser">
      {/*<BreadcrumbsComponent />*/}
      <h3 className="page-heading">Create User</h3>
      <hr />
      <Row>
        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
          <Form
            className="formAddUser"
            autoComplete="off"
            role="presentation"
            onSubmit={handleSubmit(onSubmit)}
          >
            <Row>
              <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                <Row
                  className={"mb-3 justify-content-center align-items-center"}
                >
                  <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                    <Form.Group
                      controlId="formFile"
                      onChange={(e) => handleChange(e)}
                      className="custom-file-upload mb-0"
                    >
                      <Form.Label className={"toggleFileUpload mb-0"}>
                        <UploadIcon />
                        Upload Image
                      </Form.Label>
                      <Form.Control
                        type="file"
                        accept="image/png, image/jpeg, image/jpg, image/gif"
                        {...register("media", {
                          required: {
                            value: "required",
                            message: "Profile image is required",
                          },
                        })}
                      />
                      {errors.media && (
                        <Form.Text className="text-muted validationText hasError">
                          {errors.media.message}
                        </Form.Text>
                      )}
                    </Form.Group>
                  </Col>
                  <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                    <ul className={"list-inline list-variations mb-0"}>
                      <li
                        className={
                          "list-inline-item d-flex justify-content-between mb-0"
                        }
                      >
                        <img
                          onError={({ currentTarget }) => {
                            currentTarget.onerror = null; // prevents looping
                            currentTarget.src = constants.IMAGENOTFOUND;
                          }}
                          src={file ? file : defaultImage}
                          width="50%"
                          height="50%"
                        />
                      </li>
                    </ul>
                  </Col>
                </Row>
              </Col>
            </Row>

            <Row>
              <Col xs={12} sm={12} md={8} lg={4} xl={4}>
                <Form.Group className="mb-3" controlId="full_name">
                  <Form.Label>Full Name</Form.Label>
                  <Form.Control
                    placeholder="Enter Full Name"
                    id={"formSignUpName"}
                    // onChange={e => {
                    //   const re = /^[A-Za-z]+$/;
                    //   if (e.target.value === "" || re.test(e.target.value))
                    //     setTextOnly(textOnly);
                    // }}
                    {...register("full_name", {
                      required: {
                        value: "required",
                        message: "Full Name is required",
                      },
                      pattern: {
                        value: /^[A-Za-z ]+$/,
                        message: "Invalid full name",
                      },
                      minLength: {
                        value: 3,
                        message: "Minimum length is 3",
                      },
                      maxLength: {
                        value: 25,
                        message: "Maximum length is 25",
                      },
                    })}
                    type="text"
                  />
                  {errors.full_name && (
                    <Form.Text className="text-muted validationText hasError">
                      {errors.full_name.message}
                    </Form.Text>
                  )}
                </Form.Group>
              </Col>
              <Col xs={12} sm={12} md={8} lg={4} xl={4}>
                <Form.Group className="mb-3" controlId="email">
                  <Form.Label>Email Address</Form.Label>
                  <Form.Control
                    placeholder="Enter Email Address"
                    autocomplete="off"
                    autofill="off"
                    {...register("email", {
                      required: {
                        value: "required",
                        message: "Email is required",
                      },
                      pattern: {
                        value: /\S+@\S+\.\S+/,
                        message: "Invalid email",
                      },
                      maxLength: {
                        value: 255,
                        message: "Maximum length is 255",
                      },
                    })}
                    type="text"
                  />
                  {errors.email && (
                    <Form.Text className="text-muted validationText hasError">
                      {errors.email.message}
                    </Form.Text>
                  )}
                </Form.Group>
              </Col>
           
          
              <Col xs={12} sm={12} md={8} lg={4} xl={4}>
                <Form.Group className="mb-3" controlId="phone_number">
                  <Form.Label>Phone Number</Form.Label>
                  <Form.Control
                    type="tel"
                    placeholder="Enter Phone Number"
                    {...register("phone_number", {
                      required: {
                        value: "required",
                        message: "Phone is required",
                      },
                      pattern: {
                        value: /^\+[0-9]*$/,
                        message: "incorrect pattern +xxxxxxxxxxxx",
                      },
                      minLength: {
                        value: 8,
                        message: "Minimum length is 8",
                      },
                      maxLength: {
                        value: 16,
                        message: "Maximum length is 16",
                      },
                    })}
                  />
                  {errors.phone_number && (
                    <Form.Text className="text-muted validationText hasError">
                      {errors.phone_number.message}
                    </Form.Text>
                  )}
                </Form.Group>
              </Col>
              <Col xs={12} sm={12} md={8} lg={4} xl={4}>
                <Form.Group className="mb-3" controlId="state">
                  <Form.Label>State</Form.Label>
                  <Form.Control
                    placeholder="Enter State"
                    id={"formSignUpName"}
                    {...register("state", {
                      required: {
                        value: "required",
                        message: "State is required",
                      },
                      pattern: {
                        value: /^[A-Za-z ]+$/,
                        message: "invalid state name entered",
                      },
                      minLength: {
                        value: 3,
                        message: "Minimum length is 3",
                      },
                      maxLength: {
                        value: 25,
                        message: "Maximum length is 25",
                      },
                    })}
                    type="text"
                  />
                  {errors.state && (
                    <Form.Text className="text-muted validationText hasError">
                      {errors.state.message}
                    </Form.Text>
                  )}
                </Form.Group>
              </Col>
          
           
              <Col xs={12} sm={12} md={8} lg={4} xl={4}>
                <Form.Group className="mb-3" controlId="officer">
                  <Form.Label>Officer 1</Form.Label>
                  <Controller
                    control={control}
                    name="officer"
                    rules={{
                      required: true,
                    }}
                    render={({
                      field: { onChange, onBlur, value, name, ref },
                    }) => (
                      <Select
                        name="officer"
                        value={value}
                        onChange={onChange}
                        options={rowsOfficer}
                        isSearchable={true}
                        className="react-select-container"
                        classNamePrefix="react-select"
                      />
                    )}
                  />

                  {errors.officer && (
                    <Form.Text className="text-muted validationText hasError">
                      Officer is required
                    </Form.Text>
                  )}
                </Form.Group>
              </Col>

              <Col xs={12} sm={12} md={8} lg={4} xl={4}>
                <Form.Group className="mb-3" controlId="officer1">
                  <Form.Label>Officer 2</Form.Label>
                  <Controller
                    control={control}
                    name="officer1"
                    render={({
                      field: { onChange, onBlur, value, name, ref },
                    }) => (
                      <Select
                        name="officer1"
                        value={value}
                        onChange={onChange}
                        options={rowsOfficer}
                        isSearchable={true}
                        className="react-select-container"
                        classNamePrefix="react-select"
                      />
                    )}
                  />
                </Form.Group>
              </Col>
              <Col xs={12} sm={12} md={8} lg={4} xl={4}>
                <Form.Group className="mb-3" controlId="officer2">
                  <Form.Label>Officer 3</Form.Label>
                  <Controller
                    control={control}
                    name="officer2"
                    render={({
                      field: { onChange, onBlur, value, name, ref },
                    }) => (
                      <Select
                        name="officer2"
                        value={value}
                        onChange={onChange}
                        options={rowsOfficer}
                        isSearchable={true}
                        className="react-select-container"
                        classNamePrefix="react-select"
                      />
                    )}
                  />
                </Form.Group>
              </Col>
              <Col xs={12} sm={12} md={8} lg={4} xl={4}>
                <Form.Group className="mb-3" controlId="officer3">
                  <Form.Label>Officer 4</Form.Label>
                  <Controller
                    control={control}
                    name="officer3"
                    render={({
                      field: { onChange, onBlur, value, name, ref },
                    }) => (
                      <Select
                        name="officer3"
                        value={value}
                        onChange={onChange}
                        options={rowsOfficer}
                        isSearchable={true}
                        className="react-select-container"
                        classNamePrefix="react-select"
                      />
                    )}
                  />
                </Form.Group>
              </Col>
         
            <Col xs={12} sm={12} md={8} lg={4} xl={4}>
                <Form.Group className="mb-3" controlId="agent">
                  <Form.Label>Agent</Form.Label>

                  <Controller
                    control={control}
                    name="agent"
                    rules={{
                      required: true,
                    }}
                    render={({
                      field: { onChange, onBlur, value, name, ref },
                    }) => (
                      <Select
                        name="agent"
                        value={value}
                        onChange={onChange}
                        options={rowsAgent}
                        isSearchable={true}
                        className="react-select-container"
                        classNamePrefix="react-select"
                      />
                    )}
                  />

                  {errors.agent && (
                    <Form.Text className="text-muted validationText hasError">
                      Agent is required
                    </Form.Text>
                  )}
                </Form.Group>
              </Col>
              <Col xs={12} sm={12} md={8} lg={4} xl={4}>
                <Form.Group className="mb-3" controlId="representative">
                  <Form.Label>Representative</Form.Label>

                  <Controller
                    control={control}
                    name="representative"
                    rules={{
                      required: true,
                    }}
                    render={({
                      field: { onChange, onBlur, value, name, ref },
                    }) => (
                      <Select
                        name="representative"
                        value={value}
                        onChange={onChange}
                        options={rowsRep}
                        isSearchable={true}
                        className="react-select-container"
                        classNamePrefix="react-select"
                      />
                    )}
                  />

                  {errors.agent && (
                    <Form.Text className="text-muted validationText hasError">
                      Representative is required
                    </Form.Text>
                  )}
                </Form.Group>
              </Col>
           
          
              <Col xs={12} sm={12} md={8} lg={4} xl={4}>
                  <Form.Group className="mb-3" controlId="serial">
                    <Form.Label>Serial Number</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter serial Number"
                      {...register("serial", {
                        required: {
                          value: "required",
                          message: "Serial number is required",
                        },
                        maxLength: {
                          value: 16,
                          message: "Maximum length is 255",
                        },
                      })}
                    />
                    {errors.serial && (
                      <Form.Text className="text-muted validationText hasError">
                        {errors.serial.message}
                      </Form.Text>
                    )}
                  </Form.Group>
              </Col>
              <Col xs={12} sm={12} md={8} lg={4} xl={4}>
                <Form.Group className="mb-3" controlId="dob">
                  <Form.Label>Date Of Birth</Form.Label>
                  <Form.Control
                    placeholder="Enter Date Of Birth"
                    max={format(new Date(), "yyyy-MM-dd")}
                    {...register("dob", {
                      required: {
                        value: "required",
                        message: "dob is required",
                      },
                    })}
                    type="date"
                  />
                  {errors.dob && (
                    <Form.Text className="text-muted validationText hasError">
                      {errors.dob.message}
                    </Form.Text>
                  )}
                </Form.Group>
              </Col>
              <Col xs={12} sm={12} md={8} lg={4} xl={4}>
                <Form.Group className="mb-3" controlId="installation_date">
                  <Form.Label>Install Date</Form.Label>
                  <Form.Control
                    placeholder="Enter Installation Date"
                    max={format(new Date(), "yyyy-MM-dd")}
                    {...register("installation_date", {
                      required: {
                        value: "required",
                        message: "installation date is required",
                      },
                    })}
                    type="date"
                  />
                  {errors.installation_date && (
                    <Form.Text className="text-muted validationText hasError">
                      {errors.installation_date.message}
                    </Form.Text>
                  )}
                </Form.Group>
              </Col>
              <Col xs={12} sm={12} md={8} lg={4} xl={4}>
                <Form.Group className="mb-3" controlId="payment_date">
                  <Form.Label>Payment Date</Form.Label>
                  <Form.Control
                    placeholder="Enter Payment Date"
                    max={format(new Date(), "yyyy-MM-dd")}
                    {...register("payment_date", {
                      required: {
                        value: "required",
                        message: "payment date is required",
                      },
                    })}
                    type="date"
                  />
                  {errors.payment_date && (
                    <Form.Text className="text-muted validationText hasError">
                      {errors.payment_date.message}
                    </Form.Text>
                  )}
                </Form.Group>
              </Col>
           
        
            <Col xs={12} sm={12} md={8} lg={4} xl={4}>
                <Form.Group className="mb-3" controlId="county">
                  <Form.Label>County</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter County"
                    {...register("county", {
                      required: {
                        value: "required",
                        message: "County is required",
                      },
                      maxLength: {
                        value: 16,
                        message: "Maximum length is 255",
                      },
                    })}
                  />
                  {errors.county && (
                    <Form.Text className="text-muted validationText hasError">
                      {errors.county.message}
                    </Form.Text>
                  )}
                </Form.Group>
            </Col>
            <Col xs={12} sm={12} md={8} lg={4} xl={4}>
                <Form.Group className="mb-3" controlId="program">
                  <Form.Label>Program</Form.Label>

                  <Controller
                    control={control}
                    name="program"
                    rules={{
                      required: true,
                    }}
                    render={({
                      field: { onChange, onBlur, value, name, ref },
                    }) => (
                      <Select
                        name="program"
                        value={value}
                        onChange={onChange}
                        options={rowsProgram}
                        isSearchable={true}
                        className="react-select-container"
                        classNamePrefix="react-select"
                      />
                    )}
                  />

                  {errors.agent && (
                    <Form.Text className="text-muted validationText hasError">
                      Program is required
                    </Form.Text>
                  )}
                </Form.Group>
              </Col>
           
         
              <Col xs={12} sm={12} md={8} lg={4} xl={4}>
                <div className={"pass-field mb-3"}>
                  <Form.Label>Password</Form.Label>
                  <InputGroup controlId="passowrd">
                    <FormControl
                      placeholder="Enter Password"
                      autoComplete={"off"}
                      {...register("password", {
                        required: {
                          value: "required",
                          message: "Password is required",
                        },
                        minLength: {
                          value: 8,
                          message: "Minimum length is 8",
                        },
                        maxLength: {
                          value: 255,
                          message: "Maximum length is 255",
                        },
                      })}
                      onClick={() => setConfirmPass(!confirmPass)}
                      type={pass ? "password" : "text"}
                    />
                    <Button
                      variant="outline-secondary"
                      id="button-addon2"
                      onClick={() => TogglePass(!pass)}
                    >
                      {pass ? <FaEye /> : <FaEyeSlash />}
                    </Button>
                  </InputGroup>
                  {errors.password && (
                    <Form.Text className="text-muted validationText hasError">
                      {errors.password.message}
                    </Form.Text>
                  )}
                </div>
              </Col>
              <Col xs={12} sm={12} md={8} lg={4} xl={4}>
                <div className={"pass-field mb-3"}>
                  <Form.Label>Confirm Password</Form.Label>
                  <InputGroup controlId="password_confirmation">
                    <Form.Control
                      placeholder="Enter Password Again"
                      {...register("password_confirmation", {
                        required: {
                          value: "required",
                          message: "Confirm Password is required",
                        },
                        minLength: {
                          value: 8,
                          message: "Minimum length is 8",
                        },
                        validate: password.current
                          ? (value) =>
                              value === password.current ||
                              "The passwords do not match"
                          : "",
                        maxLength: {
                          value: 255,
                          message: "Maximum length is 255",
                        },
                      })}
                      type={confPass ? "password" : "text"}
                    />
                    <Button
                      variant="outline-secondary"
                      id="button-addon2"
                      // disabled={confirmPass}
                      onClick={() => ToggleConfPass(!confPass)}
                    >
                      {confPass ? <FaEye /> : <FaEyeSlash />}
                    </Button>
                  </InputGroup>
                  {errors.password_confirmation && (
                    <Form.Text className="text-muted validationText hasError">
                      {errors.password_confirmation.message}
                    </Form.Text>
                  )}
                </div>
              </Col>
              <Col xs={12} sm={12} md={8} lg={4} xl={4}>
                <Form.Group className="mb-3" controlId="license">
                  <Form.Label>SPN Number</Form.Label>
                  <Form.Control
                    placeholder="Enter License"
                    id={"formSignUpName"}
                    {...register("license", {
                      required: {
                        value: "required",
                        message: "License is required",
                      },
                      minLength: {
                        value: 3,
                        message: "Minimum length is 3",
                      },
                      maxLength: {
                        value: 25,
                        message: "Maximum length is 25",
                      },
                    })}
                    type="text"
                  />
                  {errors.license && (
                    <Form.Text className="text-muted validationText hasError">
                      {errors.license.message}
                    </Form.Text>
                  )}
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col xs={12} sm={12} md={8} lg={4} xl={4}>
                <input
                  type={"submit"}
                  className={"btn btn-green-theme w-100 mt-3"}
                  disabled={disableButton}
                  value={"Create User"}
                />
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
    </div>
  );
}

export default AddNewUser;
