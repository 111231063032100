import React, {useEffect, useState} from 'react'
import ChatConversation from "../../components/Chat/ChatConversation";
import OrderDetails from "../../components/Chat/sidebar/OrderDetails";
import MediaBox from "../../components/Chat/sidebar/MediaBox";
import VendorDetailsBox from "../../components/Chat/sidebar/VendorDetailsBox";
import ChatList from "../../components/Chat/ChatList";
import VendorChat from "../../components/Chat/VendorChat";
import {
    getActiveThreadMedia,
    getThreadList,
    getThreadMessages,
    getVendorThreadMessages
} from "../../services/firebase.service";
import {GetOrdersById} from "../../services/csa/orders.service";
import {toast} from "react-toastify";
import {constants} from "../../utils/constants";
import {MoneyFormat} from "../../utils/helpers";
import moment from "moment";
import {EmptyLocalStorage, GetAuthUser} from "../../services/auth/auth.service";
import {BeatLoader} from "react-spinners";
import {useHistory} from 'react-router-dom';

function Chat() {
    const history = useHistory();
    const [loading, setLoading] = useState(false);
    const [chatLoader, setChatLoader] = useState(false);
    const [messageLoading, setMessageLoading] = useState(false);
    const [orderLoading, setOrderLoading] = useState(false);
    const [sideBar, setSideBar] = useState(false);
    const [vendorPop, setVendorPop] = useState(false);
    const [vendorPopId, setVendorPopId] = useState(false);
    const [threads, setThreads] = useState([]);
    const [messages, setMessages] = useState([]);
    const [vendorMessages, setVendorMessages] = useState([]);
    const [search, setSearch] = useState('');
    const [activeCustomer, setActiveCustomer] = useState('');
    const [activeThread, setActiveThread] = useState('');
    const [activeVendor, setActiveVendor] = useState('');
    const [activeStore, setActiveStore] = useState('');
    const [orderData, setOrderData] = useState([]);
    const [vendorData, setVendorData] = useState([]);
    const [mediaDetail, setMediaDetail] = useState([]);
    const [orderConfirm, setOrderConfirm] = useState(false);
    const [updateProductVariant, setUpdateProductVariant] = useState(false);
    const [reloadThread, setReloadThread] = useState(false);
    const current_user = GetAuthUser();

    useEffect(() => {
        if (!activeThread && !activeCustomer) {
            setLoading(true)
            setChatLoader(true)
        } else {
            setLoading(false)
            setChatLoader(false)
        }
    })
    //Chat Threads
    useEffect(() => {
        getThreads();
    }, [search])

    //Messages and Order Details
    useEffect(() => {
        getMessages();
        getThreadMedia();
    }, [activeThread, activeCustomer])

    useEffect(() => {
        if (activeThread) {
            getOrderDetail();
        }
    }, [activeThread, activeCustomer, orderConfirm, updateProductVariant])

    //Orders
    useEffect(() => {
        getVendorMessages();
    }, [activeVendor])

    //Get all chat threads by login user
    const getThreads = async () => {
        let is_vendor = current_user.roles[0].id == constants.ROLES.ROLE_VENDOR;
        await getThreadList(search, is_vendor,async (data) => {
            if (data) {
                await setActiveThread(data[0]?.chat_id)
                await setActiveCustomer(data[0]?.customer_id)
                setThreads(data);
            }
        });
    }

    //Get all chat threads by login user
    const getMessages = async () => {
        if (activeThread, activeCustomer) {
            if (current_user?.roles[0]?.id == constants.ROLES.ROLE_VENDOR) {
                await getVendorThreadMessages(activeThread, activeCustomer, true, (data) => {
                    setMessages(data ? data : []);
                });
            } else {
                await getThreadMessages(activeThread, activeCustomer, (data) => {
                    setMessages(data ? data : []);
                });
            }
        }
    }

    const getThreadMedia = async () => {
        if (activeThread, activeCustomer) {
            await getActiveThreadMedia(activeThread, activeCustomer, (data) => {
                setMediaDetail(data ? data : []);
            });
        }
    }

    //Get details of selected order
    const getOrderDetail = async () => {
        let order = activeThread.split('-')
        await GetOrdersById(order[1]).then(async (result) => {
            if (result.status) {
                if (result.data) {
                    let data = result.data;
                    let order_data = {
                        id: data.id ? data.id : '',
                        order_number: data.order_number ? data.order_number : '',
                        customer_name: data.user ? data.user?.full_name : '',
                        status: data.status ? data.status : '',
                        total_amount: data.total_amount ? MoneyFormat(data.total_amount) : '',
                        total_shipping_fees: data.total_shipping_fees ? MoneyFormat(data.total_shipping_fees) : '',
                        shipping_date: data.shipping_date ? moment.utc(data.shipping_date).format('Y-m-d') : '',
                        shipping_address: data.shipping_address ? data.shipping_address?.address_line_1 + '' + data.shipping_address?.city + ' ' + data.shipping_address?.country : '',
                        shipping_method: data.shipping_method ? data.shipping_method?.company : '',
                        description: data.description ? data.description : '',
                        sales_tax: data.sales_tax ? data.sales_tax : '',
                        agent_name: data?.agent?.username,
                    }

                    setOrderData(order_data);

                    //vendor box data
                    let store_ids = [];
                    let vendors = [];

                    data.order_products.map((product, index) => {
                        if (!store_ids.includes(product.store_id)) {
                            vendors[product.store_id] = [];
                        }
                    })

                    data.order_products.map((product, index) => {
                        vendors[product.store_id].push(product)
                    })

                    var filtered = vendors.filter(function (el) {
                        return el != null;
                    });

                    setVendorData(filtered);

                    /*let store_ids = [];
                    let stores = [];
                    let vendors = [];

                    data.order_products.map((product) => {
                        if (!store_ids.includes(product.store_id)) {
                            store_ids.push(product.store_id);
                            stores.push(product.store);
                        }
                    })

                    /!*todo: products push not working properly only last product is showing right now *!/
                    await stores.map((store_data) => {
                        data.order_products.map((product, index) => {
                            if (store_data.id == product.store_id) {
                                vendors[store_data.name] = product;
                            }
                        })
                    });

                    setVendorData(vendors);*/

                }
            } else {
                return toast.error(result.message);
            }
        }).catch((error) => {
            if (error.response.status == 401) {
                EmptyLocalStorage()
                history.push('/');
            } else {
                return toast.error(error.response.data.message);
            }
        });
    }

    //Get all chat threads by selected vendor
    const getVendorMessages = async () => {
        if (activeThread, activeVendor) {
            await getVendorThreadMessages(activeThread, activeVendor, false, (data) => {
                setVendorMessages(data);
            });
        }
    }

    if (chatLoader) {
        return (
            <div style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                height: '80vh'
            }}>
                <BeatLoader color={'#12726C'} loading={loading} css={'margin-top: 0px'} height={'4'}
                            width={'100%'}/>
                <br/><br/>
                {
                    (threads && threads.length > 0) ? '' : <div>No Chat Available</div>
                }
            </div>
        )
    }

    return (
        <div className={"ChatView"}>
            <div id="loadgrid" className={sideBar ? "sidepanel-collapsed" : ""}>
                <div className="tabs-left">
                    <ChatList orderChat={true}
                              threads={threads}
                              active_thread={activeThread}
                              onActiveClick={setActiveThread}
                              onActiveCustomer={setActiveCustomer}
                              onSearchChange={setSearch}
                              active_customer={activeCustomer}
                              setActiveVendor={setActiveVendor}
                              />
                    {
                        activeThread ?
                            <ChatConversation orderChat={true}
                                              messageLoading={messageLoading}
                                              setSideBar={setSideBar}
                                              active_thread={activeThread}
                                              messages={messages}
                                              active_customer={activeCustomer}
                                              current_user={current_user}/>
                            : ''
                    }
                    <div className={"OrderPanel"}>
                        {
                            (activeThread && orderData) ?
                                <div>
                                    <OrderDetails order_data={orderData} orderConfirm={orderConfirm}
                                                  setOrderConfirm={setOrderConfirm}/>
                                    <VendorDetailsBox setVendorPop={setVendorPop}
                                                      setActiveVendor={setActiveVendor}
                                                      vendor_data={vendorData}
                                                      setActiveStore={setActiveStore}
                                                      order_status={orderData.status}
                                                      updateProductVariant={updateProductVariant}
                                                      setUpdateProductVariant={setUpdateProductVariant}
                                    />
                                </div> : ''
                        }

                        {
                            (activeThread && orderData && messages) ?
                                <MediaBox mediaDetail={messages}/>
                                : ''
                        }
                    </div>
                </div>
            </div>
            {/*************************
             * vendor chat div starts
             **************************/}
            <VendorChat vendorPop={vendorPop} setVendorPop={setVendorPop} active_vendor={activeVendor}
                        vendor_messages={vendorMessages} activeThread={activeThread} activeStore={activeStore}/>
            {
                vendorPop ? <div className={"overlay"} onClick={() => setVendorPop(!vendorPop)}></div> : " "
            }
        </div>
    )
}

export default Chat
