import React, {useState} from 'react'
import {
    AiOutlineFileExcel,
    AiOutlineFileImage,
    AiOutlineFilePdf,
    AiOutlineFileWord,
    AiOutlineAudio,
    AiOutlineVideoCamera
} from "react-icons/ai";
import {Link} from "react-router-dom";

const fileExt = {
    jpeg: <AiOutlineFileImage/>,
    JPEG: <AiOutlineFileImage/>,
    png: <AiOutlineFileImage />,
    jpg: <AiOutlineFileImage />,
    xls: <AiOutlineFileExcel />,
    xlsx: <AiOutlineFileExcel />,
    doc: <AiOutlineFileWord />,
    docx: <AiOutlineFileWord />,
    pdf: <AiOutlineFilePdf />,
    mp3: <AiOutlineAudio />,
    mp4: <AiOutlineVideoCamera />
}

const MediaBox = ({mediaDetail}) => {
    return (
        <div className={"PanelBox MediaBox"}>
            <h3>Media Details</h3>
            <hr/>
            <ul className={"list-unstyled"} style={{overflow: "hidden"}}>
                {
                    mediaDetail && mediaDetail.length > 0 ?
                        mediaDetail.map((data) => {
                            if (data.mediaType != 'text') {
                                let url = data.media;
                                let filename = data.media.substring(data.media.lastIndexOf("/") + 1, data.media.length);
                                let ext = filename.substring(filename.lastIndexOf(".") + 1, filename.length);
                                return (
                                    <li className={"d-flex "} style={{flexDirection: "row", flexWrap: "nowrap", alignContent: "center", alignItems: "center"}}>{fileExt[ext]} <a target="_blank" href={url} download={filename} style={{width: "85%", height: "20px",marginLeft: "5px", overflow: "hidden", textOverflow: "ellipsis"}}>{filename}</a></li>
                                )
                            }
                        }) : ''
                }
            </ul>

        </div>
    )
}

export default MediaBox
