import {apiUrl} from '../utils/constants';
import {GET, POST, PUT, DELETE} from './api.service.wrapper';

export const GetSubCategories = async (category_id = '', page= '', perPage= '', keyword= '') => {
    let params = {};

    if(page){
        params['page'] = page;
    }

    if(perPage){
        params['per-page'] = perPage;
    }

    if(category_id){
        params['category_id'] = category_id;
    }

    if(keyword){
        params['keyword'] = keyword;
    }

    const response = await GET(apiUrl.sub_categories, params);
    return response;
}

export const GetSubCategoryById = async (id) => {
    let response = '';
    response = await GET(apiUrl.sub_categories + '/' + id);
    return response;
}

export const CreateSubCategory = async (data) => {
    const response = await POST(apiUrl.sub_categories, data);
    return response;
}

export const UpdateSubCategory = async (id, data) => {
    const response = await PUT(apiUrl.sub_categories, id, data);
    return response;
}

export const DeleteSubCategory = async (id) => {
    const response = await DELETE(apiUrl.sub_categories, id);
    return response;
}

