import {apiUrl} from '../utils/constants';
import {GET, POST} from './api.service.wrapper';

export const FetchCountries = async () => {
    let response = await GET(apiUrl.fetch_countries);
    return response;
}

export const FetchStateById = async (id) => {
    let response = await GET(apiUrl.fetch_states + '/' + id);
    return response;
}

export const FetchCityById = async (id) => {
    let response = await GET(apiUrl.fetch_cities + '/' + id);
    return response;
}

export const ValidateAddress = async (data) => {
    let response = await POST(apiUrl.validate_address, data);
    return response;
}
