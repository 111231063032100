import {apiUrl} from '../utils/constants';
import {PUT, DELETE} from './api.service.wrapper';

export const UpdateProductVariant = async (id, data) => {
    const response = await PUT(apiUrl.product_variants, id, data);
    return response;
}

export const DeleteProductVariant = async (id) => {
    const response = await DELETE(apiUrl.product_variants, id);
    return response;
}

export const DeleteOrderProductVariant = async (id) => {
    const response = await DELETE(apiUrl.order_product_variants, id);
    return response;
}

export const UpdateOrderProductVariant = async (id, data) => {
    const response = await PUT(apiUrl.order_product_variants, id, data);
    return response;
}
