import React, {useState, useEffect} from 'react'
import MaterialTable from "material-table";
import swal from "sweetalert";
import {Button, Col, Dropdown, DropdownButton, Form, FormControl, InputGroup, Row} from "react-bootstrap";
import {AddIconOutline, FilterIcon, MenuDots, SearchIcon} from "../../components/SvgIcons";
import {Link} from 'react-router-dom';
import {CreatePage, DeletePage, GetPages, UpdatePage} from "../../services/pages.service";
import {toast} from "react-toastify";
import {constants, customStyles} from "../../utils/constants";
import moment from "moment"
import {useHistory} from 'react-router-dom';
import DataTable from 'react-data-table-component';
import BarLoader from "react-spinners/BarLoader";
import {EmptyLocalStorage} from "../../services/auth/auth.service";

function PageList() {
    let history = useHistory();
    const [loading, setLoading] = useState(false);
    const [totalRows, setTotalRows] = useState(0);
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(constants.PERPAGE);
    const [rows, setRows] = useState();

    useEffect(async () => {
        await getPages();
    }, [page, perPage])

    const getPages = async () => {
        setLoading(true)
        await GetPages(page, perPage).then((result) => {
            if (result.success) {
                if (result.data) {
                    const rowData = [];
                    setPage(result.current_page);
                    setTotalRows(result.total);
                    result.data.map((dt, index) => {
                        console.log(dt)
                        rowData.push({
                            id: dt.id,
                            index: ++index,
                            slug: dt.slug,
                            title: dt.title,
                            created_at: moment(dt.created_at).fromNow(),
                        });
                    })
                    setRows(rowData);
                    setLoading(false)
                }
            } else {
                setLoading(false)
                return toast.error(result.message);
            }
        }).catch((error) => {
            setLoading(false)
            if (error.response.status == 401) {
                EmptyLocalStorage()
                history.push('/');
            } else {
                return toast.error(error.response.data.message);
            }
        })
    }

    const onPageDelete = (id) => {
        swal({
            title: "Are you sure?",
            text: "Are you sure you want to delete this page?",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then(async (willShip) => {
            //Delete Product API
            if (willShip) {
                await DeletePage(id).then((result) => {
                    if (result.success) {
                        swal(result.message, {
                            icon: "success",
                        });
                        getPages()
                    } else {
                        toast.error(result.message);
                    }
                }).catch((error) => {
                    if (error.response.status == 401) {
                        EmptyLocalStorage()
                        history.push('/');
                    } else {
                        return toast.error(error.response.data.message);
                    }
                })
            }
        });
    }

    const onEdit = (data) => {
        history.push('/page-edit/' + data.slug, {
            data: data
        });
    }

    const onViewPage = (data) => {
        history.push('/page/' + data.slug);
    }

    const pageActions = (data) => {
        return (
            <DropdownButton align="end" className={"actionDropdown"} id="dropdown-item-button" title={<MenuDots/>}>
                <Dropdown.Item as="button" onClick={() => onViewPage(data)}>View</Dropdown.Item>
                <Dropdown.Item as="button" onClick={() => onEdit(data)}>Edit</Dropdown.Item>
                <Dropdown.Item as="button" onClick={() => onPageDelete(data.slug)}>Delete</Dropdown.Item>
            </DropdownButton>
        )
    }

    const columns = [
        {
            id: 'title',
            name: 'Title',
            selector: rowData => rowData.title,
        },
        {
            id: 'slug',
            name: 'Slug',
            selector: rowData => rowData.slug,
        },
        {
            id: 'created_at',
            name: 'Created At',
            selector: rowData => rowData.created_at,
        },
        {
            id: 'action',
            name: 'Action',
            cell: rowData => pageActions(rowData)
        },
    ];

    const handlePageChange = async (page) => {
        await setPage(page);
    };

    return (
        <div className={"PageList"}>
            <h3 className="page-heading">Pages</h3>
            <hr/>
            <div className={"customOrderFilters"}>
                <Row>
                    <Col xs={12} sm={12} md={{span: 3, offset: 9}} lg={{span: 3, offset: 9}} xl={{span: 3, offset: 9}}>
                        <Link to={"/page-add"}>
                            <Button className={"btn btn-green-theme h40 w-100"}>
                                <AddIconOutline/> Add New
                            </Button>
                        </Link>
                    </Col>
                </Row>
            </div>
            <div className={"mt-3"}>
                <div className="theme-dataGrid products-dataGrid custom-table-border mt-4">
                    <DataTable
                        columns={columns}
                        data={rows}
                        progressPending={loading}
                        customStyles={customStyles}
                        selectableRows={false}
                        striped
                        highlightOnHover
                        pagination
                        paginationServer
                        paginationTotalRows={totalRows}
                        paginationPerPage={perPage}
                        onChangePage={(page) => {
                            setPage(page);
                        }}
                        onChangeRowsPerPage={(currentRowsPerPage) => {
                            setPerPage(currentRowsPerPage)
                        }}
                        progressComponent={<BarLoader color={'#961b1a'} loading={loading} css={'marginTop: 10px'}
                                                      height={'4'} width={'100%'}/>}
                    />
                </div>
            </div>
        </div>
    )
}

export default PageList
