import React, { useRef, useState, useEffect } from "react";
import {
  Form,
  Row,
  Col,
  InputGroup,
  FormControl,
  Button,
} from "react-bootstrap";
import BreadcrumbsComponent from "../../components/BreadcrumbsComponent";
import { Controller, useForm } from "react-hook-form";
import {
  GetUserById,
  UpdateUser,
  GetUsers,
} from "../../services/users.service";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import Calendar from "react-multi-date-picker";
import moment from "moment";
import { updateUser } from "../../services/firebase.service";
import {
  EmptyLocalStorage,
  GetUserRole,
} from "../../services/auth/auth.service";
import { constants } from "../../utils/constants";
import { format } from "date-fns";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { useDropzone } from "react-dropzone";
import { UploadIcon } from "../../components/SvgIcons";
import { UploadImageToS3 } from "../../utils/helpers";
import Select from "react-select";

function EditUser(props) {
  let history = useHistory();
  const [disableButton, setDisableButton] = useState(false);
  const [data, setData] = useState([]);
  const [pass, TogglePass] = useState(true);
  const [confPass, ToggleConfPass] = useState(true);
  const [confirmPass, setConfirmPass] = useState(true);
  const [mediaRequiredError, setMediaRequiredError] = useState(false);
  const [files, setFiles] = useState([]);
  const [selectedFile, setSelectedFile] = useState(false);
  const [txt, setTxt] = useState(false);
  const [file, setFileData] = useState(false);
  const [defaultImage, setDefaultImage] = useState(false);
  const [selectedOptionAgent, setSelectedOption] = useState("");
  const [selectedOptionOfficer, setSelectedOptionOfficer] = useState("");
  const [selectedOptionOfficer1, setSelectedOptionOfficer1] = useState("");
  const [selectedOptionOfficer2, setSelectedOptionOfficer2] = useState("");
  const [selectedOptionOfficer3, setSelectedOptionOfficer3] = useState("");
  const [rowsAgent, setRowsAgent] = useState();
  const [rowsOfficer, setRowsOfficer] = useState();
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(constants.PERPAGE);
  const [keyword, setKeyword] = useState("");
  const [rowsRep, setRowsRep] = useState();
  const [rowsProgram, setRowsProgram] = useState([
    {label: 'Pre Trial', value: '10'}, 
    {label: 'Probation', value: '20'}, 
    {label: 'Sober Count', value: '30'}, 
    {label: 'Verterans Court', value: '40'}
  ]);
  const [selectedOptionRepresentative, setSelectedOptionRepresentative] = useState();
  const [selectedOptionProgram, setSelectedOptionProgram] = useState();


  const {
    register,
    watch,
    reset,
    control,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
  });

  const onInputChange = (e) => {
    const { value } = e.target;
    console.log("Input value: ", value);

    const re = /^[A-Za-z ]+$/;
    if (value === "" || re.test(value)) {
      setTxt(value);
    }
  };

  const password = useRef({});
  password.current = watch("password", "");

  useEffect(async () => {
    let id = props.match.params.id;
    await getAgents();
    await getOfficers();
    await getRepresentative();
    await getUserByid(id);
  }, []);

  useEffect(() => {
    if (data) {
      reset({
        full_name: data.full_name,
        username: data.username,
        email: data.email,
        state: data.state,
        phone_number: data.phone_number,
        dob: data.dob,
        payment_date: data.payment_date,
        installation_date: data.installation_date,
        license: data.license,
        serial: data.device_serial,
        county: data.county
      });
      let agent = {
        label: data.agent,
        value: data.agent_id,
      };
      let representative = {
        label: data.representative,
        value: data.rep_id,
      };
      let officer = {
        label: data.officer,
        value: data.officer_id,
      };
      let officer1 = {
        label: data.officer_1,
        value: data.officer_1_id,
      };
      let officer2 = {
        label: data.officer_2,
        value: data.officer_2_id,
      };
      let officer3 = {
        label: data.officer_3,
        value: data.officer_3_id,
      };
      let program = {
        label: data.program,
        value: data.program_id,
      };
      setSelectedOption(agent);
      setSelectedOptionOfficer(officer);
      setSelectedOptionOfficer1(officer1);
      setSelectedOptionOfficer2(officer2);
      setSelectedOptionOfficer3(officer3);
      setSelectedOptionRepresentative(representative);
      setSelectedOptionProgram(program);
      setValue("agent", {
        label: data.agent,
        value: data.agent_id,
      });
      setValue("officer", officer);
      setValue("officer1", officer1);
      setValue("officer2", officer2);
      setValue("officer3", officer3);
      setValue("representative", {
        label: data.representative,
        value: data.rep_id,
      });
      setValue("program", {
        label: data.program,
        value: data.program_id,
      });
    }
  }, [data]);

  const getAgents = async () => {
    setLoading(true);
    await GetUsers(keyword, constants.ROLES.ROLE_AGENT, page, perPage)
      .then((result) => {
        if (result.success) {
          if (result.data) {
            const rowData = [];
            result.data.map((dt, index) => {
              rowData.push({
                value: dt.id,
                label: dt.full_name || "-",
              });
            });
            setRowsAgent(rowData);
            setLoading(false);
          }
        } else {
          setLoading(false);
          return toast.error(result.message);
        }
      })
      .catch((error) => {
        setLoading(false);
        if (error.response.status == 401) {
          EmptyLocalStorage();
          history.push("/");
        } else {
          return toast.error(error.response.data.message);
        }
      });
  };

  const getOfficers = async () => {
    setLoading(true);
    await GetUsers(keyword, constants.ROLES.ROLE_OFFICER, page, perPage)
      .then((result) => {
        if (result.success) {
          if (result.data) {
            const rowData = [];
            result.data.map((dt, index) => {
              rowData.push({
                value: dt.id,
                label: dt.full_name || "-",
              });
            });
            setRowsOfficer(rowData);
            setLoading(false);
          }
        } else {
          setLoading(false);
          return toast.error(result.message);
        }
      })
      .catch((error) => {
        setLoading(false);
        if (error.response.status == 401) {
          EmptyLocalStorage();
          history.push("/");
        } else {
          return toast.error(error.response.data.message);
        }
      });
  };

  const getRepresentative = async () => {
    setLoading(true);
    await GetUsers(keyword, constants.ROLES.ROLE_REPRESENTATIVE, page, perPage)
      .then((result) => {
        if (result.success) {
          if (result.data) {
            const rowData = [];
            result.data.map((dt, index) => {
              rowData.push({
                value: dt.id,
                label: dt.full_name || "-",
              });
            });
            setRowsRep(rowData);
            setLoading(false);
          }
        } else {
          setLoading(false);
          return toast.error(result.message);
        }
      })
      .catch((error) => {
        setLoading(false);
        if (error.response.status == 401) {
          EmptyLocalStorage();
          history.push("/");
        } else {
          return toast.error(error.response.data.message);
        }
      });
  };

  const getUserByid = async (id) => {
    await GetUserById(id)
      .then((result) => {
        if (result.success) {
          if (result.data) {
            let test_schedules = result.data.test_schedule;
            let str_test_schedule = "";
            test_schedules.forEach((item) => {
              const current = new Date();
              let start_time = moment(
                `${current.getFullYear()}-${
                  current.getMonth() + 1
                }-${current.getDate()}` +
                  "T" +
                  item.start_time +
                  "Z",
                "YYYY-MM-DDTHH:mm:ssZ"
              )
                .local()
                .format("hh:mm A");
              let end_time = moment(
                `${current.getFullYear()}-${
                  current.getMonth() + 1
                }-${current.getDate()}` +
                  "T" +
                  item.end_time +
                  "Z",
                "YYYY-MM-DDTHH:mm:ssZ"
              )
                .local()
                .format("hh:mm A");
              str_test_schedule =
                str_test_schedule +
                start_time +
                "-" +
                end_time +
                (test_schedules.at(-1) != item ? ", " : "");
            });

            setDefaultImage(
              result.data.profile_image || constants.USERIMAGENOTFOUND
            );
            setData({
              full_name: result.data.full_name,
              state: result.data.state,
              email: result.data.email,
              profile_image: result.data.profile_image,
              phone_number: result.data.phone_number,
              license: result.data.license,
              officer_id: result.data.officer_id,
              officer_1_id: result.data.officer_1_id,
              officer_2_id: result.data.officer_2_id,
              officer_3_id: result.data.officer_3_id,
              agent_id: result.data.agent_id,
              rep_id: result.data.rep_id,
              officer: result.data.officer_name,
              officer_1: result.data.officer_1_name,
              officer_2: result.data.officer_2_name,
              officer_3: result.data.officer_3_name,
              payment_date: result.data.payment_date,
              installation_date: result.data.installation_date,
              agent: result.data.agent,
              representative: result.data.representative,
              device_serial: result.data.device_serial,
              county: result.data.county,
              program: result.data.program,
              dob: result.data.dob,
              test_schedule: str_test_schedule === "" ? "-" : str_test_schedule,
              random_testing: result.data.random_testing
                ? "Enabled"
                : "Disabled",
              driving_restriction: result.data.driving_restriction
                ? "Enabled"
                : "Disabled",
              email_reporting: result.data.email_reporting,
            });
          }
        } else {
          return toast.error(result.message);
        }
      })
      .catch((error) => {
        if (error.response.status == 401) {
          EmptyLocalStorage();
          history.push("/");
        } else {
          return toast.error(error.response.data.message);
        }
      });
  };

  const onSubmit = async (data) => {
    let id = props.match.params.id;

    setDisableButton(true);
    let url = "";
    if (data.media.length > 0) {
      console.log("data: ", data.media[0].type)
      if (data.media[0].type == "image/jpg" || data.media[0].type == "image/png" || data.media[0].type == "image/jpeg" ){
        const myFile = new File(
          [ data.media[0]],
        `${data.full_name}`,
        {type:data.media[0].type}
         )
        let uploadImage = await UploadImageToS3(myFile);
        if (uploadImage) {
          if (!uploadImage.success) {
            toast.error(uploadImage.message);
            return false;
          }
          // content_type = constants.CONTENTTYPE[file.type];
          url = uploadImage.data;
          // filename = file.name;
        }
      }else {
        toast.error("Invalid file type.");
        setDisableButton(false);
      }
    } else {
      url = defaultImage;
    }

    let dt = {
      full_name: data.full_name,
      state: data.state,
      email: data.email,
      phone_number: data.phone_number,
      license: data.license,
      dob: data.dob,
      serial: data.serial,
      profile_image: data.media.length > 0 ? url : defaultImage,
      agent: data.agent.value,
      officer: data.officer.value,
      officer_1: data.officer1.value,
      officer_2: data.officer2.value,
      officer_3: data.officer3.value,
      payment_date: data.payment_date,
      installation_date: data.installation_date,
      representative: data.representative.value,
      program: data.program.value,
      county: data.county,
    };

  if (data.password) {
    dt.password = data.password;
  }
  
  console.log(dt)
    await UpdateUser(id, dt)
      .then(async (data) => {
        if (data.success) {
          // await updateUser(data.data).then(() => {
          toast.success(data.message);
          history.push("/manage-user");
          // });
        } else {
          setDisableButton(false);
          toast.error(data.message);
        }
      })
      .catch((error) => {
        setDisableButton(false);
        if (error.response.status == 401) {
          EmptyLocalStorage();
          history.push("/");
        } else {
          return toast.error(error.response.data.message);
        }
      });
    
  };
  const handleChange = (event) => {
    setFileData(URL.createObjectURL(event.target.files[0]));
  };

  function CustomDropZone(props) {
    const {
      acceptedFiles,
      fileRejections,
      getRootProps,
      getInputProps,
      onChange,
    } = useDropzone({
      accept: "image/jpeg, image/jpg, image/png",
      maxFiles: 1,
      onDrop: (files) => {
        setValue("media", files);
        console.log("files droped: ", files);
        setMediaRequiredError(false);

        setSelectedFile(files[0]);
        setFiles(
          files.map((file) =>
            Object.assign(file, {
              preview: URL.createObjectURL(file),
            })
          )
        );
      },
    });

    const acceptedFileItems = acceptedFiles.map((file, index) => (
      <li key={file.path} style={{ listStyle: "none", fontSize: "10px" }}>
        {index > 1 ? index + "Files Selected" : index + "File Selected"}
        {/*{file.path} - {file.size} bytes*/}
      </li>
    ));

    console.log("accepted files: ", acceptedFiles);

    const thumbs = files.map((file) => (
      <div className={"thumb"} key={file.name}>
        <div className={"thumbInner"}>
          <img src={file.preview} className={"img"} />
        </div>
      </div>
    ));

    const fileRejectionItems = fileRejections.map(({ file, errors }) => (
      <li key={file.path}>
        {file.path} - {file.size} bytes
        <ul>
          {errors.map((e) => (
            <li key={e.code}>{e.message}</li>
          ))}
        </ul>
      </li>
    ));

    return (
      <div className="dropZoneContainer">
        <div {...getRootProps({ className: "dropzone" })}>
          <input type="file" name="profile_image" {...getInputProps()} />
          <UploadIcon />
          <p>Drag images or click to upload</p>
        </div>
        <aside className={"thumbsContainer"}>
          {/* <img src={data.profile_image}></img> */}
          {thumbs}
        </aside>
        {/*{
                acceptedFileItems.length > 0 ?
                    <div style={{marginLeft: '10px'}}>
                        <span style={{fontSize: '10px'}}>Selected files</span>
                        <p>{acceptedFileItems.length}</p>
                    </div> : ''
            }*/}

        {/*<aside>
            <h4>Accepted files</h4>
            <ul>{acceptedFileItems}</ul>
            <h4>Rejected files</h4>
            <ul>{fileRejectionItems}</ul>
        </aside>*/}
      </div>
    );
  }

  return (
    <div className="AddNewUser">
      {/*<BreadcrumbsComponent />*/}
      <h3 className="page-heading">Edit User - {data.full_name}</h3>
      <hr />
      <Row>
        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
          <Form className="formAddUser" onSubmit={handleSubmit(onSubmit)}>
            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
              <Row className={"mb-3 justify-content-center align-items-center"}>
                <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                  <Form.Group
                    controlId="formFile"
                    onChange={(e) => handleChange(e)}
                    className="custom-file-upload mb-0"
                  >
                    <Form.Label className={"toggleFileUpload mb-0"}>
                      <UploadIcon />
                      Upload Image
                    </Form.Label>
                    <Form.Control
                      type="file"
                      accept="image/png, image/jpeg, image/jpg, image/gif"
                      {...register("media")}
                    />
                  </Form.Group>
                </Col>
                <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                  <ul className={"list-inline list-variations mb-0"}>
                    <li
                      className={
                        "list-inline-item d-flex justify-content-between mb-0"
                      }
                    >
                      <img
                        onError={({ currentTarget }) => {
                          currentTarget.onerror = null; // prevents looping
                          currentTarget.src = constants.IMAGENOTFOUND;
                        }}
                        src={file ? file : defaultImage}
                        width="50%"
                        height="50%"
                      />
                    </li>
                  </ul>
                </Col>
              </Row>
            </Col>
            <Row>
              <Col xs={12} sm={12} md={8} lg={4} xl={4}>
                <Form.Group className="mb-3" controlId="full_name">
                  <Form.Label>Full Name</Form.Label>
                  <Form.Control
                    placeholder="Enter Full Name"
                    id={"formSignUpName"}
                    {...register("full_name", {
                      required: {
                        value: "required",
                        message: "Full Name is required",
                      },
                      pattern: {
                        value: /^[A-Za-z ]+$/,
                        message: "Invalid full name",
                      },
                      minLength: {
                        value: 3,
                        message: "Minimum length is 3",
                      },
                      maxLength: {
                        value: 25,
                        message: "Maximum length is 25",
                      },
                    })}
                    type="text"
                  />
                  {errors.full_name && (
                    <Form.Text className="text-muted validationText hasError">
                      {errors.full_name.message}
                    </Form.Text>
                  )}
                </Form.Group>
              </Col>
              <Col xs={12} sm={12} md={8} lg={4} xl={4}>
                <Form.Group className="mb-3" controlId="email">
                  <Form.Label>Email Address</Form.Label>
                  <Form.Control
                    placeholder="Enter Email Address"
                    autocomplete="off"
                    autofill="off"
                    {...register("email", {
                      required: {
                        value: "required",
                        message: "Email is required",
                      },
                      pattern: {
                        value: /\S+@\S+\.\S+/,
                        message: "Invalid email",
                      },
                      maxLength: {
                        value: 255,
                        message: "Maximum length is 255",
                      },
                    })}
                    type="text"
                  />
                  {errors.email && (
                    <Form.Text className="text-muted validationText hasError">
                      {errors.email.message}
                    </Form.Text>
                  )}
                </Form.Group>
              </Col>

              <Col xs={12} sm={12} md={8} lg={4} xl={4}>
                <Form.Group className="mb-3" controlId="phone_number">
                  <Form.Label>Phone Number</Form.Label>
                  <Form.Control
                    type="tel"
                    placeholder="Enter Phone Number"
                    {...register("phone_number", {
                      required: {
                        value: "required",
                        message: "Phone is required",
                      },
                      pattern: {
                        value: /^\+[0-9]*$/,
                        message: "incorrect pattern +xxxxxxxxxxxx",
                      },
                      minLength: {
                        value: 8,
                        message: "Minimum length is 8",
                      },
                      maxLength: {
                        value: 16,
                        message: "Maximum length is 16",
                      },
                    })}
                  />
                  {errors.phone_number && (
                    <Form.Text className="text-muted validationText hasError">
                      {errors.phone_number.message}
                    </Form.Text>
                  )}
                </Form.Group>
              </Col>
              <Col xs={12} sm={12} md={8} lg={4} xl={4}>
                <Form.Group className="mb-3" controlId="state">
                  <Form.Label>State</Form.Label>
                  <Form.Control
                    placeholder="Enter State"
                    {...register("state", {
                      required: {
                        value: "required",
                        message: "State is required",
                      },
                      pattern: {
                        value: /^[A-Za-z ]+$/,
                        message: "invalid state name entered",
                      },
                      minLength: {
                        value: 3,
                        message: "Minimum length is 3",
                      },
                      maxLength: {
                        value: 25,
                        message: "Maximum length is 25",
                      },
                    })}
                    type="text"
                  />
                  {errors.state && (
                    <Form.Text className="text-muted validationText hasError">
                      {errors.state.message}
                    </Form.Text>
                  )}
                </Form.Group>
              </Col>

              <Col xs={12} sm={12} md={8} lg={4} xl={4}>
                <Form.Group className="mb-3" controlId="license">
                  <Form.Label>SPN Number</Form.Label>
                  <Form.Control
                    placeholder="Enter License"
                    id={"formSignUpName"}
                    {...register("license", {
                      required: {
                        value: "required",
                        message: "License is required",
                      },
                      minLength: {
                        value: 3,
                        message: "Minimum length is 3",
                      },
                      maxLength: {
                        value: 25,
                        message: "Maximum length is 25",
                      },
                    })}
                    type="text"
                  />
                  {errors.license && (
                    <Form.Text className="text-muted validationText hasError">
                      {errors.license.message}
                    </Form.Text>
                  )}
                </Form.Group>
              </Col>
              <Col xs={12} sm={12} md={8} lg={4} xl={4}>
                <Form.Group className="mb-3" controlId="agent">
                  <Form.Label>Agent</Form.Label>
                  <Controller
                    control={control}
                    name="agent"
                    rules={{
                      required: true,
                    }}
                    render={({
                      field: { onChange, onBlur, value, name, ref },
                    }) => (
                      <Select
                        name="agent"
                        value={value}
                        onChange={onChange}
                        options={rowsAgent}
                        isSearchable={true}
                        className="react-select-container"
                        classNamePrefix="react-select"
                      />
                    )}
                  />
                  {errors.agent && (
                    <Form.Text className="text-muted validationText hasError">
                      Agent is required Required
                    </Form.Text>
                  )}
                </Form.Group>
              </Col>

              <Col xs={12} sm={12} md={8} lg={4} xl={4}>
                <Form.Group className="mb-3" controlId="officer">
                  <Form.Label>Officer 1</Form.Label>
                  <Controller
                    control={control}
                    name="officer"
                    rules={{
                      required: true,
                    }}
                    render={({
                      field: { onChange, onBlur, value, name, ref },
                    }) => (
                      <Select
                        name="officer"
                        value={value}
                        onChange={onChange}
                        options={rowsOfficer}
                        isSearchable={true}
                        className="react-select-container"
                        classNamePrefix="react-select"
                      />
                    )}
                  />
                  {errors.officer && (
                    <Form.Text className="text-muted validationText hasError">
                      Officer is required
                    </Form.Text>
                  )}
                </Form.Group>
              </Col>
              <Col xs={12} sm={12} md={8} lg={4} xl={4}>
                <Form.Group className="mb-3" controlId="officer1">
                  <Form.Label>Officer 2</Form.Label>
                  <Controller
                    control={control}
                    name="officer1"
                    render={({
                      field: { onChange, onBlur, value, name, ref },
                    }) => (
                      <Select
                        name="officer1"
                        value={value}
                        onChange={onChange}
                        options={rowsOfficer}
                        isSearchable={true}
                        className="react-select-container"
                        classNamePrefix="react-select"
                      />
                    )}
                  />
                </Form.Group>
              </Col>
              <Col xs={12} sm={12} md={8} lg={4} xl={4}>
                <Form.Group className="mb-3" controlId="officer2">
                  <Form.Label>Officer 3</Form.Label>
                  <Controller
                    control={control}
                    name="officer2"
                    render={({
                      field: { onChange, onBlur, value, name, ref },
                    }) => (
                      <Select
                        name="officer2"
                        value={value}
                        onChange={onChange}
                        options={rowsOfficer}
                        isSearchable={true}
                        className="react-select-container"
                        classNamePrefix="react-select"
                      />
                    )}
                  />
                </Form.Group>
              </Col>
              <Col xs={12} sm={12} md={8} lg={4} xl={4}>
                <Form.Group className="mb-3" controlId="officer3">
                  <Form.Label>Officer 4</Form.Label>
                  <Controller
                    control={control}
                    name="officer3"
                    render={({
                      field: { onChange, onBlur, value, name, ref },
                    }) => (
                      <Select
                        name="officer3"
                        value={value}
                        onChange={onChange}
                        options={rowsOfficer}
                        isSearchable={true}
                        className="react-select-container"
                        classNamePrefix="react-select"
                      />
                    )}
                  />
                </Form.Group>
              </Col>

            <Col xs={12} sm={12} md={8} lg={4} xl={4}>
                <Form.Group className="mb-3" controlId="representative">
                  <Form.Label>Representative</Form.Label>

                  <Controller
                    control={control}
                    name="representative"
                    rules={{
                      required: true,
                    }}
                    render={({
                      field: { onChange, onBlur, value, name, ref },
                    }) => (
                      <Select
                        name="representative"
                        value={value}
                        onChange={onChange}
                        options={rowsRep}
                        isSearchable={true}
                        className="react-select-container"
                        classNamePrefix="react-select"
                      />
                    )}
                  />

                  {errors.representative && (
                    <Form.Text className="text-muted validationText hasError">
                      Representative is required
                    </Form.Text>
                  )}
                </Form.Group>
              </Col>
              <Col xs={12} sm={12} md={8} lg={4} xl={4}>
                <Form.Group className="mb-3" controlId="serial">
                  <Form.Label>Serial Number</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter serial Number"
                    {...register("serial", {
                      required: {
                        value: "required",
                        message: "Serial number is required",
                      },
                      maxLength: {
                        value: 16,
                        message: "Maximum length is 255",
                      },
                    })}
                  />
                  {errors.serial && (
                    <Form.Text className="text-muted validationText hasError">
                      {errors.serial.message}
                    </Form.Text>
                  )}
                </Form.Group>
              </Col>

            <Col xs={12} sm={12} md={8} lg={4} xl={4}>
                <Form.Group className="mb-3" controlId="county">
                  <Form.Label>County</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter County"
                    {...register("county", {
                      required: {
                        value: "required",
                        message: "County is required",
                      },
                      maxLength: {
                        value: 16,
                        message: "Maximum length is 255",
                      },
                    })}
                  />
                  {errors.county && (
                    <Form.Text className="text-muted validationText hasError">
                      {errors.county.message}
                    </Form.Text>
                  )}
                </Form.Group>
            </Col>
            <Col xs={12} sm={12} md={8} lg={4} xl={4}>
                <Form.Group className="mb-3" controlId="program">
                  <Form.Label>Program</Form.Label>

                  <Controller
                    control={control}
                    name="program"
                    rules={{
                      required: true,
                    }}
                    render={({
                      field: { onChange, onBlur, value, name, ref },
                    }) => (
                      <Select
                        name="program"
                        value={value}
                        onChange={onChange}
                        options={rowsProgram}
                        isSearchable={true}
                        className="react-select-container"
                        classNamePrefix="react-select"
                      />
                    )}
                  />

                  {errors.program && (
                    <Form.Text className="text-muted validationText hasError">
                      Program is required
                    </Form.Text>
                  )}
                </Form.Group>
              </Col>
              <Col xs={12} sm={12} md={8} lg={4} xl={4}>
                <Form.Group className="mb-3" controlId="dob">
                  <Form.Label>Date Of Birth</Form.Label>
                  <Form.Control
                    placeholder="Enter Date Of Birth"
                    max={format(new Date(), "yyyy-MM-dd")}
                    {...register("dob", {
                      required: {
                        value: "required",
                        message: "dob is required",
                      },
                    })}
                    type="date"
                  />
                  {errors.dob && (
                    <Form.Text className="text-muted validationText hasError">
                      {errors.dob.message}
                    </Form.Text>
                  )}
                </Form.Group>
              </Col>
              <Col xs={12} sm={12} md={8} lg={4} xl={4}>
                <Form.Group className="mb-3" controlId="installation_date">
                  <Form.Label>Install Date</Form.Label>
                  <Form.Control
                    placeholder="Enter Installation Date"
                    max={format(new Date(), "yyyy-MM-dd")}
                    {...register("installation_date", {
                      required: {
                        value: "required",
                        message: "installation date is required",
                      },
                    })}
                    type="date"
                  />
                  {errors.installation_date && (
                    <Form.Text className="text-muted validationText hasError">
                      {errors.installation_date.message}
                    </Form.Text>
                  )}
                </Form.Group>
              </Col>
              <Col xs={12} sm={12} md={8} lg={4} xl={4}>
                <Form.Group className="mb-3" controlId="payment_date">
                  <Form.Label>Payment Date</Form.Label>
                  <Form.Control
                    placeholder="Enter Payment Date"
                    max={format(new Date(), "yyyy-MM-dd")}
                    {...register("payment_date", {
                      required: {
                        value: "required",
                        message: "payment date is required",
                      },
                    })}
                    type="date"
                  />
                  {errors.payment_date && (
                    <Form.Text className="text-muted validationText hasError">
                      {errors.payment_date.message}
                    </Form.Text>
                  )}
                </Form.Group>
              </Col>
              <Col xs={12} sm={12} md={8} lg={4} xl={4}>
                <div className={"pass-field mb-3"}>
                  <Form.Label>Change Password</Form.Label>
                  <InputGroup controlId="passowrd">
                    <FormControl
                      autocomplete="new-password"
                      autofill="new-password"
                      placeholder="Enter Password"
                      {...register("password", {
                        minLength: {
                          value: 8,
                          message: "Minimum length is 8",
                        },
                        maxLength: {
                          value: 255,
                          message: "Maximum length is 255",
                        },
                      })}
                      onClick={() => setConfirmPass(!confirmPass)}
                      type={pass ? "password" : "text"}
                    />
                    <Button
                      variant="outline-secondary"
                      id="button-addon2"
                      onClick={() => TogglePass(!pass)}
                    >
                      {pass ? <FaEye /> : <FaEyeSlash />}
                    </Button>
                  </InputGroup>
                  {errors.password && (
                    <Form.Text className="text-muted validationText hasError">
                      {errors.password.message}
                    </Form.Text>
                  )}
                </div>
              </Col>
            </Row>

            <Row>
              <Col xs={12} sm={12} md={8} lg={4} xl={4}>
                <input
                  type={"submit"}
                  className={"btn btn-green-theme w-100 mt-3"}
                  disabled={disableButton}
                  value={"Edit User"}
                />
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
    </div>
  );
}

export default EditUser;
