import {apiUrl} from '../utils/constants';
import {GET, DELETE} from './api.service.wrapper';

export const GetReviews = async (page= '', perPage= '', keyword= '') => {
    let params = {};

    if(page){
        params['page'] = page;
    }

    if(perPage){
        params['per-page'] = perPage;
    }

    if(keyword){
        params['keyword'] = keyword;
    }

    const response = await GET(apiUrl.reviews, params);
    return response;
}

export const DeleteReview = async (id) => {
    const response = await DELETE(apiUrl.reviews, id);
    return response;
}
