import S3 from "react-aws-s3";
import {constants} from "./constants";
window.Buffer = window.Buffer || require("buffer").Buffer;

export const MoneyFormat = (value) => {
    let currency = Intl.NumberFormat(constants.LOCALE, {
        style: "currency",
        currency: constants.CURRENCY,
        useGrouping: false,
        maximumFractionDigits: 2,
        minimumFractionDigits: 2
    });

    return currency.format(value)
}

export const randomIdWithName = (userName) => {
    let randomNumber = Math.random().nextInt(100000);
    return userName + randomNumber;
}


export const UploadImageToS3 = async (fileInput) => {
    // const file = fileInput.current.files[0];
    const newFileName = fileInput.name;
    const fileSize = fileInput.size;

    //Check filesize
    if (fileSize > parseInt(constants.S3CREDENTIAL.fileSize, 10)) {
        return {
            success: false,
            message: constants.ERRORMESSAGES.fileSize
        }
    }

    const config = {
        bucketName: constants.S3CREDENTIAL.bucketName,
        dirName: constants.S3CREDENTIAL.dirName,
        region: constants.S3CREDENTIAL.region,
        accessKeyId: constants.S3CREDENTIAL.accessKeyId,
        secretAccessKey: constants.S3CREDENTIAL.secretAccessKey,
        s3Url: constants.S3CREDENTIAL.s3EndPoint,
    };

    console.log("config", config)

    const ReactS3Client = new S3(config);
    let responseData = {};
    await ReactS3Client.uploadFile(fileInput, newFileName).then((data) => {
        if (data && data.status === 204) {
            responseData = {
                success: true,
                message: constants.ERRORMESSAGES.fileSuccess,
                data: data.location
            }
        } else {
            responseData = {
                success: false,
                message: constants.ERRORMESSAGES.fileError
            }
        }
    })

    return responseData;
}
