import React, {useEffect, useState} from 'react'
import {BiFile} from 'react-icons/bi'
import {FaTrash, FaCheckDouble, FaCheck, FaUsers, FaUser} from 'react-icons/fa'
import LightGallery from 'lightgallery/react';
import 'lightgallery/css/lightgallery.css';
import 'lightgallery/css/lg-zoom.css';
import 'lightgallery/css/lg-thumbnail.css';
import lgThumbnail from 'lightgallery/plugins/thumbnail';
import lgZoom from 'lightgallery/plugins/zoom';
import moment from "moment";
import {readMessage} from "../../../services/firebase.service";
import {constants} from "../../../utils/constants";

const Thread = ({activeThread, type, text, mediaType, media, profileImg, receivedAt, senderImage, receiverImage, isRead, setDelModalShow, setDeleteModalData, product}) => {
    const trashButtonClick = async (type, thread_id, received_at) => {
        setDeleteModalData({
            type: await type,
            active_thread: await thread_id,
            message_id: await received_at,
        });
        setDelModalShow(true);
    }

    return (
        <div className={`full ${type == "received" ? "snd_row" : ""} ${mediaType == "product" ? "custom-chat-card" : ""}`}>
            <img onError={({currentTarget}) => {
                                    currentTarget.onerror = null;
                                    currentTarget.src = constants.USERIMAGENOTFOUND;
                                }} src={type == "received" ? receiverImage : senderImage} className="dp"/>
            <span className="text hasMedia">
                {
                    text && mediaType != "product" ?
                        <div className={mediaType ? "mb-2" : ""}>
                            {text}
                        </div> : ""
                }

                {
                    mediaType == "audio" ?
                        <audio controls>
                            <source src={media} type="audio/mpeg"/>
                            Your browser does not support the audio element.
                        </audio> : ""
                }

                {
                    mediaType == "video" ?
                        <video width="320" style={{borderRadius: '5px'}} controls>
                            <source src={media} type="video/mp4"/>
                            Your browser does not support the audio element.
                        </video> : ""
                }

                {
                    mediaType == "image" ?
                        <div className={"mediaShared"} style={{cursor: "pointer"}}>
                            <LightGallery
                                speed={500}
                                plugins={[lgThumbnail, lgZoom]}
                            >
                                <a href={media}>
                                    <img src={media} className={"img-responsive imgMedia d-block"}/>
                                </a>
                            </LightGallery>
                        </div> : ""
                }

                {
                    mediaType == "gallery" ?
                        <div className={"mediaShared mediaGallery"}>
                            <LightGallery
                                speed={500}
                                plugins={[lgThumbnail, lgZoom]}
                            >
                                <a href={"https://picsum.photos/300/300"}>
                                    <img src={"https://picsum.photos/300/300"}
                                         className={"img-responsive imgMedia"}/>
                                </a>
                                <a href={"https://picsum.photos/300/300"}>
                                    <img src={"https://picsum.photos/300/300"}
                                         className={"img-responsive imgMedia"}/>
                                </a>
                                <a href={"https://picsum.photos/300/300"}>
                                    <img src={"https://picsum.photos/300/300"}
                                         className={"img-responsive imgMedia"}/>
                                </a>
                                <a href={"https://picsum.photos/300/300"}>
                                    <img src={"https://picsum.photos/300/300"}
                                         className={"img-responsive imgMedia"}/>
                                </a>
                            </LightGallery>
                        </div> : ""
                }
                {
                    mediaType == "doc" ?
                        <div className={"mediaShared mediaFile"}>
                            <span className={"icon"}>
                                <BiFile/>
                            </span>
                            <span>
                                <a href={media} target={"_blank"}>
                                    Download
                                </a>
                            </span>
                        </div> : ""
                }
                {
                    mediaType == "product" ?
                        <div className={"box-order"}>
                            <img onError={({currentTarget}) => {
                                    currentTarget.onerror = null;
                                    currentTarget.src = constants.IMAGENOTFOUND;
                                }} className={"img-fluid img-full"} src={product.image} />
                            <p className={"product-name"}>{product.name}</p>
                            <p className={"description"}>{product.description.slice(0,200) + "..."}</p>
                            <p className={"orderQty"}>min. order {product.minOrder} piece</p>



                        </div> : ''
                }
                    </span>
            <span onClick={() => trashButtonClick(type, activeThread, receivedAt)} className={"trash"}>
                <FaTrash/>
            </span>
            <h5>{moment.utc(receivedAt).fromNow()}
                {
                    type == "received" ? <span style={{marginLeft: '5px'}}>
                        {
                            isRead ? <FaCheckDouble style={{color: '#12726C'}}/> : <FaCheck/>
                        }
                        </span> : ''
                }
            </h5>
        </div>
    )
}

export default Thread
