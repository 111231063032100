import {apiUrl} from '../utils/constants';
import {GET, POST, PUT, DELETE} from './api.service.wrapper';

export const GetColors = async (page= '', perPage= '', keyword= '') => {
    let params = {};

    if(page){
        params['page'] = page;
    }

    if(perPage){
        params['per-page'] = perPage;
    }

    if(keyword){
        params['keyword'] = keyword;
    }

    const response = await GET(apiUrl.colors, params);
    return response;
}

export const GetColorById = async (id) => {
    let response = '';
    response = await GET(apiUrl.colors + '/' + id);
    return response;
}

export const CreateColor = async (data) => {
    const response = await POST(apiUrl.colors, data);
    return response;
}

export const UpdateColor = async (id, data) => {
    const response = await PUT(apiUrl.colors, id, data);
    return response;
}

export const DeleteColor = async (id) => {
    const response = await DELETE(apiUrl.colors, id);
    return response;
}

export const GetAllColors = async () => {
    let response = '';
    response = await GET(apiUrl.get_colors);
    return response;
}
