import React, {useEffect, useState} from 'react'
import swal from "sweetalert"
import {Link} from "react-router-dom"
import {Button, Col, Dropdown, DropdownButton, Form, FormControl, InputGroup, Row} from "react-bootstrap"
import {AddIconOutline, FilterIcon, MenuDots, SearchIcon} from "../../components/SvgIcons"
import MaterialTable from "material-table"
import {DeleteProduct, GetProducts} from "../../services/products.service";
import {toast} from "react-toastify";
import {constants, customStyles} from "../../utils/constants";
import {GetCategories} from "../../services/categories.service";
import {GetSubCategories} from "../../services/sub_categories.service";
import {useHistory} from 'react-router-dom';
import DataTable from 'react-data-table-component';
import BarLoader from "react-spinners/BarLoader";
import {EmptyLocalStorage, GetAuthUser, GetUserRole} from "../../services/auth/auth.service";

function Products() {
    let history = useHistory();
    const [loading, setLoading] = useState(false);
    const [totalRows, setTotalRows] = useState(0);
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(constants.PERPAGE);
    const [rows, setRows] = useState();
    const [filterCategory, setFilterCategory] = useState('');
    const [filterSubCategory, setFilterSubCategory] = useState('');
    const [category, setCategory] = useState('');
    const [subCategory, setSubCategory] = useState('');
    const [keyword, setKeyword] = useState('');

    useEffect(async () => {
        await getProductFunction();
    }, [keyword, category, subCategory, page, perPage])

    useEffect(async () => {
        await getCategories();
    }, [])

    const getProductFunction = async () => {
        setLoading(true)
        let user = GetAuthUser();
        let role = GetUserRole();
        await GetProducts(keyword, category, subCategory, page, perPage, user, role).then((result) => {
            if (result.status) {
                if (result.data.data) {
                    const rowData = [];
                    setPage(result.data.meta.current_page)
                    setTotalRows(result.data.meta.total);
                    result.data.data.map((dt) => {
                        rowData.push({
                            id: dt.id,
                            imgUrl: dt.attachments[0]?.smallImage || constants.IMAGENOTFOUND,
                            sid: dt?.store?.name,
                            productName: dt?.name,
                            category: dt?.category?.name,
                            subCategory: dt?.sub_category?.name,
                        });
                    })
                    setRows(rowData);
                    setLoading(false);
                }
            } else {
                setLoading(false);
                toast.error(result.message);
            }
        }).catch((error) => {
            setLoading(false);
            if (error.response.status == 401) {
                EmptyLocalStorage()
                history.push('/');
            } else {
                return toast.error(error.response.data.message);
            }
        })
    }

    const getCategories = async () => {
        const categories = [];
        await GetCategories().then((result) => {
            if (result.status) {
                if (result.data.data) {
                    result.data.data.map((dt) => {
                        categories.push({
                            id: dt.id,
                            name: dt.name
                        });
                    })
                    return setFilterCategory(categories);
                }
            } else {
                return toast.error(result.message);
            }
        }).catch((error) => {
            if (error.response.status == 401) {
                EmptyLocalStorage()
                history.push('/');
            } else {
                return toast.error(error.response.data.message);
            }
        })
    }

    const getSubCategories = async (category_id) => {
        const sub_category = [];
        if (!category_id) {
            setFilterSubCategory("")
            return setSubCategory("")
        }
        await GetSubCategories(category_id).then((result) => {
            if (result.status) {
                if (result.data.data) {
                    result.data.data.map((dt) => {
                        sub_category.push({
                            id: dt.id,
                            name: dt.name
                        });
                    })
                    return setFilterSubCategory(sub_category);
                }
            } else {
                return toast.error(result.message);
            }
        }).catch((error) => {
            if (error.response.status == 401) {
                EmptyLocalStorage()
                history.push('/');
            } else {
                return toast.error(error.response.data.message);
            }
        })
    }

    const onEdit = (id) => {
        history.push('/products/edit/' + id);
    }
    const onView = (id) => {
        history.push('/products/view/' + id);
    }
    const onDelete = (id) => {
        swal({
            title: "Are you sure?",
            text: "Are you sure you want to delete this product?",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then(async (willShip) => {
            //Delete Product API
            if (willShip) {
                await DeleteProduct(id).then((result) => {
                    if (result.status) {
                        swal(result.message, {
                            icon: "success",
                        });
                        getProductFunction(keyword, category, subCategory, page);
                    } else {
                        toast.error(result.message);
                    }
                }).catch((error) => {
                    if (error.response.status == 401) {
                        EmptyLocalStorage()
                        history.push('/');
                    } else {
                        return toast.error(error.response.data.message);
                    }
                })
            }
        });
    }

    const productActions = (data) => {
        return (
            <DropdownButton align="end" className={"actionDropdown"} id="dropdown-item-button" title={<MenuDots/>}>
                <Dropdown.Item as="button" onClick={() => onView(data)}>View</Dropdown.Item>
                <Dropdown.Item as="button" onClick={() => onEdit(data)}>Edit</Dropdown.Item>
                <Dropdown.Item as="button" onClick={() => onDelete(data)}>Delete</Dropdown.Item>
            </DropdownButton>
        )
    }

    const columns = [
        {
            id: 'imgUrl',
            name: 'Image',
            maxWidth: '100px',
            selector: rowData => rowData.oid,
            format: rowData => <img src={rowData.imgUrl}
                                    onError={({currentTarget}) => {
                                        currentTarget.onerror = null;
                                        currentTarget.src = constants.IMAGENOTFOUND;
                                    }}
                                    className={"img-table img-thumbnail"} alt={"Image"}/>
        },
        {
            id: 'productName',
            name: 'Product Name',
            maxWidth: '160px',
            selector: rowData => rowData.productName,
            format: rowData => <a target={"_self"} style={{color: '#9F9F9F', textDecoration: 'none'}}
                                  href={"/products/view/" + rowData.id}>{rowData.productName}</a>
        },
        {
            id: 'sid',
            name: 'Store',
            selector: rowData => rowData.sid,
            /*format: rowData => <a target={"_self"} style={{color: '#9F9F9F', textDecoration: 'none'}}
                                  href={"/products/view/" + rowData.id}>{rowData.sid}</a>*/
        },
        {
            id: 'category',
            name: 'Category',
            selector: rowData => rowData.category,
        },
        {
            id: 'subCategory',
            name: 'Sub Category',
            selector: rowData => rowData.subCategory,
        },
        {
            field: 'action',
            name: 'Action',
            cell: rowData => productActions(rowData.id),
        },
    ];

    return (
        <div className={"orderPage"}>
            <h3 className="page-heading">Products</h3>
            <hr/>
            <div className={"customOrderFilters"}>
                <Row>
                    <Col xs={12} sm={12} md={4} lg={4} xl={4}>
                        <InputGroup className="mb-3 search-group">
                            <InputGroup.Text id="basic-addon1"><SearchIcon/></InputGroup.Text>
                            <FormControl
                                placeholder="Search"
                                aria-label="Search"
                                aria-describedby="Search"
                                maxLength={"255"}
                                onChange={(e) => {
                                    if (e.target.value.length > 3) {
                                        setKeyword(e.target.value)
                                    } else {
                                        setKeyword("")
                                    }
                                }}
                            />
                        </InputGroup>
                    </Col>
                    <Col xs={12} sm={12} md={8} lg={8} xl={8}>
                        <Row>
                            <Col xs={12} sm={12} md={4} lg={4} xl={4}>
                                <InputGroup className="mb-3 select-group">
                                    <InputGroup.Text id="basic-addon1"><FilterIcon/></InputGroup.Text>
                                    <Form.Select
                                        onChange={e => {
                                            setCategory(e.target.value);
                                            getSubCategories(e.target.value);
                                        }}
                                        aria-label="Default Filters">
                                        <option value="">Categories</option>
                                        {
                                            filterCategory ?
                                                filterCategory.map((d) => {
                                                    return (<option value={d.id}>{d.name}</option>);
                                                }) : ''
                                        }
                                    </Form.Select>
                                </InputGroup>
                            </Col>
                            <Col xs={12} sm={12} md={4} lg={4} xl={4}>
                                <InputGroup className="mb-3 select-group">
                                    <InputGroup.Text id="basic-addon1"><FilterIcon/></InputGroup.Text>
                                    <Form.Select
                                        onChange={e => {
                                            setSubCategory(e.target.value);
                                        }}
                                        aria-label="Default Filters">
                                        <option value="">Sub Categories</option>
                                        {
                                            filterSubCategory ?
                                                filterSubCategory.map((d) => {
                                                    return (<option value={d.id}>{d.name}</option>);
                                                })
                                                : ''
                                        }
                                    </Form.Select>
                                </InputGroup>
                            </Col>
                            <Col xs={12} sm={12} md={4} lg={4} xl={4}>
                                <Link to={"/products/add-new"}>
                                    <Button className={"btn btn-green-theme h40 w-100"}>
                                        <AddIconOutline/> Add New
                                    </Button>
                                </Link>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </div>
            <div className="theme-dataGrid products-dataGrid">
                <DataTable
                    columns={columns}
                    data={rows}
                    progressPending={loading}
                    customStyles={customStyles}
                    selectableRows={false}
                    striped
                    highlightOnHover
                    pagination
                    paginationServer
                    paginationTotalRows={totalRows}
                    paginationPerPage={perPage}
                    onChangePage={(page) => {
                        setPage(page);
                    }}
                    onChangeRowsPerPage={(currentRowsPerPage) => {
                        setPerPage(currentRowsPerPage)
                    }}
                    progressComponent={<BarLoader color={'#961b1a'} loading={loading} css={'marginTop: 10px'}
                                                  height={'4'} width={'100%'}/>}
                />
            </div>
        </div>
    )
}

export default Products
