import {apiUrl} from '../utils/constants';
import {GET, POST, PUT, DELETE} from './api.service.wrapper';

export const GetCategories = async (page='', perPage='', keyword = '' ) => {
    let params = {};

    if(page){
        params['page'] = page;
    }

    if(perPage){
        params['per-page'] = perPage;
    }

    if(keyword){
        params['keyword'] = keyword;
    }

    const response = await GET(apiUrl.categories, params);
    return response;
}

export const GetCategoryById = async (id) => {
    let response = '';
    response = await GET(apiUrl.categories + '/' + id);
    return response;
}

export const CreateCategory = async (data) => {
    const config = {
        headers: {
            'content-type': 'multipart/form-data'
        }
    }
    const response = await POST(apiUrl.categories, data, config);
    return response;
}

export const UpdateCategory = async (id, data) => {
    const config = {
        headers: {
            'content-type': 'multipart/form-data'
        }
    }
    const response = await PUT(apiUrl.categories, id, data, config);
    return response;
}

export const DeleteCategory = async (id) => {
    const response = await DELETE(apiUrl.categories, id);
    return response;
}

