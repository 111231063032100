import React, {useEffect, useState} from 'react'
import {GetUserRole} from "../../services/auth/auth.service"
import {AdminDashboard, OfficerDashboard, AgentDashboard, RepresentativeDashboard} from "./ByRole/index"
import {constants} from "../../utils/constants";

function Dashboard() {
    const [dashboard, setDashboard] = useState();

    useEffect(() => {
        let role = GetUserRole();
        if(role == constants.ROLES.ROLE_ADMIN){
            setDashboard(<AdminDashboard RoleId={role} />);
        }
        else if(role == constants.ROLES.ROLE_AGENT){
            setDashboard(<AgentDashboard RoleId={role} />);
        }
        else if(role == constants.ROLES.ROLE_REPRESENTATIVE){
            setDashboard(<RepresentativeDashboard RoleId={role} />);
        }
        else if(role == constants.ROLES.ROLE_OFFICER){
            setDashboard(<OfficerDashboard RoleId={role} />);
        }
    }, []);

    return (
        <div>
            {dashboard}
        </div>
    )
}

export default Dashboard
