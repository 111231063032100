import React, { useRef, useState, useEffect } from "react";
import {
  Form,
  Row,
  Col,
  Button,
  InputGroup,
  FormControl,
} from "react-bootstrap";
import BreadcrumbsComponent from "../../components/BreadcrumbsComponent";
import { useForm } from "react-hook-form";
import { CreateRep } from "../../services/users.service";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import { constants } from "../../utils/constants";
import { createUser } from "../../services/firebase.service";
import { format } from "date-fns";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { EmptyLocalStorage } from "../../services/auth/auth.service";
import { UploadIcon } from "../../components/SvgIcons";
import {UploadImageToS3} from "../../utils/helpers";

function AddRepresentatives(props) {
  let history = useHistory();
  const [data, setData] = useState([]);
  const [pass, TogglePass] = useState(true);
  const [confPass, ToggleConfPass] = useState(true);
  const [confirmPass, setConfirmPass] = useState(true);
  const [disableButton, setDisableButton] = useState(false);
  const [file, setFileData] = useState(false);
  const [defaultImage, setDefaultImage] = useState(false);
  const [selectedFile, setSelectedFile] = useState(false);

  const {
    register,
    watch,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
  });

  const password = useRef({});
  password.current = watch("password", "");
  const onSubmit = async (data) => {
    setDisableButton(true);
    let url = '';
    if (selectedFile) {
      console.log(selectedFile.type)
      if (selectedFile.type == "image/jpg" || selectedFile.type == "image/png" || selectedFile.type == "image/jpeg" ){
        let uploadImage = await UploadImageToS3(selectedFile);
        if (uploadImage) {

          console.log(uploadImage)
            if (!uploadImage.success) {
                toast.error(uploadImage.message);
                return false;
            }

            // content_type = constants.CONTENTTYPE[file.type];
            url = uploadImage.data;
            // filename = file.name;
        }

        await CreateRep({
          full_name: data.full_name.trim(),
          state: data.state,
          agent_type: data.agent_type,
          email: data.email.trim(),
          phone_number: data.phone_number.trim(),
          password: data.password.trim(),
          password_confirmation: data.password_confirmation.trim(),
          profile_image: url,
          device_type: "web",
          device_token: "agent",
          clients: [],
          agents: []
        })
          .then(async (data) => {
            if (data.success) {
              toast.success(data.message);
              history.push("/manage-representatives");
            } else {
              setDisableButton(false);
              toast.error(data.message);
            }
          })
          .catch((error) => {
            setDisableButton(false);
            if (error.response.status == 401) {
              EmptyLocalStorage();
              history.push("/");
            } else {
              console.log(error);
              return toast.error(error.response.data.message);
            }
          });
      }else {
        toast.error("Invalid file type.");
        setDisableButton(false)
      }
      
    }
  };
  const handleChange = (event) => {
    setSelectedFile(event.target.files[0])
    setFileData(URL.createObjectURL(event.target.files[0]));
  };
  const [countryId, setCountryId] = useState("");
  const [disableField, setDisableField] = useState(true);

  return (
    <div className="AddNewUser">
      {/*<BreadcrumbsComponent />*/}
      <h3 className="page-heading">Create Representatives</h3>
      <hr />
      <Row>
        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
          <Form
            className="formAddUser"
            autoComplete="off"
            role="presentation"
            onSubmit={handleSubmit(onSubmit)}
          >
            <Row>
              <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                <Row
                  className={"mb-3  align-items-center"}
                >
                  <Col xs={12} sm={12} md={6} lg={4} xl={4}>
                    <Form.Group
                      controlId="formFile"
                      onChange={(e) => handleChange(e)}
                      className="custom-file-upload mb-0"
                    >
                      <Form.Label className={"toggleFileUpload mb-0"}>
                        <UploadIcon />
                        Upload Image
                      </Form.Label>
                      <Form.Control
                        type="file"
                        accept="image/png, image/jpeg, image/jpg, image/gif"
                        {...register("profile_image")}
                      />
                    </Form.Group>
                  </Col>
                  <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                    <ul className={"list-inline list-variations mb-0"}>
                      <li
                        className={
                          "list-inline-item d-flex justify-content-between mb-0"
                        }
                      >
                        <img
                          onError={({ currentTarget }) => {
                            currentTarget.onerror = null; // prevents looping
                            currentTarget.src = constants.IMAGENOTFOUND;
                          }}
                          src={file ? file : defaultImage}
                          width="50%"
                          height="50%"
                        />
                      </li>
                    </ul>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row>
              <Col xs={12} sm={12} md={8} lg={4} xl={4}>
                <Form.Group className="mb-3" controlId="full_name">
                  <Form.Label>Full Name</Form.Label>
                  <Form.Control
                    placeholder="Enter Full Name"
                    id={"formSignUpName"}
                    {...register("full_name", {
                      required: {
                        value: "required",
                        message: "Full Name is required",
                      },
                      pattern:{
                        value: /^[A-Za-z ]+$/,
                        message: "Invalid full name"
                      },
                      minLength: {
                        value: 3,
                        message: "Minimum length is 3",
                      },
                      maxLength: {
                        value: 25,
                        message: "Maximum length is 25",
                      },
                    })}
                    type="text"
                  />
                  {errors.full_name && (
                    <Form.Text className="text-muted validationText hasError">
                      {errors.full_name.message}
                    </Form.Text>
                  )}
                </Form.Group>
              </Col>

              {/* <Col xs={12} sm={12} md={8} lg={4} xl={4}>
                                <Form.Group className="mb-3" controlId="username">
                                    <Form.Label>Username</Form.Label>
                                    <Form.Control
                                        placeholder="Enter Username"
                                        id={"username"}
                                        autoComplete={"nofill"}
                                        {...register('username', {
                                            required: {
                                                value: "required",
                                                message: "Username is required"
                                            },
                                            minLength: {
                                                value: 3,
                                                message: "Minimum length is 3"
                                            },
                                            maxLength: {
                                                value: 25,
                                                message: "Maximum length is 25"
                                            },
                                        })} type="text"
                                    />
                                    {errors.username && <Form.Text
                                        className="text-muted validationText hasError">{errors.username.message}</Form.Text>}

                                </Form.Group>
                            </Col> */}
              <Col xs={12} sm={12} md={8} lg={4} xl={4}>
                <Form.Group className="mb-3" controlId="email">
                  <Form.Label>Email Address</Form.Label>
                  <Form.Control
                    placeholder="Enter Email Address"
                    autocomplete="off"
                    autofill="off"
                    {...register("email", {
                      required: {
                        value: "required",
                        message: "Email is required",
                      },
                      pattern: {
                        value: /\S+@\S+\.\S+/,
                        message: "Invalid email",
                      },
                      maxLength: {
                        value: 255,
                        message: "Maximum length is 255",
                      },
                    })}
                    type="text"
                  />
                  {errors.email && (
                    <Form.Text className="text-muted validationText hasError">
                      {errors.email.message}
                    </Form.Text>
                  )}
                </Form.Group>
              </Col>
          
              <Col xs={12} sm={12} md={8} lg={4} xl={4}>
                <Form.Group className="mb-3" controlId="phone_number">
                  <Form.Label>Phone Number</Form.Label>
                  <Form.Control
                    type="tel"
                    placeholder="Enter Phone Number"
                    {...register("phone_number", {
                      required: {
                        value: "required",
                        message: "Phone is required",
                      },
                      pattern: {
                        value: /^\+[0-9]*$/,
                        message: "incorrect pattern +xxxxxxxxxxxx",
                      },
                      minLength: {
                        value: 8,
                        message: "Minimum length is 8",
                      },
                      maxLength: {
                        value: 16,
                        message: "Maximum length is 16",
                      },
                    })}
                  />
                  {errors.phone_number && (
                    <Form.Text className="text-muted validationText hasError">
                      {errors.phone_number.message}
                    </Form.Text>
                  )}
                </Form.Group>
              </Col>
              <Col xs={12} sm={12} md={8} lg={4} xl={4}>
                <Form.Group className="mb-3" controlId="state">
                  <Form.Label>State</Form.Label>
                  <Form.Control
                    placeholder="Enter State"
                    id={"formSignUpName"}
                    {...register("state", {
                      required: {
                        value: "required",
                        message: "State is required",
                      },
                      pattern:{
                        value: /^[A-Za-z ]+$/,
                        message: "invalid state name entered"
                      },
                      minLength: {
                        value: 3,
                        message: "Minimum length is 3",
                      },
                      maxLength: {
                        value: 25,
                        message: "Maximum length is 25",
                      },
                    })}
                    type="text"
                  />
                  {errors.state && (
                    <Form.Text className="text-muted validationText hasError">
                      {errors.state.message}
                    </Form.Text>
                  )}
                </Form.Group>
              </Col>
        

        
              <Col xs={12} sm={12} md={8} lg={4} xl={4}>
                <div className={"pass-field mb-3"}>
                  <Form.Label>Password</Form.Label>
                  <InputGroup controlId="passowrd">
                    <FormControl
                      placeholder="Enter Password"
                      autoComplete={"off"}
                      {...register("password", {
                        required: {
                          value: "required",
                          message: "Password is required",
                        },
                        minLength: {
                          value: 8,
                          message: "Minimum length is 8",
                        },
                        maxLength: {
                          value: 255,
                          message: "Maximum length is 255",
                        },
                      })}
                      onClick={() => setConfirmPass(!confirmPass)}
                      type={pass ? "password" : "text"}
                    />
                    <Button
                      variant="outline-secondary"
                      id="button-addon2"
                      onClick={() => TogglePass(!pass)}
                    >
                      {pass ? <FaEye /> : <FaEyeSlash />}
                    </Button>
                  </InputGroup>
                  {errors.password && (
                    <Form.Text className="text-muted validationText hasError">
                      {errors.password.message}
                    </Form.Text>
                  )}
                </div>
              </Col>
              <Col xs={12} sm={12} md={8} lg={4} xl={4}>
                <div className={"pass-field mb-3"}>
                  <Form.Label>Confirm Password</Form.Label>
                  <InputGroup controlId="password_confirmation">
                    <Form.Control
                      placeholder="Enter Password Again"
                      // disabled={confirmPass}
                      {...register("password_confirmation", {
                        required: {
                          value: "required",
                          message: "Confirm Password is required",
                        },
                        minLength: {
                          value: 8,
                          message: "Minimum length is 8",
                        },
                        validate: password.current
                          ? (value) =>
                              value === password.current ||
                              "The passwords do not match"
                          : "",
                        maxLength: {
                          value: 255,
                          message: "Maximum length is 255",
                        },
                      })}
                      type={confPass ? "password" : "text"}
                    />
                    <Button
                      variant="outline-secondary"
                      id="button-addon2"
                      // disabled={confirmPass}
                      onClick={() => ToggleConfPass(!confPass)}
                    >
                      {confPass ? <FaEye /> : <FaEyeSlash />}
                    </Button>
                  </InputGroup>
                  {errors.password_confirmation && (
                    <Form.Text className="text-muted validationText hasError">
                      {errors.password_confirmation.message}
                    </Form.Text>
                  )}
                </div>
              </Col>
              </Row>
            <Row>
              <Col xs={12} sm={12} md={8} lg={4} xl={4}>
                <input
                  type={"submit"}
                  className={"btn btn-green-theme w-100 mt-3"}
                  disabled={disableButton}
                  value={"Create Representatives"}
                />
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
    </div>
  );
}

export default AddRepresentatives;
