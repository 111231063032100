import React, {useState, useCallback, useEffect} from 'react'
import {
    getLastThreadMessage,
    readMessage,
    readUserChatMessage
} from "../../services/firebase.service";
import {constants} from "../../utils/constants";

export const ThreadLastMessage = ({chat_id, order_chat}) => {
    const [lastMessage, setLastMessage] = useState('');
    if (chat_id) {
        let collection = order_chat ? constants.COLLECTION.CHATROOM : constants.COLLECTION.GENERALCHATROOM
        let last_message = '';

        getLastThreadMessage(collection, chat_id, (data) => {
            last_message = data?.delete == 0 && data?.deleteAgent == 0 ? data?.content : 'Message Delete';
            setLastMessage(last_message);
        })
        return (
            <h4 className="sub-msg">{lastMessage}</h4>
        )
    }
}

const ChatList = ({orderChat, threads, active_thread, onSearchChange, onActiveClick, onActiveCustomer, setActiveVendor}) => {

    const handleSearchInputChange = useCallback(async (event) => {
        await onSearchChange(event.target.value.trim())
    }, [onSearchChange])

    const handleActiveClick = useCallback(async (chat_id) => {
        await onActiveClick(chat_id)
    }, [onActiveClick])

    const handleActiveCustomer = useCallback(async (customer_id) => {
        await onActiveCustomer(customer_id)
    }, [onActiveCustomer])

    const readChatMessages = useCallback(async (active_thread, customer_id) => {
        if (active_thread, customer_id) {
            if (orderChat) {
                readMessage({
                    active_thread: active_thread,
                    customer_id: customer_id
                });
            } else {
                readUserChatMessage({
                    active_thread: active_thread,
                    customer_id: customer_id
                });
            }
        }
    })


    return (
        <div id="title_starts" className="chat-title">
            <ul className="nav nav-tabs">
                <span className="search align-content-center">
                    <input type="text" placeholder="Search" className="input_search" maxLength={"255"}
                           onChange={handleSearchInputChange}/>
                </span>
                {
                    threads && threads.length > 0 ?
                        threads.map((thread) => {
                            return (
                                <li className={active_thread == thread.chat_id ? "active" : ""}
                                    onClick={() => {
                                        setActiveVendor('');
                                        handleActiveClick(thread.chat_id)
                                        handleActiveCustomer(thread.customer_id)
                                        readChatMessages(thread.chat_id, thread.customer_id)
                                    }}
                                >
                                    <a href="#">
                                        <h3 className="name"
                                            style={{textTransform: 'capitalized'}}>{orderChat ? 'ORDER# ' + thread.chat_id : thread.customer_name} </h3>
                                        {thread.online ? <span className="online"></span> : ''}
                                        <ThreadLastMessage chat_id={thread.chat_id} order_chat={orderChat} />
                                        {/*{threadLastMessage(thread.chat_id)}*/}
                                        {/*<h4 className="sub-msg">{thread.last_message}</h4>*/}
                                        <small className="min">{thread.created_ago}</small>
                                    </a>
                                </li>
                            )
                        })
                        :
                        <div style={{
                            textAlign: 'center',
                            fontSize: '12px',
                            color: '#9f9f9f'
                        }}>No {orderChat ? 'orders' : 'chat'} available</div>
                }
            </ul>
        </div>
    )
}

export default ChatList
