import React, {useRef, useState, useEffect} from 'react'
import {Form, Row, Col, Button, InputGroup, FormControl} from 'react-bootstrap'
import BreadcrumbsComponent from "../../components/BreadcrumbsComponent";
import {useForm} from "react-hook-form";
import {toast} from "react-toastify";
import {useHistory} from 'react-router-dom';
import {constants} from "../../utils/constants";
import {CreateAds} from "../../services/advertisement.service";
import {EmptyLocalStorage} from "../../services/auth/auth.service";

function AddNewAdvertisements(props) {
    let history = useHistory();
    const [data, setData] = useState([]);
    const [disableButton, setDisableButton] = useState(false);
    const [adType, setAdType] = useState("image");
    const [fileError, setFileError] = useState(false);

    const {register, watch, setValue, handleSubmit, formState: {errors}} = useForm({
        mode: "onBlur",
    });

    const onSubmit = async data => {
        setDisableButton(true)
        let file = data?.media[0];
        if(file.size > 52428800){
            setFileError(true);
            setDisableButton(false)
            return false;
        }

        setFileError(false);
        let formData = new FormData();
        formData.append('title', data.title);
        formData.append('description', data.description);
        formData.append('external_url', data.external_url);
        formData.append('ad_type', adType);
        if (data.media[0]) {
            formData.append('media', data.media[0]);
        }

        await CreateAds(formData).then(async (data) => {
            if (data.success) {
                toast.success(data.message);
                history.push('/ads-management');
            } else {
                setDisableButton(false)
                toast.error(data.message);
            }
        }).catch((error) => {
            setDisableButton(false)
            if (error.response.status == 401) {
                EmptyLocalStorage()
                history.push('/');
            } else {
                return toast.error(error.response.data.message);
            }
        })
    };

    return (
        <div className="AddNewAd">
            {/*<BreadcrumbsComponent />*/}
            <h3 className="page-heading">Create Advertisement</h3>
            <hr/>
            <Row>
                <Col xs={12} sm={12} md={8} lg={6} xl={6}>
                    <Form encType={'multipart/form-data'} className="formAddUser" autoComplete="off" role="presentation"
                          onSubmit={handleSubmit(onSubmit)}>
                        <Row>
                            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                <Form.Group className="mb-3" controlId="title">
                                    <Form.Label>Title<small style={{color: "red"}}>*</small></Form.Label>
                                    <Form.Control
                                        placeholder="Enter Title"
                                        id={"formTitle"}
                                        {...register('title', {
                                            required: {
                                                value: "required",
                                                message: "Title is required"
                                            },
                                            minLength: {
                                                value: 3,
                                                message: "Minimum length is 3"
                                            },
                                            maxLength: {
                                                value: 50,
                                                message: "Maximum length is 50"
                                            },
                                        })} type="text"
                                    />
                                    {errors.title && <Form.Text
                                        className="text-muted validationText hasError">{errors.title.message}</Form.Text>}
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                <Form.Group className="mb-3" controlId="description">
                                    <Form.Label>Description<small style={{color: "red"}}>*</small></Form.Label>
                                    <div className={"wysiwyg-editor-containerxx"}>
                                        <Form.Control
                                            as="textarea"
                                            rows={6}
                                            placeholder="Enter Description"
                                            {...register('description', {
                                                onChange: (e) => {
                                                    setValue('description', e.target.value)
                                                },
                                                required: {
                                                    value: "required",
                                                    message: "Description is required"
                                                },
                                            })}
                                        />
                                    </div>
                                    {errors.description && <Form.Text
                                        className="text-muted validationText hasError">{errors.description.message}</Form.Text>}
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                <Form.Group className="mb-3" controlId="external_url">
                                    <Form.Label>External URL</Form.Label>
                                    <Form.Control
                                        placeholder="Enter External URL"
                                        id={"formTitle"}
                                        {...register('external_url', {
                                            maxLength: {
                                                value: 255,
                                                message: "Maximum length is 255"
                                            },
                                        })} type="text"
                                    />
                                    {errors.external_url && <Form.Text
                                        className="text-muted validationText hasError">{errors.external_url.message}</Form.Text>}
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                <Form.Group className="mb-3" controlId="AdType">
                                    <Form.Label>Ad Type</Form.Label>
                                    <Form.Select
                                        aria-label="Ad Type"
                                        name="AdType"
                                        onChange={(e) => {
                                            setAdType(e.target.value)
                                        }}
                                    >
                                        <option selected value="image">Image</option>
                                        <option value="video">Video</option>
                                    </Form.Select>
                                    {errors.AdType && <Form.Text
                                        className="text-muted validationText hasError">{errors.AdType.message}</Form.Text>}
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                                <Form.Group className="mb-3" controlId="image">
                                    <Form.Label>Media <small> (File Size Upto 50MB)</small>
                                    </Form.Label>
                                    <Form.Control
                                        id={"formTitle"}
                                        {...register('media')} type="file"
                                        accept={adType == constants.ADTYPE.IMAGE ? "image/jpeg, image/jpg, image/png" : "video/mp4"}
                                    />
                                    { fileError ? <Form.Text className="text-muted validationText hasError">File size must under 50MB!</Form.Text>: ''}
                                    {errors.media && <Form.Text
                                        className="text-muted validationText hasError">{errors.media.message}</Form.Text>}
                                </Form.Group>
                            </Col>
                        </Row>

                        <Row>
                            <Col xs={12} sm={12} md={8} lg={6} xl={6}>
                                <input type={"submit"} className={"btn btn-green-theme w-100 mt-3"}
                                       disabled={disableButton}
                                       value={"Create Advertisement"}/>
                            </Col>
                        </Row>
                    </Form>

                </Col>
            </Row>

        </div>
    )
}

export default AddNewAdvertisements
