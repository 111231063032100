import React from 'react'
import Badge from 'react-bootstrap/Badge';

const rows = [
    { id: 1, oid: 'ABC12345', customerName: 'Bryson Max', agentName: 'Jordyn Kayden', order: 'Red Velvet Bag', deliveryDate: '04.12.2021', price: '$24.90', status: 'success', action: '' },
    { id: 1, oid: 'ABC12345', customerName: 'Bryson Max', agentName: 'Jordyn Kayden', order: 'Red Velvet Bag', deliveryDate: '04.12.2021', price: '$24.90', status: 'success', action: '' },
    { id: 1, oid: 'ABC12345', customerName: 'Bryson Max', agentName: 'Jordyn Kayden', order: 'Red Velvet Bag', deliveryDate: '04.12.2021', price: '$24.90', status: 'success', action: '' },
    { id: 1, oid: 'ABC12345', customerName: 'Bryson Max', agentName: 'Jordyn Kayden', order: 'Red Velvet Bag', deliveryDate: '04.12.2021', price: '$24.90', status: 'success', action: '' },
    { id: 1, oid: 'ABC12345', customerName: 'Bryson Max', agentName: 'Jordyn Kayden', order: 'Red Velvet Bag', deliveryDate: '04.12.2021', price: '$24.90', status: 'success', action: '' },
    { id: 1, oid: 'ABC12345', customerName: 'Bryson Max', agentName: 'Jordyn Kayden', order: 'Red Velvet Bag', deliveryDate: '04.12.2021', price: '$24.90', status: 'success', action: '' },
    { id: 1, oid: 'ABC12345', customerName: 'Bryson Max', agentName: 'Jordyn Kayden', order: 'Red Velvet Bag', deliveryDate: '04.12.2021', price: '$24.90', status: 'success', action: '' },
    { id: 1, oid: 'ABC12345', customerName: 'Bryson Max', agentName: 'Jordyn Kayden', order: 'Red Velvet Bag', deliveryDate: '04.12.2021', price: '$24.90', status: 'success', action: '' },
    { id: 1, oid: 'ABC12345', customerName: 'Bryson Max', agentName: 'Jordyn Kayden', order: 'Red Velvet Bag', deliveryDate: '04.12.2021', price: '$24.90', status: 'success', action: '' },
    { id: 1, oid: 'ABC12345', customerName: 'Bryson Max', agentName: 'Jordyn Kayden', order: 'Red Velvet Bag', deliveryDate: '04.12.2021', price: '$24.90', status: 'success', action: '' },
    { id: 1, oid: 'ABC12345', customerName: 'Bryson Max', agentName: 'Jordyn Kayden', order: 'Red Velvet Bag', deliveryDate: '04.12.2021', price: '$24.90', status: 'success', action: '' },
    { id: 1, oid: 'ABC12345', customerName: 'Bryson Max', agentName: 'Jordyn Kayden', order: 'Red Velvet Bag', deliveryDate: '04.12.2021', price: '$24.90', status: 'success', action: '' },
    { id: 1, oid: 'ABC12345', customerName: 'Bryson Max', agentName: 'Jordyn Kayden', order: 'Red Velvet Bag', deliveryDate: '04.12.2021', price: '$24.90', status: 'success', action: '' },
    { id: 1, oid: 'ABC12345', customerName: 'Bryson Max', agentName: 'Jordyn Kayden', order: 'Red Velvet Bag', deliveryDate: '04.12.2021', price: '$24.90', status: 'success', action: '' },
    { id: 1, oid: 'ABC12345', customerName: 'Bryson Max', agentName: 'Jordyn Kayden', order: 'Red Velvet Bag', deliveryDate: '04.12.2021', price: '$24.90', status: 'success', action: '' },


];

const columns = [
    { field: 'oid', headerName: 'Order ID', width: 100 },
    { field: 'customerName', headerName: 'Customer', maxWidth: '160px' },
    { field: 'agentName', headerName: 'CSA Agent', maxWidth: '160px' },
    { field: 'order', headerName: 'Order', width: 200 },
    { field: 'deliveryDate', headerName: 'Delivery Date', width: 150},
    { field: 'price', headerName: 'Price', width: 100},
    { field: 'status', headerName: 'Status', width: 100},
    { field: 'action', headerName: 'Action', width: 50 },
];

function ConfirmOrder() {

    return (
        /*add page name as class to each parent div of the page like here*/
        <div className={"orderPage"}>
            <h3 className="page-heading">Confirm Order</h3>
            <hr />
            <div className="theme-dataGrid new-orders-dataGrid">

            </div>
        </div>
    )
}

export default ConfirmOrder
