import React, { useState, useEffect, useContext, useLayoutEffect } from "react";
import { Navbar, NavDropdown, Nav, Container, Button } from "react-bootstrap";
import { FaRegBell, FaRegUserCircle } from "react-icons/fa";
import { BiMessageSquareDetail } from "react-icons/bi";
import NotificationIcon from "./../assets/images/notification-icon.png";
import { Logo } from "./SvgIcons";
import { useHistory } from "react-router";
import { NavLink } from "react-router-dom";
import {
  Logout,
  EmptyLocalStorage,
  GetAuthUser,
  GetUserRole,
} from "../services/auth/auth.service";
import { toast } from "react-toastify";
import { GetNotifications, GetNotificationsCount, MarkNotificationRead } from "../services/notifications.service";
import { constants } from "../utils/constants";
import UserContext from "../hooks/UserContext";
import brandlogo from "../assets/images/LOGOAMS.svg";
import moment from "moment";
import ReadMore from "./ReadMore";

function Header(props) {
  const [notificationStatus, setNotificationStatus] = useState(false);
  const [notificationCount, setNotificationCount] = useState(0);
  const [chatnotificationStatus, setchatNotificationStatus] = useState(false);
  const [data, setData] = useState();
  const [showChat, setShowChat] = useState(false);
  const history = useHistory();
  //const {user} = useContext(UserContext);
  const [user, setUser] = useState("");
  const [expanded, setExpanded] = useState(true)


  useEffect(() => {
    // if (GetUserRole() !== constants.ROLES.ROLE_ADMIN) {
    //     setShowChat(true)
    // }
    getNotifications();
  }, []);

  /*useLayoutEffect(() => {
        setUser(GetAuthUser()?.full_name)
    }, [])*/

  const getNotifications = async () => {
      const itemStr = localStorage.getItem("rememberMe");
      if (itemStr) {
        const item = JSON.parse(itemStr);
        const now = new Date();
        if (now.getTime() / 1000 > item) {
          EmptyLocalStorage();
          history.push("/");
          return toast.error("Session timed out please login again");
        }
      }

    await GetNotificationsCount()
    .then((result) => {
      if (result.success) {
        if (result.data) {
          setNotificationCount(result.data.notification_count)

          GetNotifications()
          .then((result) => {
            if (result.success) {
              if (result.data) {
                console.log("result.data: ", result.data)
                const rowData = [];
                result.data.map((dt) => {
                  rowData.push({
                    id: dt.id,
                    time_ago: dt.time_ago,
                    is_read: dt.is_read,
                    title: dt.title,
                    description: dt.description,
                    ref_id: dt.ref_id,
                    type: dt.type,
                    extras: dt.extras,
                    created_at: moment.utc(dt.created_at).fromNow(),
                    updated_at: dt.updated_at,
                  });
                });

                setData(rowData);
              }
            } else {
              return toast.error(result.message);
            }
          })
          .catch((error) => {
            console.log("test: ", error);
            if (error.response.status === 401) {
              EmptyLocalStorage();
              history.push("/")
              return toast.error("Session timed out please login again");
            }
          });
        }
      } else {
        return toast.error(result.message);
      }
    })
    .catch((error) => {
      console.log(error.response.status);
      if (error.response.status === 401) {
        EmptyLocalStorage();
        history.push("/")
        return toast.error("Session timed out please login again");
      }
    });
  };


  const markNotificationRead = async () => {
    await MarkNotificationRead(null)
      .then((data) => {
        getNotifications()
      })
      .catch((error) => {
        if (error.response.status == 401) {
          EmptyLocalStorage();
          history.push("/");
        } else {
          return toast.error(error.response.data.message);
        }
      });
  }

  async function handleLogout() {
    await Logout()
      .then(async (data) => {
        if (data.success) {
          toast.success(data.message);
          EmptyLocalStorage();
          history.push("/");
        } else {
          toast.error(data.message);
        }
      })
      .catch((error) => {
        if (error.response.status == 401) {
          EmptyLocalStorage();
          history.push("/");
        } else {
          console.log(error);
          return toast.error(error.response.data.message);
        }
      });
  }

  const checkNotifications = () => {
    const notificationCount = 0;
    /*
     *
     *  checking the notification count and set to true
     *
     */
    if (notificationCount > 1) {
      setNotificationStatus(!notificationStatus);
    }
  };
  const checkChatNotifications = () => {
    const chatNotificationCount = 0;
    /*
     *
     *  checking the notification count and set to true
     *
     */
    if (chatNotificationCount > 1) {
      setchatNotificationStatus(!chatnotificationStatus);
    }
  };

  const navDropdownTitle = (
    <>
      <FaRegUserCircle /> {user}
    </>
  );

  return (
    <div>
      <Navbar className={"imprint-header shadow"}>
        <Container fluid className="fluid-class">
          <Navbar.Brand
            // href="/dashboard"
            className="brand-logo"
          >
            <img
              className="logoimg"
              src={brandlogo}
              // style={{ height: "100%", width: "330px" }}
            />
          </Navbar.Brand>
          <Nav className="ms-auto">
            <div className="notificationWrapper">
            <span className="notificationCount">{notificationCount > 10 ? "9+" : notificationCount}</span>
            <NavDropdown
              align="end"
              title={<FaRegBell />}
              id="basic-nav-dropdown"
              className={`notificationsDropdown ${
                notificationStatus ? "hasNotifications" : ""
              }`}
              onToggle={ (e) => {
                setExpanded(!expanded)
                if (expanded == true){
                  markNotificationRead()
                }
              }}
            >
             
              {data
                ? data.map((notification, index) => {
                  let url = ''

                  if(notification.type == constants.NOTIFICATION_TYPES.NEW_COMMENT){
                    url = `/manage-user/Userdetail/${notification.extras.client_id}?report_id=${notification.extras.report_id}`
                  }else if(notification.type == constants.NOTIFICATION_TYPES.NEW_REPLY) {
                    url = `/manage-user/Userdetail/${notification.extras.client_id}?report_id=${notification.extras.report_id}`
                  }else {
                    url = '/notifications'
                  }
                    return (
                      <NavDropdown.Item href={url} key={index}>
                        <div className={"notificationTile"} >
                          {/* <img
                            src={
                              notification.image
                                ? notification.image
                                : NotificationIcon
                            }
                            //   alt={"Notification"}
                          /> */}
                          <div style={{width: '100%'}}>                            
                              <ReadMore textLength={"30"}>
                                {notification.description}
                              </ReadMore>
                              <span style={{fontSize:'10px', textAlign: 'right', opacity: '0.5', display: 'block', width: '100%'}}>{notification.created_at}</span>
                           
                          </div>
                        </div>
                      </NavDropdown.Item>
                    );
                  })
                : ""}
              <NavDropdown.Item className={"viewAllNotifications"}>
                <NavLink
                  className={"viewAllNotificationstab"}
                  to="/notifications"
                >
                  Show all notifications
                </NavLink>
              </NavDropdown.Item>
            </NavDropdown>
            </div>
            <NavDropdown
              align="end"
              title={navDropdownTitle}
              id="basic-nav-dropdown"
              className={"profileDropdown"}
            >
              <NavDropdown.Item href="/profile">Profile</NavDropdown.Item>
              <NavDropdown.Item href="/change-password">
                Change Password
              </NavDropdown.Item>
              <NavDropdown.Item onClick={handleLogout} href="javascript:;">
                Logout
              </NavDropdown.Item>
            </NavDropdown>
          
          </Nav>
        </Container>
      </Navbar>
    </div>
  );
}

export default Header;
