import React, { useEffect, useState } from "react";
import { Row, Col, Button } from "react-bootstrap";
import { FaChartPie } from "react-icons/fa";
import DashboardStat from "../../../components/DashboardStat";
import { GetDashboard } from "../../../services/admin/dashboard.service";
import { toast } from "react-toastify";
import { NavLink } from "react-router-dom";
import { constants } from "../../../utils/constants";
import { EmptyLocalStorage } from "../../../services/auth/auth.service";
import { useHistory } from "react-router-dom";
import {
  XYPlot,
  XAxis, // Shows the values on x axis
  YAxis, // Shows the values on y axis
  VerticalBarSeries,
  LabelSeries,
  FlexibleXYPlot,
} from "react-vis";

const MyChartBar = (props) => {
  const data = [
    { y: 100, x: "Jan" },
    { y: 112, x: "Feb" },
    { y: 230, x: "Mar" },
    { y: 268, x: "Apr" },
    { y: 300, x: "May" },
    { y: 310, x: "Jun" },
    { y: 315, x: "Jul" },
    { y: 340, x: "Aug" },
    { y: 388, x: "Sep" },
    { y: 404, x: "Oct" },
    { y: 442, x: "Nov" },
    { y: 447, x: "Dec" },
  ];
  const chartWidth = 800;
  const chartHeight = 500;
  const chartDomain = [0, chartHeight];
  return (
    <>
      <FlexibleXYPlot
        xType="ordinal"
        height={chartHeight}
        yDomain={chartDomain}
      >
        <XAxis />
        <YAxis />
        <VerticalBarSeries data={data} className="Hello" color={"#960b0c"} />
        <LabelSeries
          data={data.map((obj) => {
            return { ...obj, label: obj.y.toString() };
          })}
          labelAnchorX="middle"
          labelAnchorY="text-after-edge"
        />
      </FlexibleXYPlot>
    </>
  );
};
const InitialStats = {
  users_count: "0",
  cases_count: "0",
  representatives_count: "0",
  officers_count: "0",
  agents_count: "0",
};

function Admin({ RoleId }) {
  const history = useHistory();
  const [stats, setStats] = useState(InitialStats);
  useEffect(async () => {
    let params = { role_id: RoleId };
    await GetDashboard(params)
      .then((response) => {
        if (response.success) {
          setStats({
            users_count: response.data.users_count,
            cases_count: response.data.cases_count,
            representatives_count: response.data.representatives_count,
            officers_count: response.data.officers_count,
            agents_count: response.data.agents_count,
          });
        } else {
          toast.error(response.message);
        }
      })
      .catch((error) => {
        if (error.response.status == 401) {
          EmptyLocalStorage();
          history.push("/");
        } else {
          return toast.error(error.response.data.message);
        }
      });
  }, []);

  return (
    <>
    <div>
      <Row>
        <Col xs={12} sm={12} md={4} lg={4} xl={4}>
          <NavLink
            to={`/manage-user`}
            style={{
              display: "block",
              color: "#0d6efd",
              textDecoration: "none",
              transition:
                "color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out",
            }}
            activeClassName="active"
          >
            <DashboardStat
              variant={"theme-green"}
              title={"Total Users"}
              icon={<FaChartPie />}
              countMain={stats.users_count}
            />
          </NavLink>
        </Col>
        <Col xs={12} sm={12} md={4} lg={4} xl={4}>
            <NavLink to={"/manage-representatives"} style={{
                display: 'block',
                color: '#0d6efd',
                textDecoration: 'none',
                transition: 'color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out'
            }} activeClassName="active">
                <DashboardStat variant={"theme-success"} title={"Total Representatives"} icon={<FaChartPie/>}
                                countMain={stats.representatives_count}/>
            </NavLink>
        </Col>
        <Col xs={12} sm={12} md={4} lg={4} xl={4} >
            <NavLink to={"/manage-officer"} style={{
                display: 'block',
                color: '#0d6efd',
                textDecoration: 'none',
                transition: 'color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out'
            }} activeClassName="active">
                <DashboardStat variant={"gradient-yellow"} title={"Total Officers"} icon={<FaChartPie/>}
                                countMain={stats.officers_count}/>
            </NavLink>
        </Col>
        </Row>
        <div className="graph-div"></div>
        <Row>
          
          <Col xs={12} sm={12} md={4} lg={4} xl={4} className="ms-lg-auto">
            <NavLink to={'/manage-agents'} style={{
                display: 'block',
                color: '#0d6efd',
                textDecoration: 'none',
                transition: 'color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out'
            }} activeClassName="active">
                <DashboardStat variant={"gradient-pink"} title={"Total Agents"} icon={<FaChartPie/>}
                                countMain={stats.agents_count}/>
            </NavLink>
        </Col>
        <Col xs={12} sm={12} md={4} lg={4} xl={4} className="me-lg-auto" >
          {/* <NavLink
            to={`${"/orders?status=" + constants.ORDERSTATUS.CONFIRMED}`}
            style={{
              display: "block",
              color: "#0d6efd",
              textDecoration: "none",
              transition:
                "color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out",
            }}
            activeClassName="active"
          > */}
          <DashboardStat
            variant={"theme-danger"}
            title={"Total Cases"}
            icon={<FaChartPie />}
            countMain={stats.cases_count}
          />
          {/* </NavLink> */}
        </Col>
     

        </Row>

                {/* <Col xs={12} sm={12} md={4} lg={4} xl={4}>
                    <NavLink to={`${"/orders?status=" + constants.ORDERSTATUS.COMPLETED}`} style={{
                        display: 'block',
                        color: '#0d6efd',
                        textDecoration: 'none',
                        transition: 'color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out'
                    }} activeClassName="active">
                        <DashboardStat variant={"gradient-green"} title={"Completed Orders"} icon={<FaChartPie/>}
                                       countMain={stats.completed_orders_count}/>
                    </NavLink>
                </Col>
                <Col xs={12} sm={12} md={4} lg={4} xl={4}>
                    <NavLink to={`${"/orders?status=" + constants.ORDERSTATUS.REVIEWED}`} style={{
                        display: 'block',
                        color: '#0d6efd',
                        textDecoration: 'none',
                        transition: 'color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out'
                    }} activeClassName="active">
                        <DashboardStat variant={"gradient-blue"} title={"Reviewed Orders"} icon={<FaChartPie/>}
                                       countMain={stats.reviewed_orders_count}/>
                    </NavLink>
                </Col>
                <Col xs={12} sm={12} md={4} lg={4} xl={4}>
                    <NavLink to={`${"/orders?status=" + constants.ORDERSTATUS.RETURNED}`} style={{
                        display: 'block',
                        color: '#0d6efd',
                        textDecoration: 'none',
                        transition: 'color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out'
                    }} activeClassName="active">
                        <DashboardStat variant={"gradient-blue"} title={"Returned Orders"} icon={<FaChartPie/>}
                                       countMain={stats.returned_orders_count}/>
                    </NavLink>
                </Col>
                <Col xs={12} sm={12} md={4} lg={4} xl={4}>
                    <NavLink to="/products" style={{
                        display: 'block',
                        color: '#0d6efd',
                        textDecoration: 'none',
                        transition: 'color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out'
                    }} activeClassName="active">
                        <DashboardStat variant={"gradient-blue"} title={"Total Products"} icon={<FaChartPie/>}
                                       countMain={stats.products_count}/>
                    </NavLink>
                </Col>
                <Col xs={12} sm={12} md={4} lg={4} xl={4}>
                    <NavLink to="/manage-agents" style={{
                        display: 'block',
                        color: '#0d6efd',
                        textDecoration: 'none',
                        transition: 'color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out'
                    }} activeClassName="active">
                        <DashboardStat variant={"gradient-blue"} title={"Total CSAgents"} icon={<FaChartPie/>}
                                       countMain={stats.csa_agents_count}/>
                    </NavLink>
                </Col>
                <Col xs={12} sm={12} md={4} lg={4} xl={4}>
                    <NavLink to="/vendors" style={{
                        display: 'block',
                        color: '#0d6efd',
                        textDecoration: 'none',
                        transition: 'color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out'
                    }} activeClassName="active">
                        <DashboardStat variant={"gradient-blue"} title={"Total Vendors"} icon={<FaChartPie/>}
                                       countMain={stats.vendors_count}/>
                    </NavLink>
                </Col>
                <Col xs={12} sm={12} md={4} lg={4} xl={4}>
                    <NavLink to="/ads-management" style={{
                        display: 'block',
                        color: '#0d6efd',
                        textDecoration: 'none',
                        transition: 'color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out'
                    }} activeClassName="active">
                        <DashboardStat variant={"gradient-blue"} title={"Ads Count"} icon={<FaChartPie/>}
                                       countMain={stats.ads_count}/>
                    </NavLink>
                </Col> */}
 
      {/* Graph */}
      <Row>
        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
          {/* Faizan */}
          <MyChartBar />
        </Col>
      </Row>
    </div>
    </>
  );
}

export default Admin;
