import {constants, apiUrl} from '../utils/constants';
import {GET, POST, PUT, DELETE} from './api.service.wrapper';
import {GetAuthUser, GetUserRole} from "../services/auth/auth.service"
import {createUser} from "./firebase.service";

const user = GetAuthUser();
let role = GetUserRole();

export const GetAds = async (keyword = '', page = '', perPage = '') => {
    let params = {};

    if(page){
        params['page'] = page;
    }

    if(perPage){
        params['per-page'] = perPage;
    }

    if(keyword){
        params['keyword'] = keyword;
    }

    const response = await GET(apiUrl.ads, params);
    return response;
}

export const GetAdsById = async (id) => {
    let response = '';
    response = await GET(apiUrl.ads + '/' + id);
    return response;
}

export const CreateAds = async (data) => {
    const config = {
        headers: {
            'content-type': 'multipart/form-data'
        }
    }
    const response = await POST(apiUrl.ads, data, config);
    return response;
}

export const UpdateAds = async (id, data) => {
    const config = {
        headers: {
            'content-type': 'multipart/form-data'
        }
    }
    const response = await PUT(apiUrl.ads, id, data, config);
    return response;
}

export const DeleteAds = async (id) => {
    const response = await DELETE(apiUrl.ads, id);
    return response;
}
