import { useHistory, withRouter } from "react-router";
import React, { useEffect, useLayoutEffect, useState } from "react";
import { PagesApi, SinglePageApi } from "../../services/pages.service";
import { toast } from "react-toastify";
import parse from "html-react-parser";
import logoIcon from "../../assets/images/logo-full.svg";
import { EmptyLocalStorage } from "../../services/auth/auth.service";
// import { useHistory } from "react-router";

function PrivacyPolicy(props) {
  const history = useHistory();
  const [content, setContent] = useState();
  const [slug, setSlug] = useState();
  
  useEffect(async () => {
    // let param = props.match.params.slug;
    await SinglePageApi("privacy-policy")
      .then((data) => {
        if (data.success) {
          setSlug("privacy-policy");
          setContent(parse(data.data.content));
        } else {
          toast.error(data.message);
        }
      })
      .catch((error) => {
        if (error.response.status == 401) {
          EmptyLocalStorage();
          history.push("/");
        } else {
          console.log(error);
          toast.error(error.response.data.message);
        }
      });
  }, [props]);

  return (

    <div className="container">
      <a href="/">
        <img
          className="img-fluid mb-4"
          src={logoIcon}
          alt="Imprint Logo"
          title="Imprint Logo"
          style={{ padding: "10px 25px", 
          display: "block",
          marginLeft: "auto",
          marginRight: "auto"
        }}
        />
      </a>
        <div className={"policyPage PrivacyPolicy"}>
        <h3 className="page-heading">{slug}</h3>
        <hr />
        <div>{content || "Loading..."}</div>
        </div>
    </div>
  );
}


export default withRouter(PrivacyPolicy);
