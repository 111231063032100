import React, {useState, useEffect} from 'react'
import swal from "sweetalert"
import {Link} from "react-router-dom"
import {Button, Col, Dropdown, DropdownButton, Form, FormControl, InputGroup, Row} from "react-bootstrap"
import {AddIconOutline, FilterIcon, MenuDots, SearchIcon} from "../../components/SvgIcons"
import MaterialTable from "material-table"
import {useHistory} from 'react-router-dom';
import {toast} from "react-toastify";
import {constants, customStyles} from "../../utils/constants";
import moment from "moment";
import {CreateAds, DeleteAds, GetAds, UpdateAds} from "../../services/advertisement.service";
import DataTable from 'react-data-table-component';
import BarLoader from "react-spinners/BarLoader";
import {EmptyLocalStorage} from "../../services/auth/auth.service";

function Advertisements() {
    let history = useHistory();
    const [loading, setLoading] = useState(false);
    const [totalRows, setTotalRows] = useState(0);
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(constants.PERPAGE);
    const [rows, setRows] = useState();
    const [keyword, setKeyword] = useState('');

    useEffect(async () => {
        await getAds();
    }, [keyword, page, perPage])

    const getAds = async () => {
        setLoading(true)
        await GetAds(keyword, page, perPage).then((result) => {
            if (result.status) {
                if (result.data.data) {
                    const rowData = [];
                    setPage(result.data.meta.current_page)
                    setTotalRows(result.data.meta.total);
                    result.data.data.map((dt, index) => {
                        rowData.push({
                            id: dt.id,
                            index: ++index,
                            title: dt?.title,
                            description: dt?.description,
                            image: dt?.image,
                            thumbnail: dt?.thumbnail,
                            video: dt?.video,
                            created_at: moment(dt?.created_at).fromNow(),
                        });
                    })
                    setRows(rowData);
                    setLoading(false)
                }
            } else {
                setLoading(false)
                return toast.error(result.message);
            }
        }).catch((error) => {
            setLoading(false)
            if (error.response.status == 401) {
                EmptyLocalStorage()
                history.push('/');
            } else {
                return toast.error(error.response.data.message);
            }
        })
    }

    const onAdDelete = (id) => {
        swal({
            title: "Are you sure?",
            text: "Are you sure you want to delete this ad?",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then(async (willShip) => {
            //Delete Product API
            if (willShip) {
                await DeleteAds(id).then((result) => {
                    if (result.status) {
                        swal(result.message, {
                            icon: "success",
                        });
                        getAds()
                    } else {
                        toast.error(result.message);
                    }
                }).catch((error) => {
                    if (error.response.status == 401) {
                        EmptyLocalStorage()
                        history.push('/');
                    } else {
                        return toast.error(error.response.data.message);
                    }
                })
            }
        });
    }

    const onEdit = (data) => {
        history.push('/ads-management/edit-ads-management/' + data.id, {
            data: data
        });
    }

    const adsActions = (data) => {
        return (
            <DropdownButton align="end" className={"actionDropdown"} id="dropdown-item-button" title={<MenuDots/>}>
                {/*<Dropdown.Item as="button" onClick={() => onEdit(data)}>Edit</Dropdown.Item>*/}
                <Dropdown.Item as="button" onClick={() => onAdDelete(data.id)}>Delete</Dropdown.Item>
            </DropdownButton>
        )
    }

    const columns = [
        {
            id: 'title',
            name: 'Title',
            maxWidth: '160px',
            selector: rowData => rowData.title,
        },
        {
            id: 'type',
            name: 'Type',
            maxWidth: '160px',
            selector: rowData => rowData.image ? 'Image' : 'Video',
        },
        {
            id: 'src',
            name: 'Source',
            maxWidth: '160px',
            cell: rowData => <a
                href={rowData.image ? constants.S3CREDENTIAL.s3EndPoint + '/' + rowData.image : constants.S3CREDENTIAL.s3EndPoint + '/' + rowData.video}
                download>Download</a>
        },
        {
            id: 'action',
            name: 'Action',
            cell: rowData => adsActions(rowData)
        },
    ];

    return (
        <div className={"usersPage"}>
            <h3 className="page-heading">Manage Advertisements</h3>
            <hr/>
            <div className={"customOrderFilters"}>
                <Row>
                    <Col xs={12} sm={12} md={5} lg={5} xl={5}>
                        <InputGroup className="mb-3 search-group">
                            <InputGroup.Text id="basic-addon1"><SearchIcon/></InputGroup.Text>
                            <FormControl
                                placeholder="Search"
                                aria-label="Search"
                                aria-describedby="Search"
                                maxLength={"255"}
                                onChange={(e) => {
                                    (e.target.value.length > 1) ? setKeyword(e.target.value) : setKeyword(null)
                                }}
                            />
                        </InputGroup>
                    </Col>
                    <Col xs={12} sm={12} md={7} lg={7} xl={7}>
                        <Row>
                            <Col xs={12} sm={12} md={3} lg={3} xl={3}></Col>
                            <Col xs={12} sm={12} md={5} lg={5} xl={5}></Col>
                            <Col xs={12} sm={12} md={4} lg={4} xl={4}>
                                <Link to={"/ads-management/add-ads-management"}>
                                    <Button className={"btn btn-green-theme h40 w-100"}>
                                        <AddIconOutline/> Add New
                                    </Button>
                                </Link>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </div>
            <div className="theme-dataGrid products-dataGrid">
                <DataTable
                    columns={columns}
                    data={rows}
                    progressPending={loading}
                    customStyles={customStyles}
                    selectableRows={false}
                    striped
                    highlightOnHover
                    pagination
                    paginationServer
                    paginationTotalRows={totalRows}
                    paginationPerPage={perPage}
                    onChangePage={(page) => {
                        setPage(page);
                    }}
                    onChangeRowsPerPage={(currentRowsPerPage) => {
                        setPerPage(currentRowsPerPage)
                    }}
                    progressComponent={<BarLoader color={'#961b1a'} loading={loading} css={'marginTop: 10px'}
                                                  height={'4'} width={'100%'}/>}
                />
            </div>
        </div>
    )
}

export default Advertisements
