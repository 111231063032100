import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import Test from "../assets/images/unk.png";
import { Form, Button, Spinner } from "react-bootstrap";
import { AiOutlineMessage } from "react-icons/ai";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import {
  EmptyLocalStorage,
  GetAuthUser
} from "../services/auth/auth.service";
import {
  PostComment,
  UpdateComment,
  PostReply,
  UpdateReply
} from "../services/users.service";
import { toast } from "react-toastify";

const Comments = ({showComment, commentLoader, comments, replies, reportID}) => {
  let history = useHistory();

  const current_user = GetAuthUser();
  const [disableButton, setDisableButton] = useState(false);
  const {register, handleSubmit, formState: {errors}} = useForm({
      mode: "onChange"
  });

  const onCommentSubmit = async (data) => {
    let dt = {
      message: data.message,
      author: current_user.id,
      report: reportID
    };

    setDisableButton(true);
    if(comments.length > 0){
      await UpdateComment(comments[0]?.id, dt)
        .then(async (data) => {
          if (data.success) {
            toast.success(data.message);
            showComment(false)

          } else {
            setDisableButton(false);
            toast.error(data.message);
          }
        })
        .catch((error) => {
          setDisableButton(false);
          if (error.response.status == 401) {
            EmptyLocalStorage();
            history.push("/");
          } else {
            return toast.error(error.response.data.message);
          }
        });
    }else {
      await PostComment(dt)
        .then(async (data) => {
          if (data.success) {
            toast.success(data.message);
            showComment(false)

          } else {
            setDisableButton(false);
            toast.error(data.message);
          }
        })
        .catch((error) => {
          setDisableButton(false);
          if (error.response.status == 401) {
            EmptyLocalStorage();
            history.push("/");
          } else {
            return toast.error(error.response.data.message);
          }
        });
    }
  }


  const onReplySubmit = async (data) => {
    let dt = {
      message: data.message,
      author: current_user.id,
      comment: comments[0]?.id
    };

    setDisableButton(true);
    if(replies.length > 0){
      await UpdateReply(replies[0]?.id, dt)
        .then(async (data) => {
          if (data.success) {
            toast.success(data.message);
            showComment(false)

          } else {
            setDisableButton(false);
            toast.error(data.message);
          }
        })
        .catch((error) => {
          setDisableButton(false);
          if (error.response.status == 401) {
            EmptyLocalStorage();
            history.push("/");
          } else {
            return toast.error(error.response.data.message);
          }
        });
    }else {
      await PostReply(dt)
        .then(async (data) => {
          if (data.success) {
            toast.success(data.message);
            showComment(false)

          } else {
            setDisableButton(false);
            toast.error(data.message);
          }
        })
        .catch((error) => {
          setDisableButton(false);
          if (error.response.status == 401) {
            EmptyLocalStorage();
            history.push("/");
          } else {
            return toast.error(error.response.data.message);
          }
        });
    }
  }
  return (
    <>
      { commentLoader ? <div className="text-center">
          <Spinner animation="border" role="status" style={{color: "#960b0c"}}>
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div> :
      <section className={`comments`}>
         {/* ${comments.length > 0 && "vh-100"} */}
        <div>
          <Row>
            <Col md={12}>
                {comments.length > 0 && (
                  <>
                    <div className={`d-flex`}>
                      <div className="image_pf">
                        <div className={"image_inner"}>
                          <img src={comments[0]?.author_image ?? Test} alt="profile" />
                        </div>
                      </div>
                      <div className="comment_mesg">
                        <div className="officer_name">{comments[0]?.author_name ?? "N/A"}</div>
                        <p>
                          {comments[0]?.message ?? "N/A"}
                        </p>
                      </div>
                    </div>
                    {/* replies */}
                      {replies.length > 0 && ( 

                        <div className="mt-4" style={{paddingLeft: "80px"}}>
                          <div className="d-flex">
                            <div className="image_pf">
                              <div className={"image_inner"}>
                                <img src={replies[0]?.author_image ?? Test} alt="profile" />
                              </div>
                            </div>
                            <div className="comment_mesg">
                              <div className="officer_name">{replies[0]?.author_name ?? "N/A"}</div>
                              <p>
                                {replies[0]?.message ?? "N/A"}
                              </p>
                            </div>
                          </div>
                          </div>
                      )
                      }
                      {/* reply text */}
                      <hr />
                      { current_user.role == 4 ?
                        (<div className="type_message reply_text mt-4">
                          <Form onSubmit={handleSubmit(onReplySubmit)}>
                            <Row>
                              <Col md={2}>
                                <div className="image_pf_side">
                                  <img src={Test} alt="profile" />
                                </div>
                              </Col>

                              <Col md={8} className="text_mesg">
                                <Form.Group
                                  className="mb-3"
                                  controlId="exampleForm.ControlTextarea1"
                                >
                                  <Form.Label>Reply</Form.Label>
                                  <Form.Control 
                                  as="textarea" 
                                  rows={3} 
                                  {...register("message",
                                    {
                                        required: {
                                            value: true,
                                            message: "Please type in your comment."
                                        },
                                    }
                                  )}
                                  />
                                </Form.Group>
                              </Col>
                            </Row>
                            <Row>
                              <Col md={10
                              } className="btn">
                                <div>
                                  <Button type="submit">
                                    <AiOutlineMessage />
                                    <span>Reply</span>
                                  </Button>
                                </div>
                              </Col>
                            </Row>
                          </Form>
                        </div>): current_user.role != 4 && comments.length < 1 &&
                        (
                          <div className="officer_name text-center">No Replies Found</div>
                        )
                      }
                  </>
                )}

            </Col>
          </Row>
        </div>
        {/* main comment */}
        {current_user.role == 3 ? 
          (
            <div className="type_message">
              <Form onSubmit={handleSubmit(onCommentSubmit)}>
                <Row>
                  <Col md={2}>
                    <div className="image_pf_side">
                      <img src={current_user.profile_image} alt="profile" />
                    </div>
                  </Col>

                  <Col md={8} className="text_mesg">
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlTextarea1"
                    >
                      <Form.Label>Message</Form.Label>
                      <Form.Control 
                      as="textarea" 
                      rows={3} 
                      {...register("message",
                        {
                            required: {
                                value: true,
                                message: "Please type in your comment."
                            },
                        }
                      )}/>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col md={10
                  } className="btn">
                    <div>
                      <Button type="submit" 
                        disabled={disableButton}
                      >
                        <AiOutlineMessage />
                        <span>Post</span>
                      </Button>
                    </div>
                  </Col>
                </Row>
              </Form>
            </div>
          ): current_user.role != 3 && comments.length < 1 &&
          (
            <div className="officer_name text-center">No Comments Found</div>
          )
        }
      </section>
      }
    </>
  );
};

export default Comments;
