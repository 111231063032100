import {GET} from '../api.service.wrapper';
import {apiUrl} from '../../utils/constants';

export const PagesApi = async (slug) => {
    const response = await GET(apiUrl.pages + '/' + slug);
    return response;
}

export const TermsApi = async (slug) => {
    const response = await GET(apiUrl.pages, {slug: slug});
    return response;
}
