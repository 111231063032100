import {apiUrl} from '../utils/constants';
import {GET, POST, PUT, DELETE} from './api.service.wrapper';

export const GetOrderShipping = async (page = '', perPage = '') => {
    let params = {};

    if(page){
        params['page'] = page;
    }

    if(perPage){
        params['per-page'] = perPage;
    }
    const response = await GET(apiUrl.order_shipping, params);
    return response;
}

export const GetOrderShippingById = async (id) => {
    let response = '';
    response = await GET(apiUrl.order_shipping + '/' + id);
    return response;
}

export const CreateOrderShipping = async (data) => {
    const response = await POST(apiUrl.order_shipping, data);
    return response;
}

export const UpdateOrderShipping = async (id, data) => {
    const response = await PUT(apiUrl.order_shipping, id, data);
    return response;
}

export const DeleteOrderShipping = async (id) => {
    const response = await DELETE(apiUrl.order_shipping, id);
    return response;
}

export const CreateShipping = async (data) => {
    const response = await POST(apiUrl.create_shipping + '/' + data, data);
    return response;
}

export const TrackShipping = async (shipping_id) => {
    const response = await POST(apiUrl.track_shipping + '/' + shipping_id, {});
    return response;
}
