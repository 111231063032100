import React, { createContext } from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
//auth layout
import AuthLayoutRoute from "./layouts/AuthLayout";
//auth views
import Signin from "./views/auth/Signin";
import SignUp from "./views/auth/SignUp";
import ForgotPassword from "./views/auth/ForgotPassword";
import PrivacyPolicy from "./views/auth/PrivacyPolicy";
import TermsAndConditions from "./views/auth/TermsAndConditions";
import VerifyCode from "./views/auth/VerifyCode";
import ResetPassword from "./views/auth/ResetPassword";
//dashboard layout
import DashboardLayoutRoute from "./layouts/DashboardLayout";
//dashboard views
import Dashboard from "./views/dashboard/Dashboard";
//dashboard views / orders
import Orders from "./views/orders/Orders";
import OrderDetails from "./views/orders/OrderDetails";
import OrderHistory from "./views/orders/OrderHistory";
import ConfirmOrder from "./views/orders/ConfirmOrder";
//dashboard views / category
import Category from "./views/category/Category";
import SubCategoryList from "./views/category/SubCategoryList";
import Colors from "./views/colors/Colors";
import Reviews from "./views/reviews/Reviews";
//dashboard views / Products
import Products from "./views/products/Products";
import AddProduct from "./views/products/AddProduct";
import EditProduct from "./views/products/EditProduct";
import ViewProduct from "./views/products/ViewProduct";
//dashboard views / Users
import Users from "./views/users/Users";
import AddNewUser from "./views/users/AddNewUser";
import EditUser from "./views/users/EditUser";
//dashboard views / Vendors
import Vendors from "./views/vendors/Vendors";
import AddNewVendor from "./views/vendors/AddNewVendor";
import EditVendor from "./views/vendors/EditVendor";
//notifications
import AllNotifications from "./views/notification/AllNotifications";
// ads management
import Advertisements from "./views/advertisements/Advertisements";
import AddNewAdvertisements from "./views/advertisements/AddNewAdvertisements";
import EditAdvertisements from "./views/advertisements/EditAdvertisements";
// dashboard settings
import ChangePassword from "./views/settings/ChangePassword";

//Policy routes
import Pages from "./views/policy/Pages";

//profile management
import ViewProfile from "./views/profile/ViewProfile";
import EditProfile from "./views/profile/EditProfile";
//error layout
import ErrorComponent from "./views/error/ErrorComponent";

//Chat Layout Route
import ChatLayoutRoute from "./layouts/ChatLayout";
//Chat Layout
import Chat from "./views/chat/Chat";
import ConnectedUsers from "./views/chat/ConnectedUsers";

//pages
import PageList from "./views/pages/PageList";
import PageAdd from "./views/pages/PageAdd";
import PageEdit from "./views/pages/PageEdit";

//agent view
import Agents from "./views/agents/Agents";
import AddNewAgent from "./views/agents/AddNewAgent";
import EditAgent from "./views/agents/EditAgent";
import Payments from "./views/payments/Payments";

import { injectStyle } from "react-toastify/dist/inject-style";
import { ToastContainer } from "react-toastify";
import ColorList from "./views/colors/Colors";
import UserDetails from "./views/pages/UserDetails";

// Officer
import Officer from "./views/oficers/Officers";
import AddOfficer from "./views/oficers/AddOfficer";
import EditOfficer from "./views/oficers/Editofficer";
//Representatives
import Representatives from "./views/representatives/Representatives";
import EditRepresentatives from "./views/representatives/EditRepresentatives";
import AddRepresentatives from "./views/representatives/AddRepresentatives"

// Agnets
// CALL IT ONCE IN YOUR APP
if (typeof window !== "undefined") {
  injectStyle();
}

function App() {
  return (
    <Router>
      <Switch>
        {/**
         *
         *  Define all the page routes here
         *
         */}
        <DashboardLayoutRoute
          exact
          isAuth={true}
          path={`/dashboard`}
          component={Dashboard}
        />
        <DashboardLayoutRoute
          exact
          isAuth={true}
          path={`/orders`}
          component={Orders}
        />
        <DashboardLayoutRoute
          exact
          isAuth={true}
          path={`/orders/order-details/:id`}
          component={OrderDetails}
        />
        <DashboardLayoutRoute
          exact
          isAuth={true}
          path={`/order-history/`}
          component={OrderHistory}
        />
        <DashboardLayoutRoute
          exact
          isAuth={true}
          path={`/confirm-order/`}
          component={ConfirmOrder}
        />
        <DashboardLayoutRoute
          exact
          isAuth={true}
          path={`/category`}
          component={Category}
        />
        <DashboardLayoutRoute
          exact
          isAuth={true}
          path={`/agents-details`}
          component={Agents}
        />
        <DashboardLayoutRoute
          exact
          isAuth={true}
          path={`/sub-category/:category_id`}
          component={SubCategoryList}
        />
        <DashboardLayoutRoute
          exact
          isAuth={true}
          path={`/colors`}
          component={Colors}
        />
        <DashboardLayoutRoute
          exact
          isAuth={true}
          path={`/products`}
          component={Products}
        />
        <DashboardLayoutRoute
          exact
          isAuth={true}
          path={`/products/edit/:id`}
          component={EditProduct}
        />
        <DashboardLayoutRoute
          exact
          isAuth={true}
          path={`/products/add-new`}
          component={AddProduct}
        />
        <DashboardLayoutRoute
          exact
          isAuth={true}
          path={`/products/view/:id`}
          component={ViewProduct}
        />
        <DashboardLayoutRoute
          exact
          isAuth={true}
          path={`/manage-user`}
          component={Users}
        />
        <DashboardLayoutRoute
          exact
          isAuth={true}
          path={`/manage-user/add-user`}
          component={AddNewUser}
        />
        <DashboardLayoutRoute
          exact
          isAuth={true}
          path={`/manage-user/edit-user/:id`}
          component={EditUser}
        />
        <DashboardLayoutRoute
          exact
          isAuth={true}
          path={`/manage-user/Userdetail/:id`}
          component={UserDetails}
        />
        <DashboardLayoutRoute
          exact
          isAuth={true}
          path={`/notifications`}
          component={AllNotifications}
        />
        <DashboardLayoutRoute
          exact
          isAuth={true}
          path={`/ads-management`}
          component={Advertisements}
        />
        <DashboardLayoutRoute
          exact
          isAuth={true}
          path={`/ads-management/add-ads-management`}
          component={AddNewAdvertisements}
        />
        <DashboardLayoutRoute
          exact
          isAuth={true}
          path={`/ads-management/edit-ads-management/:id`}
          component={EditAdvertisements}
        />
        <DashboardLayoutRoute
          exact
          isAuth={true}
          path={`/manage-agents`}
          component={Agents}
        />
        <DashboardLayoutRoute
          exact
          isAuth={true}
          path={`/manage-agents/add-agent`}
          component={AddNewAgent}
        />
        <DashboardLayoutRoute
          exact
          isAuth={true}
          path={`/manage-agents/edit-agent/:id`}
          component={EditAgent}
        />
        {/* Offiecrs */}
        <DashboardLayoutRoute
          exact
          isAuth={true}
          path={`/manage-officer`}
          component={Officer}
        />
        <DashboardLayoutRoute
          exact
          isAuth={true}
          path={`/manage-officer/add-officer`}
          component={AddOfficer}
        />
        <DashboardLayoutRoute
          exact
          isAuth={true}
          path={`/manage-officer/edit-officer/:id`}
          component={EditOfficer}
        />
        {/* Representatives */}
        <DashboardLayoutRoute
          exact
          isAuth={true}
          path={`/manage-representatives`}
          component={Representatives}
        />
        <DashboardLayoutRoute
          exact
          isAuth={true}
          path={`/manage-representatives/edit-representatives/:id`}
          component={EditRepresentatives}
        />
        <DashboardLayoutRoute
          exact
          isAuth={true}
          path={`/manage-representatives/add-representatives`}
          component={AddRepresentatives}
        />
        <DashboardLayoutRoute
          exact
          isAuth={true}
          path={`/vendors`}
          component={Vendors}
        />
        <DashboardLayoutRoute
          exact
          isAuth={true}
          path={`/vendors/add-vendor`}
          component={AddNewVendor}
        />
        <DashboardLayoutRoute
          exact
          isAuth={true}
          path={`/vendors/edit-vendor/:id`}
          component={EditVendor}
        />
        <DashboardLayoutRoute
          exact
          isAuth={true}
          path={`/payments-management`}
          component={Payments}
        />
        <DashboardLayoutRoute
          exact
          isAuth={true}
          path={`/reviews`}
          component={Reviews}
        />
        {/**
         *
         *  pages Management
         *
         */}
        <DashboardLayoutRoute
          exact
          isAuth={true}
          path={`/page-list`}
          component={PageList}
        />
        <DashboardLayoutRoute
          exact
          isAuth={true}
          path={`/page-edit/:id`}
          component={PageEdit}
        />
        <DashboardLayoutRoute
          exact
          isAuth={true}
          path={`/page-add`}
          component={PageAdd}
        />
        {/**
         *
         *  Terms and conditions pages
         *
         */}
        <DashboardLayoutRoute
          exact
          isAuth={true}
          path={`/page/:slug`}
          component={Pages}
        />
        {/**
         *
         *  Profile Views
         *
         */}
        <DashboardLayoutRoute
          exact
          isAuth={true}
          path={`/profile`}
          component={ViewProfile}
        />
        <DashboardLayoutRoute
          exact
          isAuth={true}
          path={`/edit-profile`}
          component={EditProfile}
        />
        <DashboardLayoutRoute
          exact
          isAuth={true}
          path={`/change-password`}
          component={ChangePassword}
        />
        {/**
         *
         *  Chat layout Systems / view
         *
         */}
        <ChatLayoutRoute exact isAuth={true} path={`/chat`} component={Chat} />
        <ChatLayoutRoute
          exact
          isAuth={true}
          path={`/connected_users`}
          component={ConnectedUsers}
        />
        {/**
         *
         *  Authentication routes
         *
         */}
        <AuthLayoutRoute
          exact
          isAuth={true}
          path="/sign-up"
          component={SignUp}
        />
        <AuthLayoutRoute
          exact
          isAuth={true}
          path="/forgot-password"
          component={ForgotPassword}
        />
        <AuthLayoutRoute
          exact
          isAuth={true}
          path="/privacy-policy"
          component={PrivacyPolicy}
        />
        <AuthLayoutRoute
          exact
          isAuth={true}
          path="/terms-and-conditions"
          component={TermsAndConditions}
        />
        <AuthLayoutRoute
          exact
          isAuth={true}
          path="/verify-code"
          component={VerifyCode}
        />
        <AuthLayoutRoute
          exact
          isAuth={true}
          path="/reset-password"
          component={ResetPassword}
        />
        <AuthLayoutRoute exact isAuth={true} path="/" component={Signin} />
        {/**
         *
         *  Error routes
         *
         */}
        <Route path={"*"}>
          <ErrorComponent
            type={"404"}
            message={"The page you are looking for is not found"}
          />
        </Route>
      </Switch>
      <ToastContainer hideProgressBar autoClose={2000} />
    </Router>
  );
}

export default App;
