import React, { useRef, useState, useEffect } from "react";
import {
  Form,
  Row,
  Col,
  InputGroup,
  FormControl,
  Button,
} from "react-bootstrap";
import BreadcrumbsComponent from "../../components/BreadcrumbsComponent";
import { useForm } from "react-hook-form";
import { GetUserById, UpdateUser } from "../../services/users.service";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import Calendar from "react-multi-date-picker";
import moment from "moment";
import { updateUser } from "../../services/firebase.service";
import { format } from "date-fns";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { EmptyLocalStorage } from "../../services/auth/auth.service";
import { useDropzone } from "react-dropzone";
import { constants } from "../../utils/constants";
import { UploadIcon } from "../../components/SvgIcons";
import {UploadImageToS3} from "../../utils/helpers";

function EditOfficer(props) {
  let history = useHistory();
  const [data, setData] = useState([]);
  const [pass, TogglePass] = useState(true);
  const [confPass, ToggleConfPass] = useState(true);
  const [confirmPass, setConfirmPass] = useState(true);
  const [disableButton, setDisableButton] = useState(false);
  const [mediaRequiredError, setMediaRequiredError] = useState(false);
  const [files, setFiles] = useState([]);
  const [selectedFile, setSelectedFile] = useState(false);
  const [file, setFileData] = useState(false);
  const [defaultImage, setDefaultImage] = useState(false);

  const {
    register,
    watch,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
  });

  const { setValue } = useForm({
    mode: "onChange",
  });

  const password = useRef({});
  password.current = watch("password", "");

  useEffect(async () => {
    let id = props.match.params.id;
    await getUserByid(id);
    if (data) {
      reset({
        full_name: data.full_name,
        phone_number: data.phone_number,
        state: data.state,
      });
    }
  }, []);

  const getUserByid = async (id) => {
    await GetUserById(id)
    .then((result) => {
      if (result.success) {
        if (result.data) {
          setDefaultImage(result.data.profile_image || constants.USERIMAGENOTFOUND)
          setData({
            full_name: result.data.full_name,
            phone_number: result.data.phone_number,
            state: result.data.state,
          });
        }
      } else {
        return toast.error(result.message);
      }
    })
      .catch((error) => {
        if (error.response.status == 401) {
          EmptyLocalStorage();
          history.push("/");
        } else {
          return toast.error(error.response.data.message);
        }
      });
  };
  
  const handleChange = (event) => {
    setFileData(URL.createObjectURL(event.target.files[0]));
  }

  const onSubmit = async (data) => {
    setDisableButton(true);
    let url = '';
    if (data.media.length > 0) {
      console.log(data.media[0].type)
      if (data.media[0].type == "image/jpg" || data.media[0].type == "image/png" || data.media[0].type == "image/jpeg" ){
        
      let uploadImage = await UploadImageToS3(data.media[0]);
      if (uploadImage) {

        console.log(uploadImage)
          if (!uploadImage.success) {
              toast.error(uploadImage.message);
              return false;
          }

          // content_type = constants.CONTENTTYPE[file.type];
          url = uploadImage.data;
          // filename = file.name;
      }

    console.log("url", url)
    console.log("dada", data)
    let id = props.match.params.id;
    let dt = {
      state: data.state,
      phone_number: data.phone_number,
      profile_image: url,
    };
    if (data.password && data.password_confirmation) {
      dt.password = data.password;
      dt.password_confirmation = data.password_confirmation;
    }
    await UpdateUser(id, dt)
      .then(async (data) => {
        if (data.success) {
          toast.success(data.message);
          history.push("/manage-officer");
        } else {
          setDisableButton(false);
          toast.error(data.message);
        }
      })
      .catch((error) => {
        setDisableButton(false);
        if (error.response.status == 401) {
          EmptyLocalStorage();
          history.push("/");
        } else {
          return toast.error(error.response.data.message);
        }
      });

      }else {
        toast.error("Invalid file type.");
        setDisableButton(false)
      }
    }else {
      url = defaultImage
    }
  };
  function CustomDropZone(props) {
    const {
      acceptedFiles,
      fileRejections,
      getRootProps,
      getInputProps,
      onChange,
    } = useDropzone({
      accept: "image/jpeg, image/jpg, image/png",
      maxFiles: 10,
      onDrop: (files) => {
        setValue("media", files);
        console.log("files droped: ", files);
        setMediaRequiredError(false);
        setSelectedFile(files[0])
        setFiles(
          files.map((file) =>
            Object.assign(file, {
              preview: URL.createObjectURL(file),
            })
          )
        );
      },
    });

    const acceptedFileItems = acceptedFiles.map((file, index) => (
      <li key={file.path} style={{ listStyle: "none", fontSize: "10px" }}>
        {index > 1 ? index + "Files Selected" : index + "File Selected"}
        {/*{file.path} - {file.size} bytes*/}
      </li>
    ));

    console.log("accepted files: ", acceptedFiles);

    const thumbs = files.map((file) => (
      <div className={"thumb"} key={file.name}>
        <div className={"thumbInner"}>
          <img src={file.preview} className={"img"} />
        </div>
      </div>
    ));

    const fileRejectionItems = fileRejections.map(({ file, errors }) => (
      <li key={file.path}>
        {file.path} - {file.size} bytes
        <ul>
          {errors.map((e) => (
            <li key={e.code}>{e.message}</li>
          ))}
        </ul>
      </li>
    ));

    return (
      <div className="dropZoneContainer">
        <div {...getRootProps({ className: "dropzone" })}>
          <input type="file" name="media" {...getInputProps()} />
          <UploadIcon />
          <p>Drag images or click to upload</p>
        </div>
        <aside className={"thumbsContainer"}>{thumbs}</aside>
        {/*{
                acceptedFileItems.length > 0 ?
                    <div style={{marginLeft: '10px'}}>
                        <span style={{fontSize: '10px'}}>Selected files</span>
                        <p>{acceptedFileItems.length}</p>
                    </div> : ''
            }*/}

        {/*<aside>
            <h4>Accepted files</h4>
            <ul>{acceptedFileItems}</ul>
            <h4>Rejected files</h4>
            <ul>{fileRejectionItems}</ul>
        </aside>*/}
      </div>
    );
  }
  return (
    <div className="AddNewUser">
      {/*<BreadcrumbsComponent />*/}
      <h3 className="page-heading"><span className="custom-color-maroon">Edit Officer -</span> {data.full_name}</h3>
      <hr />
      <Row>
        <Col xs={12} sm={12} md={8} lg={6} xl={6}>
          <Form
            className="formAddUser"
            autocomplete="off"
            onSubmit={handleSubmit(onSubmit)}
          >
            {/* <Row> */}
              {/* <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                <Form.Group>
                  <Form.Label className={"form-label"}>
                    Image<small style={{ color: "red" }}>*</small>
                  </Form.Label>
                  <div className={"box-dotted"}>
                    <CustomDropZone ref={{ ...register("media") }} />
                    {mediaRequiredError ? (
                      <Form.Text className="text-muted validationText hasError">
                        Media is required
                      </Form.Text>
                    ) : (
                      ""
                    )}
                  </div>
                </Form.Group>
              </Col>{" "} */}
            {/* </Row> */}
            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
              <Row className={"mb-3 justify-content-center align-items-center"}>
                  <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                      <Form.Group controlId="formFile" onChange={(e) => handleChange(e)}
                                  className="custom-file-upload mb-0">
                          <Form.Label className={"toggleFileUpload mb-0"}>
                              <UploadIcon/>
                              Upload Image
                          </Form.Label>
                          <Form.Control type="file"
                                        accept="image/png, image/jpeg, image/jpg, image/gif" {...register('media')} />

                      </Form.Group>
                  </Col>
                  <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                      <ul className={"list-inline list-variations mb-0"}>
                          <li className={"list-inline-item d-flex justify-content-between mb-0"}>
                              <img onError={({currentTarget}) => {
                                  currentTarget.onerror = null; // prevents looping
                                  currentTarget.src = constants.IMAGENOTFOUND;
                              }} src={file ? file : defaultImage} width="50%" height="50%"/>
                          </li>
                      </ul>
                  </Col>
              </Row>
          </Col>

            <Row>
              {/* <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                <Form.Group>
                  <Form.Label className={"form-label"}>
                    Image<small style={{ color: "red" }}>*</small>
                  </Form.Label>
                  <div className={"box-dotted"}>
                    <CustomDropZone ref={{ ...register("media") }} />
                    {mediaRequiredError ? (
                      <Form.Text className="text-muted validationText hasError">
                        Media is required
                      </Form.Text>
                    ) : (
                      ""
                    )}
                  </div>
                </Form.Group>
              </Col> */}

              <Col xs={12} sm={12} md={8} lg={6} xl={6}>
                <Form.Group className="mb-3" controlId="phone_number">
                  <Form.Label>Phone Number</Form.Label>
                  <Form.Control
                    type="tel"
                    placeholder="Enter Phone Number"
                    autocomplete="off"
                    defaultValue={data.phone_number}
                    {...register("phone_number", {
                      required: {
                        value: "required",
                        message: "Phone is required",
                      },
                      pattern: {
                        value: /^\+[0-9]*$/,
                        message: "incorrect pattern +xxxxxxxxxxxx",
                      },
                      minLength: {
                        value: 8,
                        message: "Minimum length is 8",
                      },
                      maxLength: {
                        value: 16,
                        message: "Maximum length is 16",
                      },
                    })}
                  />
                  {errors.phone_number && (
                    <Form.Text className="text-muted validationText hasError">
                      {errors.phone_number.message}
                    </Form.Text>
                  )}
                </Form.Group>
              </Col>
              <Col xs={12} sm={12} md={8} lg={6} xl={6}>
                <Form.Group className="mb-3" controlId="state">
                  <Form.Label>State</Form.Label>
                  <Form.Control
                    placeholder="Current Location"
                    defaultValue={data.state}
                    autocomplete="off"
                    {...register("state", {
                      required: {
                        value: "required",
                        message: "State is required",
                      },
                      pattern:{
                        value: /^[A-Za-z ]+$/,
                        message: "invalid state name entered"
                      },
                      minLength: {
                        value: 3,
                        message: "Minimum length is 3",
                      },
                      maxLength: {
                        value: 25,
                        message: "Maximum length is 25",
                      },
                    })}
                    type="text"
                  />
                  {errors.state && (
                    <Form.Text className="text-muted validationText hasError">
                      {errors.state.message}
                    </Form.Text>
                  )}
                </Form.Group>
              </Col>
            </Row>
            {/* <Row>
                            <Col xs={12} sm={12} md={8} lg={6} xl={6}>
                                <Form.Group className="mb-3" controlId="dob">
                                    <Form.Label>Date Of Birth</Form.Label>
                                    <Form.Control
                                        placeholder="Enter Date Of Birth"
                                        defaultValue={data.dob}
                                        max={format(new Date(), 'yyyy-MM-dd')}
                                        autocomplete="off"
                                        {...register('dob', {
                                            required: {
                                                value: "required",
                                                message: "dob is required"
                                            },
                                        })} type="date"/>
                                    {errors.dob && <Form.Text
                                        className="text-muted validationText hasError">{errors.dob.message}</Form.Text>}
                                </Form.Group>
                            </Col>
                        </Row> */}
            {/*<Row>
                            <Col xs={12} sm={12} md={8} lg={6} xl={6}>
                                <Form.Group className="mb-3" controlId="company_name">
                                    <Form.Label>Company Name</Form.Label>
                                    <Form.Control
                                        placeholder="Enter Company Name"
                                        defaultValue={data.company_name}
                                        {...register('company_name', {
                                            required: {
                                                value: "required",
                                                message: "company name is required"
                                            },
                                            minLength: {
                                                value: 6,
                                                message: "Minimum length is 6"
                                            },
                                            maxLength: {
                                                value: 255,
                                                message: "Maximum length is 255"
                                            },
                                        })} type="text"/>
                                    {errors.company_name && <Form.Text
                                        className="text-muted validationText hasError">{errors.company_name.message}</Form.Text>}
                                </Form.Group>
                            </Col>
                            <Col xs={12} sm={12} md={8} lg={6} xl={6}>
                                <Form.Group className="mb-3" controlId="business_type">
                                    <Form.Label>Business Type</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Enter Business Type"
                                        defaultValue={data.business_type}
                                        {...register("business_type", {
                                                required: {
                                                    value: "required",
                                                    message: "business type is required"
                                                },
                                                minLength: {
                                                    value: 4,
                                                    message: "Minimum length is 6"
                                                },
                                                maxLength: {
                                                    value: 16,
                                                    message: "Maximum length is 16"
                                                }
                                            }
                                        )}
                                    />
                                    {errors.business_type && <Form.Text
                                        className="text-muted validationText hasError">{errors.business_type.message}</Form.Text>}
                                </Form.Group>
                            </Col>
                        </Row>*/}
            <Row>
              <Col xs={12} sm={12} md={8} lg={6} xl={6}>
                <input
                  type={"submit"}
                  className={"btn btn-green-theme w-100 mt-3"}
                  disabled={disableButton}
                  value={"Edit Officer"}
                />
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
    </div>
  );
}

export default EditOfficer;
