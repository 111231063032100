import React from 'react'
import { Route, Redirect } from "react-router-dom"
import Header from '../components/Header'
import SideBar from '../components/SideBar'
import {Container, Row, Col} from 'react-bootstrap'
import { GetToken } from '../services/auth/auth.service'

function NavClick(){
    console.log("nav click triggered");
}

function ChatLayout({children}) {
    return (
        <div>
            <div className="chat_layout_wrapper">
                <Header />
                {/*<SideBar />*/}
                <Container fluid className={"p-0"}>
                    <Row>
                        <main className={"col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12"}>
                            <div className={"main_chat_wrapper"}>
                                {children}
                            </div>
                        </main>
                    </Row>
                </Container>
            </div>
        </div>
    )
}
const ChatLayoutRoute = ({isAuth, component: Component, ...rest}) => {
    isAuth = Boolean(GetToken());
    // isAuth = localStorage.getItem("user");
    return (
        <>
            <Route
                {...rest}
                render={(matchProps) =>
                    isAuth ? (
                        <ChatLayout>
                            <Component {...matchProps} />
                        </ChatLayout>
                    ) : (
                        <Redirect
                            to={{
                                pathname: "/",
                                state: { from: matchProps.location },
                            }}
                        />
                    )
                }
            />
        </>
    );
};

export default ChatLayoutRoute
