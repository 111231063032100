import React, {useEffect, useState} from 'react'
import {Form} from 'react-bootstrap'
import ProfileIcon from './../../assets/images/profile.jpg'
import SendIcon from './../../assets/images/send_icon.png'
import AttachIcon from './../../assets/images/attch_big.png'
import {BiX} from "react-icons/bi";
import ChatConversation from "../../components/Chat/ChatConversation";
import ChatList from "../../components/Chat/ChatList";
import {getActiveThreadMedia, getUsersThreadList, getUserThreadMessages} from "../../services/firebase.service";
import {GetAuthUser} from "../../services/auth/auth.service";
import {BeatLoader} from "react-spinners";

function ConnectedUser() {
    const [loading, setLoading] = useState(false);
    const [chatLoader, setChatLoader] = useState(false);
    const [messageLoading, setMessageLoading] = useState(false);
    const [sideBar, setSideBar] = useState(false);
    const [threads, setThreads] = useState([]);
    const [messages, setMessages] = useState([]);
    const [search, setSearch] = useState('');
    const [activeCustomer, setActiveCustomer] = useState('');
    const [activeCustomerName, setActiveCustomerName] = useState('');
    const [activeThread, setActiveThread] = useState('');
    const [mediaDetail, setMediaDetail] = useState([]);
    const [activeVendor, setActiveVendor] = useState([]);
    const current_user = GetAuthUser();

    useEffect(() => {
        if (!activeThread && !activeCustomer) {
            setLoading(true)
            setChatLoader(true)
        } else {
            setLoading(false)
            setChatLoader(false)
        }
    })
    //Chat Threads
    useEffect(() => {
        getThreads();
    }, [search])

    //Messages and Order Details
    useEffect(() => {
        getMessages();
    }, [activeThread, activeCustomer])

    //Get all chat threads by login user
    const getThreads = async () => {
        await getUsersThreadList(search, (data) => {
            if (data) {
                setActiveThread(data[0]?.chat_id)
                setActiveCustomer(data[0]?.customer_id)
                setActiveCustomerName(data[0]?.customer_name)
                setThreads(data);
            }
        });
    }

    //Get all chat threads by login user
    const getMessages = async () => {
        await getUserThreadMessages(activeThread, activeCustomer, (data) => {
                setMessages(data ? data : []);
            });
    }

    if (chatLoader) {
        return (
            <div style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                height: '80vh'
            }}>
                <BeatLoader color={'#12726C'} loading={loading} css={'margin-top: 0px'} height={'4'}
                            width={'100%'}/>
                <br/><br/>
                {
                    (threads && threads.length > 0) ? '' : <div>No Chat Available</div>
                }
            </div>
        )
    }

    return (
        <div className={"ChatView UserConectedChat"}>
            <div id="loadgrid" className={sideBar ? "sidepanel-collapsed" : ""}>
                <div className="tabs-left">
                    <ChatList orderChat={false}
                              threads={threads}
                              active_thread={activeThread}
                              onActiveClick={setActiveThread}
                              onActiveCustomer={setActiveCustomer}
                              onSearchChange={setSearch}
                              active_customer={activeCustomer}
                              setActiveVendor={setActiveVendor}
                    />
                    {
                        activeThread ?
                            <ChatConversation orderChat={false}
                                              messageLoading={messageLoading}
                                              setSideBar={setSideBar}
                                              active_thread={activeThread}
                                              messages={messages}
                                              active_customer={activeCustomer}
                                              active_customer_name={activeCustomerName}
                                              current_user={current_user}/>
                            : ''
                    }
                </div>
            </div>
        </div>
    )
}

export default ConnectedUser
